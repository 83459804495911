import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UserFlair = _resolveComponent("UserFlair")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_component_router_link, {
    to: '/@' + _ctx.user?.username
  }, {
    default: _withCtx(() => [
      _createTextVNode("@" + _toDisplayString(_ctx.user.username), 1),
      _createVNode(_component_UserFlair, {
        class: "mx-1",
        flair: _ctx.user.flair
      }, null, 8, ["flair"])
    ]),
    _: 1
  }, 8, ["to"]))
}