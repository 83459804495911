<template lang="pug">
p.display-5.text-center User list
hr

table.table(v-if="sortedUsers")
    thead
        tr
            th(scope="col") #
            th(scope="col") Username
            th(scope="col") Joined at
    
    tbody
        tr(v-for="user in sortedUsers")
            th(scope="row") {{user.id}}
            td {{user.username}}
            td {{user.joinedAt === 0 ? "Missing registration date" : new Date(user.joinedAt * 1000).toLocaleString()}}
</template>

<script lang="ts" setup>
import { useQuery } from "villus";
import { computed } from "vue";

import type { GetManagementStatsQuery } from "@/graphql";
import { GetManagementStatsDocument } from "@/graphql";

const { data } = useQuery<GetManagementStatsQuery>({ query: GetManagementStatsDocument });
const sortedUsers = computed(() => data.value ? [...data.value.allUsers].sort((a, b) => a.id - b.id) : null);
</script>