<template lang="pug">
TitleTag(title="Trackers")

.container
    ol.breadcrumb
            li.breadcrumb-item.active
                span(aria-current="location") Trackers
    
    h1.display-4 Issue trackers

    .row.gx-5.mx-md-5(v-if="data")
        .col(v-for="prod in data.products")
            .card(style="min-height: 8rem")
                .card-body.text-center
                    h3.my-4.mb-2
                        router-link.stretched-link(:to="'/tracker/' + prod.id")
                            | {{prod.prettyName}}
</template>

<script lang="ts" setup>
import { useQuery } from "villus";

import type { GetProductsQuery } from "@/graphql";
import { GetProductsDocument } from "@/graphql";
import { TitleTag } from "@/utils";

const { data } = await useQuery<GetProductsQuery>({ query: GetProductsDocument });
</script>