import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bccd8472"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "like-button"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "ms-2 text-danger"
}
const _hoisted_4 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (!$props.compact)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["btn", {'btn-outline-primary': !$setup.userVoted, 'btn-success': $setup.userVoted}]),
          onClick: $setup.rateClicked,
          disabled: !$setup.authed
        }, [
          ($setup.userVoted)
            ? (_openBlock(), _createBlock(_component_icon, {
                key: 0,
                icon: ['fas', 'heart'],
                class: _normalizeClass({'text-danger': $props.compact})
              }, null, 8, ["class"]))
            : (_openBlock(), _createBlock(_component_icon, {
                key: 1,
                class: "text-muted",
                icon: ['far', 'heart']
              })),
          _createTextVNode(" " + _toDisplayString($props.rating?.score), 1)
        ], 10, _hoisted_2)), [
          [_directive_tooltip, $setup.authed ? null : 'Log in to rate']
        ]),
        ($setup.error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "There was an error submitting your rating"))
          : _createCommentVNode("", true)
      ]))
    : _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "like-button compact",
        onClick: $setup.rateClicked,
        disabled: !$setup.authed
      }, [
        ($setup.userVoted)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              icon: ['fas', 'heart'],
              class: _normalizeClass({'text-danger': $props.compact})
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_icon, {
              key: 1,
              class: "text-muted",
              icon: ['far', 'heart']
            })),
        _createTextVNode(" " + _toDisplayString($props.rating?.score), 1)
      ], 8, _hoisted_4)), [
        [
          _directive_tooltip,
          $setup.authed ? 'Rate comment' : 'Log in to rate',
          "left",
          { client: true }
        ]
      ])
}