import { library } from '@fortawesome/fontawesome-svg-core'
import { faDiscord, faTwitter, faYoutube, faSteam } from '@fortawesome/free-brands-svg-icons';
import { faHeart as farHeart, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faExclamationCircle, faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { faHeart as fasHeart, faReply, faTrash, faSignInAlt, faUser, faEdit, faMinus, faPlus, faBell, faCheck, faCog, faHome, faCommentAlt, faPeopleCarry, faEnvelope, faDownload, faExternalLinkAlt, faCubes, faInfoCircle, faRss } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { App } from 'vue';

library.add(farHeart, fasHeart, faReply, faTrash, faSignInAlt, faUser, faEdit, faMinus, faPlus, faEnvelope,
    faBell, faCheck, faCog, faHome, faYoutube, faDiscord, faTwitter, faSteam, faCommentAlt, faPeopleCarry,
    faDownload, faExternalLinkAlt, faGlobe, faCubes, faInfoCircle, faExclamationCircle, faPenToSquare, faRss);

export default function(app: App) {
    app.component("icon", FontAwesomeIcon);
}