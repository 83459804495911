<template lang="pug">
a.btn.btn-info(:href="'logicworld://' + creationId" @click="onOpen")
    icon.me-2(icon="external-link-alt")
    | Open in-game
</template>

<script setup lang="ts">
import { useEvents } from '@/mixins';

defineProps<{
    creationId: string
}>()

const events = useEvents();

function onOpen() {
    events.showOpenInGameModal();
}
</script>