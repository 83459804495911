import { createSSRApp } from 'vue'
import createRouter from './router'
import icons from './icons'
import createClient from "./villus-plugin"
import { CacheData } from './villus-plugins/cache'

import App from './App.vue'
import UserLink from "@/components/UserLink.vue"
import TimeAgo from "@/components/TimeAgo.vue"
import Spinner from "@/components/Spinner.vue"
import WatchQuery from './components/widgets/WatchQuery'
import IfFlag from './components/widgets/IfFlag.vue'

import directives from "./utils/directives"
import { VILLUS_CLIENT } from 'villus'
import { Head } from './utils/head'
import { provideSettings } from './mixins/settings'

import "./toast"
import { Cookies } from './utils/cookies'

export default function(head: Head, cookies: Cookies, initCache: CacheData) {
    const desc = "Logic World is the ultimate digital logic simulator. Out now for Windows, Mac and Linux.";
    head.setMeta("og:image", "https://assets.logicworld.net/img/thumbnail.png");
    head.setMeta("og:type", "image");
    head.setMeta("og:title", "Logic World");
    head.setMeta("og:description", desc);
    head.setMeta("description", desc);
    
    const app = createSSRApp(App);
    const settings = provideSettings(app, cookies);
    const router = createRouter(settings);
    const villus = createClient(initCache);

    app.provide(VILLUS_CLIENT, villus.client)
        .component("user-link", UserLink)
        .component("time-ago", TimeAgo)
        .component("spinner", Spinner)
        .component("IfFlag", IfFlag)
        .component("watchQuery", WatchQuery)
        .use(head)
        .use(directives)
        .use(icons)
        .use(cookies)
        .use(router);

    return { app, router, cache: villus.cache, settings: settings, client: villus.client }
}
