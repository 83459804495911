import { CommentFieldsFragment, PingDocument } from '@/graphql';
import mitt, { Handler } from 'mitt';
import { onMounted, onUnmounted, ref, Ref } from "vue";
import { Toast } from '@/toast';
import { useQuery } from 'villus';
import { logDebug } from '@/utils';

export { useSettings } from "./settings";
export * from "./auth";

// Events
const emitter = mitt();

emitter.on("*", (type, o) => logDebug("emitted " + type.toString(), o));

function event<T = void>(name: string) {
    function caller(ev?: T) {
        emitter.emit(name, ev);
    }
    caller.type = name;
    caller.on = (handler: Handler<T>) => emitter.on(name, handler as any);
    caller.off = (handler: Handler<T>) => emitter.off(name, handler as any);
    return caller
}

const events = {
    emitter,
    loaded: event("loaded"),
    newComment: event<CommentFieldsFragment>("newComment"),
    newToast: event<Toast>("newToast"),
    toggleNotifsModal: event("toggleNotifsModal"),
    error: event<number>("error"),
    clearCache: event("clearCache"),
    routeChanged: event("routeChanged"),
    showOpenInGameModal: event("showOpenInGameModal"),
};
export const useEvents = () => events;

export function usePreventClose(notEmpty: Ref<any>) {
    function handler() {
        if (notEmpty.value)
            return "You have unsaved work. Are you sure?";
    }

    onMounted(() => window.addEventListener("beforeunload", handler));
    onUnmounted(() => window.removeEventListener("beforeunload", handler));
}

export function doPingTest() {
    const { newToast } = useEvents();

    useQuery({ query: PingDocument, cachePolicy: "network-only", fetchOnMount: false })
        .execute()
        .then((o) => {
            if ((o.error || o.data?.ping !== "pong") && !isSSR) {
                newToast({
                    title: "Request error",
                    body: "The Logic World website API seems to be down. Please try refreshing the page in a few seconds.",
                    time: new Date(),
                    duration: 0,
                    status: "error"
                });
            }
        });
}

// Time
const time = ref<Date>(new Date());

const updateTime = () => time.value = new Date();

export const useTime = () => {
    updateTime();
    return time;
};
if (!isSSR)
    setInterval(updateTime, 30000);
