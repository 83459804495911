<template lang="pug">
li.nav-item(v-if="canAccess")
    router-link.nav-link(:to="{name:'manpanel', params:{panel}}" :class="{active: currentPanel == panel}")
        icon(:icon="panels[panel][1]" fixed-width)
        | {{panels[panel][0]}}
</template>

<script lang="ts" setup>
import { inject } from "vue";
import type { PropType } from "vue";

import { currentPanelKey, panels } from "@/utils/management";
import type { Panels } from "@/utils/management";
import { useAuth } from "@/mixins";

const props = defineProps({
    panel: {
        type: String as PropType<Panels>,
        required: true
    }
})

const { hasFlag } = useAuth();
const currentPanel = inject(currentPanelKey)!;

const canAccess = hasFlag(`can-access-manpanel-${props.panel}`);
</script>

<style lang="scss" scoped>
li > a {
    color: white;

    & > :first-child {
        margin-right: .5rem;
    }
}
</style>