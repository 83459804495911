import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-muted" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_spinner = _resolveComponent("spinner")

  return (!$setup.data)
    ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode($setup["CommentBox"], {
          class: "mb-2",
          objectid: $props.objectid
        }, null, 8, ["objectid"]),
        _createElementVNode("span", _hoisted_1, _toDisplayString($setup.data.comments.length) + " comments", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.topLevelComments, (cmt) => {
          return (_openBlock(), _createBlock($setup["Comment"], {
            key: cmt.id,
            comment: cmt,
            depth: 0,
            allComments: $setup.data.comments
          }, null, 8, ["comment", "allComments"]))
        }), 128))
      ], 64))
}