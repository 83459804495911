<template lang="pug">
.markdown-body(v-html="html" ref="container")
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router";

export default defineComponent({
    props: {
        html: String
    },
    setup(props) {
        const container = ref<HTMLElement>();
        const router = useRouter();

        onMounted(() => {
            watch(() => props.html, () => {
                if (!container.value) {
                    throw new Error("Container is missing");
                }

                nextTick(() => {
                    container.value!.querySelectorAll("a").forEach(a => {
                        const url = new URL(a.href);

                        if (url.protocol == "logicworld:") {
                            a.classList.add("logicworld");
                        } else if (url.origin === location.origin) {
                            if (url.pathname.startsWith("/@")) {
                                a.classList.add("mention");
                            }

                            a.addEventListener("click", o => {
                                o.preventDefault();
                                router.push(url.pathname);
                            })
                        } else {
                            a.target = "_blank";

                            if (url.host != "logic.world") {
                                a.title = "This link leads to a page outside logicworld.net";
                                a.classList.add("external")
                            }
                        }
                    });

                    container.value!.querySelectorAll("h1,h2,h3,h4,h5").forEach(o => {
                        const link = document.createElement("a");
                        link.classList.add("marker");
                        link.text = "§";
                        link.href = "#" + o.id;
                        o.appendChild(link);
                    });
                });
            }, { immediate: true })
        })

        return { container }
    }
})
</script>

<style lang="scss">
@import "@/styles/style";

.markdown-body {
    a.mention {
        color: var(--bs-yellow);
    }

    $inline-icon-size: .7rem;

    a.external::after {
        display: inline-block;

        $fill: red;
        $svg1: 'data:image/svg+xml,<svg aria-hidden="true" focusable="false" fill="';
        $svg2: '" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>';

        width: $inline-icon-size;
        height: $inline-icon-size;
        margin-left: .2rem;

        @include light() {
            content: url($svg1 + "black" + $svg2);
            opacity: 0.5;
        }
        @include dark() {
            content: url($svg1 + "green" + $svg2);
        }
    }

    a.logicworld::after {
        width: $inline-icon-size * 1.2;
        height: $inline-icon-size * 1.2;
        display: inline-block;

        content: " ";
        background-image: url("https://logicworld.net/favicon.ico");
        background-size: contain;
        margin-left: .2rem;
    }

    @for $i from 1 to 6 {
        h#{$i} {
            position: relative;
            
            & > .marker {
                opacity: 0;
                position: absolute;
                left: -.5em;

                @include dark() {
                    top: 2px;
                    left: -.65em;
                    font-size: .8em;
                }
            }
            &:hover > .marker {
                opacity: 1;
            }
        }
    }
}
</style>