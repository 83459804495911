import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return _withDirectives((_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["time-ago ms-1", {'text-muted': !$props.raw}])
  }, [
    _createTextVNode(_toDisplayString($setup.formatted), 1)
  ], 2)), [
    [_directive_tooltip, $setup.date?.toString()]
  ])
}