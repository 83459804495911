<template lang="pug">
TitleTag(title="Create post")

.container
    h1.display-4 Create new post

    .card
        v-form.card-body(:form="form")
            .d-sm-flex
                v-field.me-1(name="title" :show-error="false")
                v-submit
            v-error(is="div" for="title")
            v-field.mt-3.body(name="body" is="textarea" placeholder="Body (markdown is supported!)" :initialHeight="200")

            v-error.ms-2(is="span")

            v-image.mt-2
</template>

<script lang="ts" setup>
import type { CreatePostMutation, CreatePostMutationVariables } from '@/graphql';
import { CreatePostDocument } from '@/graphql';
import { useMutation } from 'villus';
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { usePreventClose } from '@/mixins';

import { VForm, VField, VSubmit, VError, VImage, Form, TitleTag } from "@/utils";
import * as yup from "yup"

const route = useRoute();
const router = useRouter();

const form = new Form(yup.object({
    title: yup.string().label("Title").required("A post title is required").min(10).default(""),
    body: yup.string().label("Body").required("A post body is required").default("")
}), submit);

usePreventClose(computed(() => form.data.title || form.data.body));

const { execute } = useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument);

async function submit() {
    var { data, error } = await execute({
        forumid: route.params["id"] as string,
        ...form.copyData()
    });

    if (error) {
        throw error;
    }
    router.push({ name: "post", params: { id: data.createPost.id } });
}
</script>

<style lang="sass" scoped>
button.submit
    width: 100px

.body
    min-height: 200px
</style>