<template lang="pug">
TitleTag(title="Forums index")

.container(v-if="data")
    ol.breadcrumb
        li.breadcrumb-item.active
            span(aria-current="location") Forums

    span.display-4 Forums index
    .mt-2.d-block
        .card.mb-1(v-for="forum in data.forums")
            .card-body
                router-link.forum-name.stretched-link(:to="{name:'forum',params:{id:forum.id}}") {{forum.title}}
                br
                span {{forum.description}}
</template>

<script lang="ts" setup>
import { useQuery } from "villus";

import { GetForumsDocument } from "@/graphql";
import { TitleTag } from "@/utils";

const { data } = await useQuery({ query: GetForumsDocument });
</script>

<style lang="sass" scoped>
.forum-name
    font-size: 1.5rem
</style>