import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cedfbab9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_select = _resolveDirective("select")

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: "form-control short-link discrete",
    value: 'https://logic.world/' + $props.obj,
    readonly: "readonly"
  }, null, 8, _hoisted_1)), [
    [_directive_select]
  ])
}