export enum LogLevel {
    Trace,
    Debug,
    Info,
    Warning,
    Error
}

const logFuncs = {
    [LogLevel.Trace]: console.debug,
    [LogLevel.Debug]: console.debug,
    [LogLevel.Info]: console.info,
    [LogLevel.Warning]: console.warn,
    [LogLevel.Error]: console.error,
} as const;

const logLevelNames = {
    [LogLevel.Trace]: "TRACE",
    [LogLevel.Debug]: "DEBUG",
    [LogLevel.Info]: "INFO",
    [LogLevel.Warning]: "WARNING",
    [LogLevel.Error]: "ERROR",
} as const;

let currentLevel = LogLevel.Info;

if (process.env.NODE_ENV === "development") {
    currentLevel = LogLevel.Debug;

    if (typeof window !== "undefined") {
        (window as any).logLevel = (level: LogLevel) => currentLevel = level;
    }
}

export const logTrace = (msg: any, ...params: any[]) => logLevel(LogLevel.Trace, msg, ...params);
export const logDebug = (msg: any, ...params: any[]) => logLevel(LogLevel.Debug, msg, ...params);
export const logInfo = (msg: any, ...params: any[]) => logLevel(LogLevel.Info, msg, ...params);
export const logWarning = (msg: any, ...params: any[]) => logLevel(LogLevel.Warning, msg, ...params);
export const logError = (msg: any, ...params: any[]) => logLevel(LogLevel.Error, msg, ...params);

export function logLevel(level: LogLevel, msg: any, ...params: any[]) {
    if (level >= currentLevel) {
        const fn = logFuncs[level] ?? console.log;

        fn(`[${logLevelNames[level]}]: ${msg}`, ...params);
    }
}
