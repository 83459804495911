import { defineComponent, h, Transition } from "vue";

export default defineComponent({
    props: {
        collapsed: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { slots }) {
        if (!slots.expanded || !slots.collapsed) {
            throw new Error("Missing slots");
        }

        function enter(element: any) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = "";
            element.style.position = "";
            element.style.visibility = "";
            element.style.height = "0";

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        }

        function afterEnter(element: any) {
            element.style.height = "auto";
        }

        function leave(element: any) {
            const height = getComputedStyle(element).height;
    
            element.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        }

        return () => h(Transition, {
            name: "expand",
            onEnter: enter,
            onAfterEnter: afterEnter,
            onLeave: leave
        }, {
            default: () => props.collapsed ? slots.collapsed!() : slots.expanded!()
        });
    }
})
