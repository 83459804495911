<template lang="pug">
TitleTag(title="")

.section-container
    section.main-section
        .background(:class="{moving}" :style="{'--transition-dur': transitionDurationStr}")
            responsive-img.image.current(:base="images[currentImage]" cover alt="Current background image")
            responsive-img.image.next(v-if="loadImage !== null" :base="images[loadImage]" @load="imageLoaded" cover aria-hidden="true")

        .h-100.d-flex.flex-column.justify-content-center
            .dark-strip
                .container
                    .row
                        .col-lg-6.d-flex.align-content-center.justify-content-end
                            img.w-100.logo(src="https://assets.logicworld.net/img/logo_white.svg" alt="Logic World logo")
                        .col-lg-6.d-flex.flex-column.justify-content-lg-center
                            span.promo.text-center
                                | The ultimate digital logic simulator.
                                br
                                | Now available for Windows, Mac and Linux.
                            a.btn.btn-secondary.mt-2(href="https://store.steampowered.com/app/1054340/Logic_World/" target="_blank")
                                icon.me-2(:icon="['fab', 'steam']")
                                | Buy now on Steam
                            .d-flex.buttons
                                router-link.btn.btn-success.text-dark-on-light.flex-grow-1(to="/newsletter") Subscribe to our newsletter
                                router-link.btn.btn-primary.text-dark-on-light.flex-grow-1(to="/view/frm-devupdts") Read our development blog
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref } from 'vue';

import ResponsiveImg from '@/components/ResponsiveImg.vue';
import { TitleTag } from '@/utils';

const images = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => `https://assets.logicworld.net/img/media/home/${i}`);
const currentImage = ref(0);
const loadImage = ref<number | null>(null);

const moving = ref(false);
const transitionDuration = ref(1);
const transitionDurationStr = computed(() => transitionDuration.value + "s");

let imageLoadResolve: Function | undefined;
let currentTimeout: ReturnType<typeof setTimeout>;

onUnmounted(() => clearTimeout(currentTimeout));
    
function imageLoaded() {
    if (imageLoadResolve)
        imageLoadResolve();
}

async function advance() {
    const prom = new Promise(resolve => imageLoadResolve = resolve);
    loadImage.value = (currentImage.value + 1) % images.length;
    await prom;

    moving.value = true;

    currentTimeout = setTimeout(() => {
        moving.value = false;

        currentImage.value = loadImage.value ?? 0;

        currentTimeout = setTimeout(advance, 6000);
    }, transitionDuration.value * 1000);
}

if (!isSSR)
    currentTimeout = setTimeout(advance, 4000);
</script>

<style lang="scss" scoped>
.section-container {
    position: absolute;
    top: 0;
    height: 100%;
}

.main-section {
    position: relative;
    width: 100vw;
    height: 100%;

    .promo {
        color: #e0e0e0;
        text-shadow: 2px 2px rgba(0,0,0,.603);
        font-family: "Lato";
        font-size: 1.5em;
    }

    .logo {
        max-width: 550px;
    }
}

.dark-strip {
    background-color: rgba(black, .5);
    box-shadow: 0px 0px 38px 4px #000000;
}

.background {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;

    & > .image {
        position: absolute;

        &.next {
            z-index: -2;
        }
    }

    &.moving > .image {
        transition: opacity var(--transition-dur) ease-out; //cubic-bezier(.15,.66,.26,1)

        @media (prefers-reduced-motion) {
            transition: none;
        }

        &.current {
            opacity: 0;
        }
    }
}
</style>