import type { InjectionKey, Ref, Component } from "vue";
import type { FlagNames } from "@/mixins";

type GetPanels<S> = S extends `can-access-manpanel-${infer T}` ? T : never;
export type Panels = GetPanels<FlagNames>;

export const currentPanelKey: InjectionKey<Ref<Panels>> = Symbol("currentPanel");

import Index from "@/views/manpanel/Index.vue"
import Newsletter from "@/views/manpanel/Newsletter.vue"
import UserList from "@/views/manpanel/UserList.vue"

export const panels: {[K in Panels]: [name: string, icon: string, component: Component]} = {
    index:      ["Index",       "home",     Index],
    newsletter: ["Newsletter",  "envelope", Newsletter],
    userlist:   ["User list",   "user",     UserList],
};

export const panelKeys: Panels[] = Object.keys(panels) as any;
