import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ecb4a4e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("textarea", {
    ref: "textArea",
    value: $props.modelValue,
    onInput: _cache[0] || (_cache[0] = $event => ($setup.changed())),
    style: _normalizeStyle({height: $setup.height + 'px'}),
    onKeypress: $setup.keyPressed
  }, null, 44, _hoisted_1))
}