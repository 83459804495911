<template lang="pug">
main(aria-live="polite" aria-atomic="true")
    .toast.mb-3(v-for="toast in toasts" role="alert" aria-live="assertive" aria-atomic="true" :class="{linked: toast.href}" @click="clicked(toast)"
            :key="toast.time.getTime()" :ref="el => shown(el, toast)")

        .toast-header
            .toast-icon.rounded.me-2(:class="{'bg-danger': toast.status == 'error', 'bg-success': toast.status == 'success', 'bg-warning': toast.status == 'warning'}")
            strong.me-auto
                | {{toast.title}}
            time-ago(:time="toast.time")
            button.btn-close(type="button" data-bs-dismiss="toast" aria-label="Close" @click.stop="remove(toast)")
        
        .toast-body(v-html="toast.body")
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router';

import { Toast } from "@/toast";
import { useEvents } from '@/mixins';

export default defineComponent({
    setup() {
        const toasts = ref<Toast[]>([]);

        const router = useRouter();
        const toastMap = new Map<Toast, import("bootstrap").Toast>();

        const { newToast } = useEvents();
        newToast.on(t => t && toasts.value.push(t));

        function remove(t: Toast, force?: true) {
            if (force) {
                toasts.value.splice(toasts.value.indexOf(t), 1);
                toastMap.delete(t);
            } else {
                toastMap.get(t)?.hide();
            }
        }

        async function shown(e: HTMLElement, toast: Toast) {
            if (!e) {
                return;
            }

            const { Toast: bToast } = await import("bootstrap")

            const btoast = new bToast(e, {
                autohide: toast.duration !== 0,
                delay: toast.duration ?? 10000
            });
            btoast.show();
            toastMap.set(toast, btoast);

            e.addEventListener("hidden.bs.toast", () => remove(toast, true));
        }

        function clicked(toast: Toast) {
            if (toast.href) {
                if (typeof toast.href == "string" && /^(?:[a-z]+:)?\/\//i.test(toast.href)) {
                    location.href = toast.href;
                } else {
                    router.push(toast.href);
                }

                remove(toast);
            }
        }

        return { toasts, shown, clicked, remove }
    }
})
</script>

<style lang="sass" scoped>
main
    position: absolute
    top: 1rem
    right: 1rem
    z-index: 1025
    max-height: 80vh

    overflow-y: auto

.toast-icon
    display: inline-block
    width: 20px
    height: 20px
    font-size: 1.125rem
    text-anchor: middle

    &.danger
        background-color: var(--bs-danger)
    &:not(.danger)
        background-color: var(--bs-primary)

.toast.linked
    .close, .time-ago
        z-index: 1010

    &::after
        content: " "
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        cursor: pointer
        z-index: 1000
</style>