<template lang="pug">
.theme.d-flex.flex-column(:class="[theme]")
    link(rel="stylesheet" v-if="(theme === 'auto') || theme === 'light'" :href="'https://cdn.jsdelivr.net/npm/bootswatch@5.0.2/dist/cosmo/bootstrap.min.css'" media="screen")
    link(rel="stylesheet" v-if="(theme === 'auto') || theme === 'dark'" :href="'https://cdn.jsdelivr.net/npm/bootswatch@5.0.2/dist/darkly/bootstrap.min.css'" :media="theme === 'dark' ? 'screen' : 'screen and (prefers-color-scheme: dark)'")
    Navbar

    .well.flex-grow-1
        ToastView
        ErrorPage(v-if="errorCode" :code="errorCode")

        template(v-else)
            router-view(v-slot="{ Component, route }")
                suspense(@pending="startLoading" @resolve="loading = false" :timeout="0")
                    component(:is="Component" :key="(route.meta.usePathKey ? route.path : undefined) + JSON.stringify(route.query)")
                    template(#fallback)
                        spinner

    Footer(v-if="showFooter")
    NotificationsModal
    OpenInGameModal
</template>

<script lang="ts" setup>
import { computed, onErrorCaptured, onMounted, ref } from 'vue';
import { CombinedError } from 'villus';
import { useRouter } from 'vue-router';

import { doPingTest, useEvents, useSettings } from "@/mixins"
import Navbar from "@/components/Navbar.vue"
import ToastView from "@/components/ToastView.vue"
import Footer from "@/components/Footer.vue"
import OpenInGameModal from "@/components/OpenInGameModal.vue"

import NotificationsModal from "@/views/NotificationsModal.vue"
import ErrorPage from "@/views/Error.vue"
import { UnauthorizedError } from './utils';

const router = useRouter();
const { newToast, error, routeChanged } = useEvents();
const settings = useSettings();

const errorCode = ref<number | undefined>(0);
const loading = ref(false);
const loadMousePos = ref<{ left: number; top: number }>();
const showFooter = computed(() => router.currentRoute.value.meta.footer !== false);

const { error: errorEvent, clearCache } = useEvents();

errorEvent.on(code => (errorCode.value = code, loading.value = false));
router.beforeEach(() => routeChanged());
router.afterEach(() => errorCode.value = undefined);

if (!isSSR) {
    doPingTest();

    document.addEventListener("keydown", async ev => {
        if (ev.key === "r" && ev.ctrlKey) {
            ev.preventDefault();
            clearCache();

            const route = router.currentRoute.value;
            await router.replace("/");
            await router.replace(route)
        }
    })
}

const theme = computed(() => settings.theme.value);

onErrorCaptured(err => {
    if (err instanceof CombinedError) {
        let code: number = 0;

        if (err.isGraphQLError && err.graphqlErrors) {
            const e = err.graphqlErrors[0];

            code = Number(e.message);

            if (isNaN(code)) {
                console.error("Unknown error", e);
            }
        } else if (err.networkError) {
            const resp = err.response as Response;
            code = resp?.status;
        }

        if (!code) {
            code = 0;
        }

        error(code);
    } else if (err instanceof UnauthorizedError) {
        router.replace("/error?code=403");
    } else {
        newToast({
            title: "Unhandled error",
            body: "An unexpected error has occurred. Check your console for more details.",
            time: new Date(),
            duration: 0,
            status: "error"
        });
    }
})

let mousePos: { left: number; top: number };
onMounted(() => window.addEventListener("mousedown", o => mousePos = { left: o.clientX, top: o.clientY }));

function startLoading() {
    loadMousePos.value = mousePos;
    loading.value = true;
}
</script>

<style lang="scss" scoped>
.well {
    position: relative;
    width: 100%;
}
</style>

<style lang="scss">
@import "@/styles/style";

html {
    scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.theme {
    height: 100vh;
}

.markdown-body {
    max-width: 60em;

    img {
        max-width: 100%;
    }

    & blockquote {
        @include dark {
            padding-left: 1rem;
            border-left: 4px solid rgba(255, 255, 255, .2);
        }
    }

    @include light(false) {
        @import "@primer/css/markdown/index.scss";
    }
    @include dark(true) {
        hr {
            border-top: 1px solid rgba(255, 255, 255, .2);
        }
        h1, .h1 {
            font-size:2.34375rem
        }
        h2, .h2 {
            font-size:1.875rem
        }
        h3, .h3 {
            font-size:1.640625rem
        }
        h4, .h4 {
            font-size:1.40625rem
        }
        h5, .h5 {
            font-size:1.171875rem
        }
        h6, .h6 {
            font-size:0.9375rem
        }
    }
}

.container, .container-fluid {
    padding-top: 1rem;
}

.action-button {
    cursor: pointer
}

.tooltip {
    pointer-events: none;
}

a {
    outline: none;
}

.dropdown-menu {
    z-index: 1030;
}
</style>
