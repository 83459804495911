<template lang="pug">
.comment.position-relative(:class="['depth-'+((depth ?? 0) % 13), isHighlighted ? 'highlighted' : '']" :collapsed="collapsed" v-if="comment")
    .marker(:id="comment.id")

    TransitionExpand(:collapsed="collapsed")
        template(#expanded)
            .part
                .collapse-button.d-flex.me-2.mt-1(v-if="!isEditing")
                    .dropstart(v-if="canEdit || canDelete")
                        icon.text-muted.dropdown-toggle(icon="cog" type="button" :id="'opt' + comment.id" v-dropdown)
                        .dropdown-menu(role="menu" :aria-labelledby="'opt' + comment.id")
                            a.dropdown-item(v-if="canEdit" href="#" @click.prevent="isEditing = true")
                                icon.me-2(icon="edit" fixed-width)
                                | Edit
                            a.dropdown-item(v-if="canDelete" href="#" @click.prevent="deleteComment")
                                icon.me-2(icon="trash" fixed-width)
                                | Delete
                    .ms-2(@click="collapsed = !collapsed")
                        icon.text-muted(icon="minus")

                .d-flex(@click="unHighlight")
                    router-link.text-center.me-3(:to="'/@' + comment.author.username")
                        UserPicture.mb-2(:user="comment.author" round shadow)

                    .flex-grow-1.position-relative.inner
                        span
                            user-link(:user="comment.author")
                            a.text-muted(:href="'#' + comment.id")
                                small
                                    time-ago(:time="comment.createdat")
                                    template(v-if="comment.editedat")
                                        |  (edited
                                        time-ago(:time="comment.editedat")
                                        | )

                        .clearfix(v-if="!isEditing")
                            Markdown.comment-text(:html="comment.renderedbody")
                            
                            .float-end.d-flex.align-items-middle.m-2
                                LikeButton(:objectid="comment.id" :rating="comment.rating" compact)
                                a.ms-2.text-muted.text-decoration-none(href="#" v-if="authed" @click.prevent="replying = !replying")
                                    | Reply
                                    icon.ms-2.mt-1(icon="reply")

                        div(v-else)
                            FitTextArea.form-control(v-model="newBody" @submit="submitEdit")
                            .my-2.float-right
                                button.btn.btn-danger.me-2(@click="isEditing = false") Cancel
                                button.btn.btn-success(@click="submitEdit") Submit

                        CommentBox.mb-2.reply-box(v-if="replying" canCancel :objectid="comment.objectid" :parentid="comment.id" @sent="replying = false" @cancel="replying = false")

                Comment(v-for="cmt in children" :key="cmt.id" :comment="cmt" :depth="(depth ?? 0) + 1" :allComments="allComments")

        template(#collapsed)
            small.text-muted.part
                .cover(@click="collapsed = false")
                span @{{comment.author?.username}}
                time-ago(:time="comment.createdat")
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useMutation } from 'villus';
import { useRoute, useRouter } from 'vue-router';

import endpoints from "@/endpoints";
import { useAuth } from "@/mixins"
import { canAllOrOwn, typedProp } from "@/utils"
import { Comment, DeleteCommentDocument, DeleteCommentMutation, DeleteCommentMutationVariables, EditCommentDocument, EditCommentMutation, EditCommentMutationVariables } from "@/graphql"

import LikeButton from "@/components/LikeButton.vue";
import CommentBox from "@/components/CommentBox.vue";
import TransitionExpand from "@/components/Expand";
import Markdown from "@/components/Markdown.vue";
import FitTextArea from "@/components/widgets/FitTextArea.vue";
import UserPicture from "@/components/widgets/UserPicture.vue";

export default defineComponent({
    name: "Comment",
    props: {
        comment: typedProp<Comment>(undefined, true),
        allComments: typedProp<Comment[]>(false),
        depth: Number
    },
    components: {
        LikeButton,
        CommentBox,
        TransitionExpand,
        Markdown,
        FitTextArea,
        UserPicture
    },

    setup(props) {
        const children = computed(() => props.allComments?.filter(o => o.parentid == props.comment?.id));
        
        const collapsed = ref(false);
        const replying = ref(false);
        const { authed, currentUser } = useAuth();

        const route = useRoute();
        const router = useRouter();
        const isHighlighted = computed(() => route.hash && (route.hash.substring(1) == props.comment?.id));

        function unHighlight() {
            if (isHighlighted.value) {
                router.replace({
                    ...route,
                    hash: ""
                })
            }
        }
        
        function useEdit() {
            const isEditing = ref(false);

            const newBody = ref(props.comment?.body ?? "empty comment");

            const { execute } = useMutation<EditCommentMutation, EditCommentMutationVariables>(EditCommentDocument);

            const canEdit = canAllOrOwn("can-edit-comment", () => props.comment?.authorid);

            async function submitEdit() {
                if (!props.comment || /^\s*$/.test(newBody.value) || !canEdit.value) {
                    return;
                }

                isEditing.value = false;

                var { data, error } = await execute({ id: props.comment.id, newBody: newBody.value });

                if (error) {
                    isEditing.value = true;
                } else {
                    props.comment.renderedbody = data!.editComment!.renderedbody;
                    props.comment.editedat = Date.now() / 1000;
                }
            }

            return { isEditing, submitEdit, newBody, canEdit }
        }

        function useDelete() {
            const { execute } = useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument);

            const canDelete = canAllOrOwn("can-delete-comment", () => props.comment?.authorid);

            async function deleteComment() {
                if (!(currentUser && props.allComments && props.comment)) {
                    return;
                }

                if (!confirm("Are you sure you want to delete this comment?")) {
                    return;
                }

                // Remove comment for visual snappiness
                var cmtIndex = props.allComments.indexOf(props.comment as any);
                props.allComments.splice(cmtIndex, 1);

                var { error } = await execute({ id: props.comment.id });

                // If there's an error, pop the comment back on
                if (error) {
                    props.allComments.splice(cmtIndex, 0, props.comment as any);
                }
            }

            return { deleteComment, canDelete }
        }

        return { children, asset: endpoints.asset, replying, collapsed, authed, isHighlighted, unHighlight, ...useDelete(), ...useEdit() }
    }
})
</script>

<style lang="sass">
.comment-text
    & p:last-of-type
        margin-bottom: 0
    & img
        max-width: 100%

.comment blockquote
    opacity: .7
    border-left: 3px solid #888B
    padding-left: .5rem
</style>

<style lang="sass" scoped>
@import "@/styles/style"

.action-button
    opacity: 0.3
    transition: opacity .15s
    display: block
    margin-bottom: .3rem

    .media:hover &
        opacity: 1

.comment
    @include dark
        border: 1px solid #80808070
    @include light
        border: 1px solid #dee2e6

    border-left-width: 3px !important
    position: relative
    padding-left: 1rem !important
    display: grid
    padding: .5rem

    .marker
        position: absolute

    .collapse-button
        position: absolute
        right: .5rem
        top: .25rem
        z-index: 10
        cursor: pointer
        opacity: 0.3
        transition: opacity .15s

    &:hover .collapse-button
        opacity: 1

    &.highlighted
        background: rgba(yellow, .2)

    .part
        grid-column-start: 1
        grid-row-start: 1

    &:not([collapsed="true"])
        padding-top: .8rem
    &[collapsed="true"]
        padding-bottom: .4rem

        & > * > .cover
            position: absolute
            left: 0
            top: 0
            right: 0
            bottom: 0
            cursor: pointer

    @for $i from 0 through 12
        &.depth-#{$i}
            @include dark
                border-left-color: hsla($i * 30, 100%, 80%, .8) !important
            @include light
                border-left-color: hsl($i * 30, 70%, 80%) !important

    &.highlighted
        background: #ffff0029

    &:not(:last-child)
        margin-bottom: 1rem

    .reply-box
        animation-name: cmtbox-show
        animation-duration: 0.2s
        animation-timing-function: ease-out
        animation-fill-mode: forwards

.fade-enter-active, .fade-leave-active
    transition: max-height .2s

.fade-enter, .fade-leave-to
    max-height: 0

@keyframes cmtbox-show
    from
        height: 0
        min-height: 40px
    to
        min-height: 10em
</style>
