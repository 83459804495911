<template lang="pug">
draggable.d-flex.flex-wrap(:list="files" item-key="img" :disabled="!reorderable || files.some(o => o.loading)")
    template(#item="{element, index}")
        .image-box.mb-2(style="overflow: hidden" :class="{'mx-auto': evenImages, 'me-2': !evenImages, 'added': addedFiles[element.id] || !showNotAddedWarning}"
                        v-tooltip:top="addedFiles[element.id] || !showNotAddedWarning ? '' : `You haven't added this image to your post, click to insert it`")
            .thumbnail.card
                .img(v-if="!element.loading" :style="{backgroundImage: 'url(' + element.img + ')'}" @click="$emit('insertImage', element)")
                .spinner.d-flex.justify-content-center.align-items-center(v-else)
                    .spinner-border

                .delete(@click="deleteFile(index)")
                    icon(icon="trash")

                icon.not-added(v-if="!element.loading && !addedFiles[element.id] && showNotAddedWarning" icon="far fa-exclamation-circle")
            .name.user-select-none {{element.name}}

    template(#footer)
        input(type="file" accept="image/*" id="newpic" style="display:none" multiple @change="newFile")
        label.card.image-box.add.d-flex.justify-content-center.align-items-center(for="newpic" v-tooltip="'Upload image'")
            span.sr-only Upload image
            icon(icon="plus")

        .text-center.fw-bold.text-danger(v-if="files.length == 0 && showNoImagesWarning") Please upload at least one picture of your creation
</template>

<script lang="ts" setup>
import { computed, reactive, ref, watch } from "vue"
import type { PropType } from "vue"
import draggable from 'vuedraggable'

import { imageUrl, uploadFile } from "@/utils";

const emit = defineEmits(["insertImage", "deletedImage", "update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: Array as PropType<{ id: string; name: string, added: boolean }[]>,
        default: []
    },
    evenImages: {
        type: Boolean,
        default: true
    },
    text: {
        type: String,
        required: true
    },
    reorderable: Boolean,
    showNotAddedWarning: {
        type: Boolean,
        default: true
    },
    showNoImagesWarning: Boolean
})

type Image = { img: string, id: string, name: string, loading: boolean };

const files = ref<Image[]>(props.modelValue.map(({id, name}) => ({ id, name, loading: false, img: `https://assets.logicworld.net/upload/${id}` })));
watch(files, files => {
    const value = files
                .filter(o => !o.loading)
                .map(o => ({ id: o.id, name: o.name, added: addedFiles.value[o.id] ?? false }));

    emit("update:modelValue", value);
}, { deep: true });

const addedFiles = computed(() => {
    const ret: Record<string, boolean> = {};

    for (const file of files.value) {
        const url = imageUrl(file.id);
        const added = new RegExp(`\\!\\[.*?\\]\\(${url}\\)`).test(props.text);
        
        ret[file.id] = added;
    }

    return ret;
})

async function upload(file: File) {
    const item = reactive({
        img: URL.createObjectURL(file),
        name: file.name,
        id: "",
        loading: true
    })
    const idx = files.value.push(item) - 1;

    const form = new FormData()
    form.append("file", file, file.name)

    let success = false;

    try {
        const result = await uploadFile(file, "image");

        if (result === false) {
            success = false;
        } else {
            success = true;
            item.id = result;
        }
    } finally {
        if (!success) {
            alert("Failed to upload image " + file.name);
            files.value.splice(idx, 1);
        }

        item.loading = false;
    }
}

async function newFile(e: Event) {
    const input = e.target as HTMLInputElement;
    
    if (!input.files || input.files.length == 0) {
        return;
    }

    await Promise.all(new Array(...input.files).map(upload));
}

function deleteFile(i: number) {
    const [file] = files.value.splice(i, 1);
    emit("deletedImage", file);
}
</script>

<style lang="scss" scoped>
.image-box {
    $size: 100px;
    width: $size;
    min-height: $size;
    overflow: hidden;
    position: relative;

    &.add {
        cursor: pointer;
        height: $size;

        * {
            width: 50%;
            height: 50%;
            opacity: .5;
        }
    }

    .name {
        $font: .7rem;
        text-align: center;
        line-height: 1.2;
        font-size: $font;
    }

    .thumbnail {
        cursor: pointer;
        width: $size;
        height: $size;

        & > .img {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            border: none;
        }

        & > .spinner {
            width: 100%;
            height: 100%;
            opacity: .5;
        }

        .delete {
            position: absolute;
            top: 0;
            right: 0;
            text-align: center;
            vertical-align: middle;

            height: $size * .3;
            width: $size * .3;

            background: #00000085;

            svg {
                margin: auto;
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
                color: whitesmoke;
            }
        }

        & > .not-added {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;

            width: 60%;
            height: 60%;

            color: var(--bs-warning);
            filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.6));

            animation: infinite alternate-reverse bobbing 2s;
        }
    }
}

@media (hover: hover) {
    .thumbnail .delete {
        transition: transform .1s;
        transform: translateX(110%);
    }

    .thumbnail:hover .delete {
        transform: none;
    }
}

@keyframes bobbing {
    0% { transform: translate(-50%, -50%) scale(1); }
    100% { transform: translate(-50%, -50%) scale(1.2); }
}
</style>