export async function uploadFile(file: File, format: "image" | "blotter"): Promise<string | false> {
    const form = new FormData();
    form.append("format", format);
    form.append("file", file, file.name);

    const resp = await fetch("/api/file", {
        method: "PUT",
        body: form,
    });

    if (!resp.ok) {
        return false;
    }

    return await resp.json() as string;
}

export function imageUrl(id: string) {
    return `https://assets.logicworld.net/upload/${id}`;
}
