<template lang="pug">
textarea(ref="textArea" :value="modelValue" @input="changed()" :style="{height: height + 'px'}" @keypress="keyPressed")
</template>

<script lang="ts" setup>
import { useSettings } from "@/mixins";
import { onMounted, ref } from "vue";

const props = defineProps({
    modelValue: String,
    initialHeight: {
        type: Number,
        default: 80
    }
});
const emit = defineEmits(["update:modelValue", "submit"]);

const height = ref(props.initialHeight);
const textArea = ref<HTMLTextAreaElement>();
const { autoResizeBoxes } = useSettings();

defineExpose({
    textArea
})

function calcHeight() {
    const newHeight = textArea.value!.scrollHeight;
    if (newHeight > height.value && autoResizeBoxes.value) {
        height.value = newHeight + 3;
    }
}
onMounted(calcHeight);

function changed() {
    const newText = textArea.value!.value;
    emit("update:modelValue", newText);

    calcHeight();
}

function keyPressed(e: KeyboardEvent) {
    if (e.key == "Enter" && e.ctrlKey) {
        e.preventDefault();
        emit("submit");
    }
}
</script>

<style scoped>
textarea {
    max-height: 100vh;
}
</style>