import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "n-modal card" }
const _hoisted_2 = { class: "card-body row" }
const _hoisted_3 = { class: "col-md-3 separator-right" }
const _hoisted_4 = { class: "nav nav-pills flex-column" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "badge badge-primary badge-pill" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "badge badge-primary badge-pill" }
const _hoisted_9 = { class: "col-md-9" }
const _hoisted_10 = { class: "position-relative h-100" }
const _hoisted_11 = { class: "list-group scroll-view" }
const _hoisted_12 = { class: "d-flex w-100 justify-content-between" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "mark-read"
}
const _hoisted_15 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_time_ago = _resolveComponent("time-ago")
  const _component_icon = _resolveComponent("icon")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      ($setup.shown)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "backdrop d-flex flex-column align-items-center justify-content-center",
            onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($setup.shown = false), ["self"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("ul", _hoisted_4, [
                    _createElementVNode("li", _hoisted_5, [
                      _createElementVNode("a", {
                        class: _normalizeClass(["nav-link d-flex justify-content-between align-items-center", {active: $setup.showUnread}]),
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.showUnread = true), ["prevent"]))
                      }, [
                        _createTextVNode("Unread"),
                        _createElementVNode("span", _hoisted_6, _toDisplayString($setup.unreadCount), 1)
                      ], 2)
                    ]),
                    _createElementVNode("li", _hoisted_7, [
                      _createElementVNode("a", {
                        class: _normalizeClass(["nav-link d-flex justify-content-between align-items-center", {active: !$setup.showUnread}]),
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.showUnread = false), ["prevent"]))
                      }, [
                        _createTextVNode("All"),
                        _createElementVNode("span", _hoisted_8, _toDisplayString($setup.data.notifications?.length), 1)
                      ], 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.notifications, (notif) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: notif.id
                        }, [
                          (!$setup.showUnread || !notif.read)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["notif list-group-item list-group-item-action flex-column align-items-start position-relative", {disabled: notif.read, read: notif.read}])
                              }, [
                                _createVNode(_component_router_link, {
                                  class: "stretched-link",
                                  to: '/view/' + notif.causedByObject,
                                  onClickCapture: $event => ($setup.markRead(notif, true))
                                }, null, 8, ["to", "onClickCapture"]),
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("h5", {
                                    class: "mb-0",
                                    innerHTML: $setup.getText(notif)
                                  }, null, 8, _hoisted_13),
                                  _createVNode(_component_time_ago, {
                                    time: notif.createdAt
                                  }, null, 8, ["time"])
                                ]),
                                (!notif.read)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                      _withDirectives((_openBlock(), _createElementBlock("button", {
                                        class: "btn btn-primary",
                                        onClick: $event => ($setup.markRead(notif))
                                      }, [
                                        _createVNode(_component_icon, { icon: "check" })
                                      ], 8, _hoisted_15)), [
                                        [_directive_tooltip, 'Mark as read']
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}