<template lang="pug">
.container(v-if="post")
    MetaTag(name="og:title" :content="post.title")
    MetaTag(name="og:url" :content="endpoints.main(`/view/${post.id}`)")
    MetaTag(name="og:image" :content="post.thumbnail")
    MetaTag(:names="['og:description', 'description']" :content="stub(post.renderedbody)")
    LinkTag(rel="canonical" :href="endpoints.main(`/view/${post.id}/${post.uglytitle}`)")
    FeedTag(:object-id="post.id")
    TitleTag(:title="post.title")

    ol.breadcrumb
        li.breadcrumb-item
            router-link(:to="{name:'forums'}") Forums
        li.breadcrumb-item
            router-link(:to="{name:'forum', params:{id:post.forumid}}") {{post.forum?.title}}
        li.breadcrumb-item.active
            span(aria-current="location") {{post.title}}

    .row
        div(:class="{'col-lg-9': showSidebar, 'col-lg-12': !showSidebar}")
            .card
                .card-body
                    PostView(:post="post" :editing="editing" @finished-edit="editing = false")

        .col-lg-3(v-if="showSidebar")
            .card.mb-4(v-if="showManage")
                .card-body
                    h5.text-center.mb-0 Manage post
                    hr

                    .d-flex.flex-column.align-items-start
                        button.btn.btn-info(v-if="canEdit" @click="editing = true")
                            icon.me-2(icon="edit" fixed-width)
                            | Edit post
                        button.btn.btn-danger.mt-2(v-if="canDelete" @click="deletePost")
                            icon.me-2(icon="trash" fixed-width)
                            | Delete post
                        MovePostButton.mt-2(v-if="canMovePost" :post="post" @moved="movedTo")
                        router-link.btn.btn-success.mt-2(v-if="canSendNewsletter" :to="newsletterRoute")
                            icon.me-2(icon="envelope" fixed-width)
                            | Send newsletter

            .card(v-if="showRelated")
                .card-body.related
                    a.float-end.text-decoration-none.hide-link(href="#" aria-label="Close" @click.prevent="showRelated = false")
                        span(aria-hidden="true") &times;
                    h5 Recent posts:

                    ul.list-group.list-group-flush
                        li.list-group-item(v-for="item in post.related")
                            router-link.stretched-link(:to="{name:'post', params: {id:item.id}}") {{item.title}}

    .card.mt-5
        .card-body(style="min-height: 150px")
            suspense(timeout="0")
                template(#fallback)
                    spinner
                template(#default)
                    CommentList(:objectid="post.id")
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useMutation, useQuery } from 'villus';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';

import type { DeletePostMutation, DeletePostMutationVariables, Forum, GetPostQuery, GetPostQueryVariables } from "@/graphql";
import { DeletePostDocument, GetPostDocument } from "@/graphql";
import { useAuth, useEvents, useSettings } from '@/mixins';
import { watchError, canAllOrOwn, stub } from "@/utils"
import endpoints from "@/endpoints";

import CommentList from "@/components/CommentList.vue";
import PostView from "@/components/forums/PostView.vue";
import MovePostButton from "@/components/forums/MovePostButton.vue";
import { FeedTag, MetaTag, TitleTag, LinkTag } from "@/utils/head"

const route = useRoute();
const router = useRouter();
const { loaded } = useEvents();
const { hasFlag, currentUser } = useAuth();

const showRelated = ref(true);
const canMovePost = hasFlag("can-move-post");
const canEdit = canAllOrOwn("can-edit-post", () => post.value?.authorid);
const canDelete = canAllOrOwn("can-delete-post", () => post.value?.authorid);
const canSendNewsletter = hasFlag("can-send-newsletter");

const showManage = computed(() => canMovePost || canEdit.value || canDelete.value);
const showSidebar = computed(() => showRelated.value || showManage.value);

const newsletterRoute = computed<RouteLocationRaw>(() => ({
    name: "manpanel",
    params: {
        panel: "newsletter"
    },
    query: {
        title: post.value?.title,
        url: 'https://logic.world/' + post.value?.id,
        discord: "1"
    }
}))

const editing = ref(false);

const deleteMutation = useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument);

const settings = useSettings();
watch(settings.showRecent, o => !o && (showRelated.value = false), { immediate: true });

const pauseQuery = ref(false);

const { data, error: postError } = await useQuery<GetPostQuery, GetPostQueryVariables>({
    query: GetPostDocument,
    variables: computed(() => ({ id: String(route.params["id"]) })),
    paused: pauseQuery
});
onBeforeRouteLeave(() => pauseQuery.value = true);

const post = computed(() => data.value?.post);

watchError(postError);
loaded();

if (post.value && !isSSR) {
    if (route.params["name"] != post.value.uglytitle) {
        router.replace({
            ...route,
            params: {
                ...route.params,
                name: post.value.uglytitle
            }
        });
    }
}

function movedTo(forum: Forum) {
    assert(post.value, "post");
    post.value.forumid = forum.id;
    post.value.forum = forum;
}

async function deletePost() {
    if (!(currentUser && post.value)) {
        return;
    }

    if (!confirm("Are you sure you want to delete this post?")) {
        return;
    }

    await deleteMutation.execute({ id: post.value.id });

    router.push(`/view/${post.value.forumid}`);
}
</script>

<style lang="sass" scoped>
.related
    ul a
        text-decoration: none

        &:not(:hover)
            color: var(--bs-gray)
    
    &:not(:hover) > .hide-link
        visibility: hidden
</style>