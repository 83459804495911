import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b37003b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "display-4" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "d-sm-flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["TitleTag"], { title: "Create ticket" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, "Submit a " + _toDisplayString($setup.ticketKind == $setup.TicketKind.Issue ? "bug report" : $setup.ticketKind == $setup.TicketKind.Feature ? "feature request" : "??"), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["VForm"], {
          class: "card-body",
          form: $setup.form
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["VField"], {
                class: "me-1",
                name: "title",
                "show-error": false
              }),
              _createVNode($setup["VSubmit"])
            ]),
            _createVNode($setup["VError"], {
              is: "div",
              for: "title"
            }),
            _createVNode($setup["VField"], {
              class: "mt-3 body",
              name: "body",
              is: "textarea",
              placeholder: "Body (markdown is supported!)",
              initialHeight: 200
            }),
            _createVNode($setup["VError"], {
              class: "ms-2",
              is: "span"
            }),
            _createVNode($setup["VImage"], { class: "mt-2" })
          ]),
          _: 1
        }, 8, ["form"])
      ])
    ])
  ], 64))
}