import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1acab06a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("img", {
    src: $props.user.picture ? $setup.endpoints.asset('/upload/' + $props.user.picture) : $setup.endpoints.asset('/img/default-user.png'),
    class: _normalizeClass({round: $props.round, shadow: $props.shadow})
  }, null, 10, _hoisted_1))
}