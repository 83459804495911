import { logDebug, logTrace } from "@/utils";
import { Cookies } from "@/utils/cookies";
import { App, computed } from "vue";
import { customRef, inject, InjectionKey, Ref } from "vue"

export declare type TimeFormat = "relative" | "datetime";

export enum Theme {
    Dark = "dark",
    Light = "light",
    Auto = "auto"
}

export type Settings = ReturnType<typeof newSettings>;
export const SETTINGS_KEY: InjectionKey<Settings> = Symbol("settings");

const cookieName = (settingKey: string) => `set-${settingKey}`;

const encodeCookie = (value: any) => encodeURIComponent(JSON.stringify(value));
const decodeCookie = (value: string) => JSON.parse(decodeURIComponent(value));

const verifyBool = (val: boolean) => val === true || val === false;

/**
 * Import old settings from local storage into cookies.
 */
function importOldSetting(key: string, cookies: Cookies) {
    if (isSSR)
        return;

    const raw = localStorage.getItem(key);
    if (raw === null) {
        return;
    }

    console.log(`importing old setting ${key}`);

    localStorage.removeItem(key);
    cookies.set(cookieName(key), encodeCookie(JSON.parse(raw)));
}

function newSettings(cookies: Cookies) {
    let channel: BroadcastChannel | undefined;

    if (!isSSR) {
        channel = new BroadcastChannel("theme");
    }

    function setting<T>(key: string, defaultValue: T, verify?: (val: T) => boolean): Ref<T> {
        importOldSetting(key, cookies);

        const ref = customRef<T>((track, trigger) => {
            let changedByMessage = false;
    
            if (channel) {
                channel.addEventListener("message", o => {
                    if (o.data.key === key && o.source !== window) {
                        changedByMessage = true;
                        try {
                            ref.value = o.data.value;
                        } finally {
                            changedByMessage = false;
                        }
                    }
                });
            }
    
            return {
                get() {
                    track();
    
                    logTrace("get setting", key);
    
                    let raw = cookies.get(cookieName(key));
    
                    if (raw === undefined) {
                        raw = cookies.get(key); // Try name without prefix to import old cookie
    
                        if (raw === undefined) {
                            logTrace("cookie not found", key);
                            return defaultValue;
                        }
    
                        console.log(`importing old cookie setting ${key}`);
    
                        cookies.set(cookieName(key), encodeCookie(raw));
    
                        // Delete old cookie
                        cookies.set(key, "", { expires: 0, sameSite: "lax" });
                    }
    
                    const value = decodeCookie(raw);
    
                    if (verify && !verify(value)) {
                        logTrace("cannot verify cookie value", key, value);
                        return defaultValue;
                    }
    
                    logTrace("verified cookie value", key, value);
                    return value;
                },
                set(newValue) {
                    logDebug("set setting", key, newValue);
    
                    cookies.set(cookieName(key), encodeCookie(newValue));
                    trigger();
    
                    if (!changedByMessage)
                        channel?.postMessage({ key, value: newValue });
                }
            }
        })
    
        return ref;
    }

    const noSSR = setting<string>("nossr", "no", o => o == "yes" || o == "no");

    return {
        theme: setting("theme", Theme.Auto, o => Object.values(Theme).includes(o)),
        enableSSR: computed({
            get: () => noSSR.value === "no",
            set: o => noSSR.value = o ? "no" : "yes"
        }),
        autoScroll: setting<boolean>("autoScroll", true, verifyBool),
        showRecent: setting<boolean>("showRecent", true, verifyBool),
        timeFormat: setting<TimeFormat>("timeFormat", "relative", o => o === "datetime" || o === "relative"),
        storeCommentDraft: setting<boolean>("storeCmtDraft", true, verifyBool),
        infiniteScroll: setting<boolean>("infiniteScroll", true, verifyBool),
        analytics: setting<boolean>("analytics", true, verifyBool),
        autoResizeBoxes: setting<boolean>("autoResizeBoxes", true, verifyBool),
        fixedNavbar: setting<boolean>("fixedNavbar", true, verifyBool),
        showOpenInGameDialog: setting<boolean>("showOpenInGameDialog", true, verifyBool),
    };
}

export function provideSettings(app: App, cookies: Cookies) {
    const setts = newSettings(cookies);
    app.provide(SETTINGS_KEY, setts);

    return setts;
}

export function useSettings() {
    const settings = inject(SETTINGS_KEY);
    if (!settings) {
        throw new Error("Settings not found");
    }
    return settings;
}
