import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.flairData)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["badge", ['bg-' + _ctx.flairData.color]]),
        title: _ctx.flairData.title
      }, _toDisplayString(_ctx.flairData.name), 11, _hoisted_1))
    : _createCommentVNode("", true)
}