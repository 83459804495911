<template lang="pug">
.card.mb-2.px-3.py-2.flex-row
    .d-flex.flex-column.me-2
        span.title.text-muted.me-1 \#{{ticket.number}}

        Status.mt-auto.mb-1.me-2(:ticket="ticket")

    .d-flex.flex-column.flex-fill
        router-link.title.stretched-link(:to="'/tracker/' + ticket.number") {{ticket.title}}
        div
            Tag.me-1(v-for="tag in ticket.tags" :tag="tag")
        .mt-auto
            span.text-muted by 
                user-link.over(:user="ticket.author")

    .d-flex.flex-column.align-items-end
        Kind.mt-1(:kind="ticket.kind")
        time-ago.mt-auto.text-end.pointer(:time="ticket.createdAt")
</template>

<script lang="ts">
import { Ticket } from "@/graphql"
import { defineComponent, PropType } from "vue"

import Tag from "./TicketTag.vue"
import Status from "./TicketStatus.vue"
import Kind from "@/components/tracker/TicketKind.vue"

export default defineComponent({
    components: { Tag, Status, Kind },
    props: {
        ticket: {
            type: Object as PropType<Ticket>,
            required: true
        }
    }
})
</script>

<style lang="scss" scoped>
.title {
    font-size: 1.5rem;
}
</style>
