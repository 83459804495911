<template lang="pug">
select.form-control(v-model="level" v-bind="$attrs" :disabled="isFetching || saving")
    option(value="-1" disabled hidden) Loading...
    option(value="0") Do not receive any emails
    option(value="1") Receive only major update emails
    option(value="2") Receive major and minor update emails

span.text-danger(v-if="error") There was an error saving your subscription
span.text-muted(v-else-if="saved && !saving") Changes successfully saved
</template>

<script lang="ts">
import { useMutation, useQuery } from 'villus';
import { defineComponent, ref, watch } from 'vue'

import { GetNewsletterLevelDocument, GetNewsletterLevelQuery, UpdateNewsletterDocument, UpdateNewsletterMutation, UpdateNewsletterMutationVariables } from "@/graphql"

export default defineComponent({
    name: "NewsletterAuthed",

    setup() {
        const level = ref(-1);

        const { execute, error, isDone: saved, isFetching: saving } = useMutation<UpdateNewsletterMutation, UpdateNewsletterMutationVariables>(UpdateNewsletterDocument);
        const { then, isFetching } = useQuery<GetNewsletterLevelQuery>({
            query: GetNewsletterLevelDocument,
            cachePolicy: "network-only"
        });
        then(o => {
            level.value = o.data.value?.level ?? 0;

            watch(level, newlevel => {
                execute({ level: newlevel });
            })
        });

        return { level, error, isFetching, saved, saving }
    }
})
</script>