<template lang="pug">
div
    .d-flex.align-items-center
        label Query: 
        input.form-control.flex-grow-1.ms-2(type="text" v-model="opts.query" style="width:unset" @keypress.enter="goSearch")

        label.ms-2 Sort by:
        select.ms-2(v-model="sortField")
            option(value="created") Creation date
            option(value="comments") Comment count
            option(value="score") Score
        input#descending.ms-2.me-1(type="checkbox" v-model="sortDescending")
        label(for="descending") Descending

    .d-flex.align-items-center.mt-2
        label Include:
        template(v-for="val, name in SearchItemType")
            input.ms-2(type="checkbox" :value="val" :id="val" v-model="opts.type")
            label.ms-1(:for="val") {{name}}s

        router-link.btn.btn-primary.ms-auto(:to="searchUrl") Search
</template>

<script lang="ts" setup>
import { computed, reactive } from 'vue';
import type { PropType } from 'vue';
import { useRouter } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';

import { SearchItemType } from '@/graphql';

const props = defineProps({
    opts: {
        type: Object as PropType<{ query: string, type: SearchItemType[], sort?: string[] }>,
        required: true
    },
    sort: {
        type: String,
        required: true
    }
})

const emit = defineEmits(["update:sort"]);

const router = useRouter();
const opts = reactive({...props.opts});

const sortDescending = computed<boolean>({
    get() {
        return props.sort.startsWith("-");
    },
    set(value) {
        const isDescending = props.sort.startsWith("-");

        if (isDescending != value) {
            emit("update:sort", isDescending ? props.sort.substr(1) : `-${props.sort}`);
        }
    }
});
const sortField = computed<string>({
    get() {
        return sortDescending.value ? props.sort.substr(1) : props.sort;
    },
    set(value) {
        emit("update:sort", sortDescending.value ? `-${value}` : value);
    }
})

const searchUrl = computed<RouteLocationRaw>(() => ({
    name: "search",
    query: {
        q: opts.query,
        t: opts.type.join(","),
        s: props.sort
    }
}))

function goSearch() {
    router.push(searchUrl.value);
}
</script>
