import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_3 = { class: "ms-auto" }
const _hoisted_4 = {
  key: 0,
  class: "text-muted me-3"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["FitTextArea"], {
      class: "form-control w-100 mb-2 flex-grow-1",
      modelValue: $setup.body,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.body) = $event)),
      onSubmit: $setup.submit,
      disabled: !$setup.canSubmit || $setup.isFetching,
      placeholder: $setup.canSubmit ? 'Write your own comment' : $setup.authed ? 'You cannot post comments' : 'Log in to comment'
    }, null, 8, ["modelValue", "disabled", "placeholder"]),
    ($setup.canSubmit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            ($setup.isFetching)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Submitting..."))
              : ($setup.error)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "text-danger me-3",
                    onClick: _cache[1] || (_cache[1] = $event => ($setup.error = undefined))
                  }, "There was an error submitting your comment."))
                : _createCommentVNode("", true),
            ($props.canCancel)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "btn btn-danger me-2",
                  onClick: $setup.cancel
                }, "Cancel"))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: $setup.submit
            }, "Submit")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}