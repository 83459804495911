<template lang="pug">
.modal.fade(tabindex="-1" ref="modalEl")
    .modal-dialog
        .modal-content
            .modal-header
                h5.modal-title Opening Logic World
                button.btn-close(@click="modal?.hide()")
            .modal-body
                p
                    | If you don't own Logic World yet, you can get it 
                    router-link(to="/wishlist" @click="hide") here
                    | .
                p
                    | If Logic World isn't opening, click 
                    router-link(to="/view/pst-56609d78" @click="hide") here
                    | .
                .d-flex.justify-content-end.mt-3
                    button.btn.btn-secondary.me-3(@click="onDontShowAgain") Don't show again
                    button.btn.btn-primary(@click="hide") Got it
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { Modal } from 'bootstrap';

import { useEvents, useSettings } from '@/mixins';

const settings = useSettings();
const events = useEvents()

const modalEl = ref<HTMLElement>();
const modal = ref<Modal>();

onMounted(() => modal.value = new Modal(modalEl.value!));
onUnmounted(() => modal.value?.dispose());

events.showOpenInGameModal.on(() => {
    if (settings.showOpenInGameDialog.value) {
        modal.value?.show();
    }
});

const hide = () => modal.value?.hide();

function onDontShowAgain() {
    settings.showOpenInGameDialog.value = false;
    modal.value?.hide();
}
</script>