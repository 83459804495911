<template lang="pug">
label.scontainer.mb-0
    input.input(type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)")
    span.switch
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "Switch",
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: Boolean,
            required: true
        }
    }
})
</script>

<style lang="scss" scoped>
// https://thomaslombart.com/how-to-build-reusable-and-accessible-switch-vue/

$switch-container-width: 40px;
$switch-size: $switch-container-width * 0.5;

.scontainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: $switch-container-width;
}

/* Visually hide the checkbox input */
.input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.switch {

    /* Vertically center the inner circle */
    display: flex;
    align-items: center;
    position: relative;
    height: $switch-size;
    flex-basis: $switch-container-width;

    /* Make the container element rounded */
    border-radius: $switch-size;
    background-color: #e2e8f0;

    transition: background-color 0.15s ease-in-out;
}

.switch::before {
    content: "";
    position: absolute;
    
    /* Move a little bit the inner circle to the right */
    left: 1px;
    height: $switch-size - 4px;
    width: $switch-size - 4px;

    /* Make the inner circle fully rounded */
    border-radius: 9999px;
    background-color: white;

    transition: transform 0.1s ease-in-out;
}

.input:checked + .switch {
    /* Teal background */
    background-color: #4fd1c5;
}

.input:checked + .switch::before {
    border-color: #4fd1c5;
    /* Move the inner circle to the right */
    transform: translateX($switch-container-width - $switch-size);
}
</style>