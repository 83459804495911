<template lang="pug">
TitleTag(title="Create ticket")

.container
    h1.display-4 Submit a {{ticketKind == TicketKind.Issue ? "bug report" : ticketKind == TicketKind.Feature ? "feature request" : "??"}}

    .card
        v-form.card-body(:form="form")
            .d-sm-flex
                v-field.me-1(name="title" :show-error="false")
                v-submit
            v-error(is="div" for="title")
            v-field.mt-3.body(name="body" is="textarea" placeholder="Body (markdown is supported!)" :initialHeight="200")

            v-error.ms-2(is="span")

            v-image.mt-2
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useMutation } from "villus";
import * as yup from "yup"

import { CreateTicketDocument, TicketKind } from "@/graphql";
import { VForm, VField, VSubmit, VError, VImage, Form, TitleTag } from "@/utils";

const props = defineProps({
    kind: {
        type: String,
        required: true
    },
    prod: {
        type: String,
        required: true
    }
});

const ticketKind = props.kind == "issue" ? TicketKind.Issue : TicketKind.Feature;

const form = new Form(yup.object({
    title: yup.string().label("Title").required("A ticket title is required").min(10).default(""),
    body: yup.string().label("Body").required("A ticket body is required").default("")
}), submit);

const { execute } = useMutation(CreateTicketDocument);
const router = useRouter();

async function submit() {
    const { data, error } = await execute({
        kind: ticketKind,
        product: props.prod,
        ...form.copyData()
    });

    if (error) {
        throw error;
    }
    router.push({ name: "ticket", params: { id: data.createTicket.number } });
}
</script>

<style lang="sass" scoped>
button.submit
    width: 100px

.body
    min-height: 200px

.kind
    width: 20rem
</style>