import { EphemeralMessageStatus, GetCurrentUserDocument, GetCurrentUserQuery, User } from "@/graphql";
import { Client } from "villus";
import { App, inject, InjectionKey } from "vue";
import * as Sentry from "@sentry/vue";

type AuthData = { currentUser: User | null, flags: Record<string, boolean> };
const authKey: InjectionKey<AuthData> = Symbol("auth");

import { useEvents } from ".";
import { FlagNames } from "./flags";
export * from "./flags"

export function useAuth() {
    const data = inject(authKey);
    if (!data) {
        throw new Error("Missing auth data");
    }

    return {
        ...data,
        get authed() {
            return !!data?.currentUser
        },
        hasFlag(flag: FlagNames) {
            return process.env.NODE_ENV === "test" ? true : (data.flags[flag] || false);
        }
    }
}
useAuth.provide = async (app: App, client: Client) => {
    const { data, error } = await client.executeQuery<GetCurrentUserQuery>({ query: GetCurrentUserDocument });
    if (error) {
        useAuth.provideDummy(app);

        // If the message is "access denied", we can ignore it and just provide dummy
        if (error.graphqlErrors?.every(o => o.message == "access denied")) {
            return;
        }

        throw error;
    }
    assert(data, "auth data");

    const authData: AuthData = {
        flags: data.enabledFlags,
        currentUser: null
    }

    if (!isSSR) {
        setTimeout(() => data.ephemeralMessages.forEach(msg => useEvents().newToast({
            title: "System message",
            body: msg.text,
            time: new Date(msg.createdAt * 1000),
            duration: 0,
            status: msg.status == EphemeralMessageStatus.Error ? "error" :
                msg.status == EphemeralMessageStatus.Success ? "success" :
                    msg.status == EphemeralMessageStatus.Warning ? "warning" : undefined
        })), 500)
    }

    if (data.currentUser) {
        Sentry.setUser({ id: data.currentUser.id.toString(), username: data.currentUser.username });
        authData.currentUser = data.currentUser as any;
    }

    if (process.env.NODE_ENV === "development") {
        console.log("flags", authData.flags);
    }

    app.provide(authKey, authData);
}
useAuth.provideDummy = (app: App) => {
    app.provide(authKey, { currentUser: null, flags: {} });
}