import createApp from "./app"
import { DomHead } from "./utils/head";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import '@justinribeiro/lite-youtube';
import { useAuth } from "./mixins";
import { Cookies } from "./utils/cookies";
import * as analytics from "./utils/analytics";
import { CacheData } from "./villus-plugins/cache";

function getWindowCache(): CacheData {
    const cache = window.cache;
    delete window.cache;

    if (typeof cache == "string") {
        try {
            return JSON.parse(cache);
        } catch (e) {
            console.error("failed to parse cache", e);

            return {};
        }
    }
    if (typeof cache == "object") {
        return {...cache};
    }

    return {};
}

const { app, router, settings, client } = createApp(new DomHead(), Cookies.fromDocument(), getWindowCache());

if (settings.analytics.value && process.env.NODE_ENV !== "development") {
    analytics.setEnabled(true);

    Sentry.init({
        app,
        dsn: "https://b0d71e32c4a3439798c04d7fd0757842@o930854.ingest.sentry.io/5879521",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "logicworld.net", /^\//],
            }),
        ],
        logErrors: true,
        release: process.env.RELEASE,
        // Sample all traces for now, we should reduce this in the future
        tracesSampleRate: 1.0
    });
}

analytics.watchRouter(router);

useAuth.provide(app, client).finally(() => router.isReady().then(() => app.mount("#app")));
