<template lang="pug">
.nav-container(:class="{fixed: fixedNavbar}")
    nav.navbar.navbar-dark.bg-dark.navbar-expand-lg
        router-link.navbar-brand(to="/" aria-label="Homepage")
            img(:src="endpoints.asset('img/logo_white.svg')" width="105" height="40" alt="Logic World logo")
        button.navbar-toggler(type="button" @click="collapse?.toggle()" aria-controls="nav-collapse" aria-expanded="false" aria-label="Toggle navigation")
            span.navbar-toggler-icon

        #nav-collapse.collapse.navbar-collapse(ref="navbarEl")
            ul.navbar-nav
                li.nav-item
                    router-link.nav-link(to="/forums") Forums
                li.nav-item
                    router-link.nav-link(to="/hub") Creation hub
                li.nav-item
                    router-link.nav-link(to="/newsletter") Newsletter
                li.nav-item
                    router-link.nav-link(to="/wishlist") Buy now
                li.nav-item
                    router-link.nav-link(to="/tracker") Issue tracker
                li.nav-item(v-if="hasFlag('can-access-adminpanel')")
                    router-link.nav-link(to="/admin") Admin panel
                li.nav-item(v-if="canAccessManagement")
                    router-link.nav-link(:to="{name:'manpanelIndex'}") Management

                li.nav-item.ms-1
                    a.nav-link.social(href="https://www.youtube.com/MouseHatGames?sub_confirmation=1" target="_blank" title="YouTube")
                        icon(:icon="['fab', 'youtube']" fixed-width)
                        span.ms-2.d-lg-none.d-inline YouTube
                li.nav-item.ms-1
                    a.nav-link.social(href="https://twitter.com/LogicWorldGame" target="_blank" title="Twitter")
                        icon(:icon="['fab', 'twitter']" fixed-width)
                        span.ms-2.d-lg-none.d-inline Twitter
                li.nav-item.ms-1
                    a.nav-link.social(href="https://discord.gg/C5Qkk53" target="_blank" title="Discord")
                        icon(:icon="['fab', 'discord']" fixed-width)
                        span.ms-2.d-lg-none.d-inline Discord

                li.d-flex.ms-3
                    .input-group
                        input.form-control(type="search" v-model="searchQuery" @keypress.enter="goSearch" placeholder="Search" aria-label="Search")
                        button.btn.btn-primary(type="submit" v-if="!isSearchEmpty" @click="goSearch") Search

            ul.navbar-nav.ms-auto
                template(v-if="currentUser")
                    li.nav-item.me-2
                        a.nav-link(href="#" @click.prevent="() => toggleNotifsModal()")
                            icon.bell(icon="bell")

                    li.nav-item.dropdown
                        a.nav-link.dropdown-toggle.p-0#userDropdown(href="#" role="button" v-dropdown)
                            UserPicture(:user="currentUser" v-tooltip="'@' + currentUser.username")

                        .dropdown-menu.dropdown-menu-end(aria-labelledby="userDropdown")
                            a.dropdown-item(:href="endpoints.auth('settings')") Account settings
                            router-link.dropdown-item(:to="{name:'siteSettings'}") Site settings
                            router-link.dropdown-item(:to="{name:'userPage', params: { name: currentUser.username }}") View profile
                            .dropdown-divider
                            a.dropdown-item(:href="endpoints.auth('logout', currentURL)") Log out

                template(v-else)
                    li.nav-item
                        a.nav-link(:href="endpoints.auth('register', currentURL)")
                            icon(icon="user")
                            | &nbsp;Sign up
                    li.nav-item
                        a.nav-link(:href="endpoints.auth('login', currentURL)")
                            icon(icon="sign-in-alt")
                            | &nbsp;Log in
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import type { Collapse } from "bootstrap";

import endpoints from "@/endpoints"
import { useAuth, useEvents, useSettings, } from '@/mixins';

import UserPicture from "@/components/widgets/UserPicture.vue";
import { panelKeys } from "@/utils/management";

const { toggleNotifsModal, routeChanged } = useEvents();
const { hasFlag, currentUser } = useAuth();
const { fixedNavbar } = useSettings();
const router = useRouter();

const currentURL = computed(() => (isSSR ? endpoints.main() : location.origin) + router.currentRoute.value.fullPath);

const canAccessManagement = computed(() => panelKeys.some(o => hasFlag(`can-access-manpanel-${o}`)));

const navbarEl = ref<HTMLElement>();
const collapse = ref<Collapse>();

onMounted(async () => {
    const {Collapse} = await import("bootstrap");

    if (navbarEl.value) {
        collapse.value = new Collapse(navbarEl.value, { toggle: false });
    }
})

routeChanged.on(() => collapse.value?.hide());

const searchQuery = ref("");
const isSearchEmpty = computed(() => /^\s*$/.test(searchQuery.value));

watch(router.currentRoute, o => {
    if (o.name === "search" && typeof o.query.q === "string") {
        searchQuery.value = o.query.q;
    }
})

function goSearch() {
    if (!isSearchEmpty.value) {
        router.push({
            name: "search",
            query: {
                q: searchQuery.value
            }
        });
    }
}
</script>

<style lang="sass" scoped>
.bell
    font-size: 1.5rem
    line-height: 1rem

.social
    border-radius: 50%
    // background: white

.navbar
    z-index: 10
    padding: 0.5rem 1rem
    width: 100%

    .fixed &
        position: fixed
    :not(.fixed) &
        position: absolute

.nav-container.fixed
    margin-bottom: 66px
</style>