import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { ref: "endMarker" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        item
          ? _renderSlot(_ctx.$slots, "default", {
              key: 0,
              item: item
            })
          : _createCommentVNode("", true)
      ], 64))
    }), 256)),
    _createElementVNode("div", {
      class: "mt-3 w-100 d-flex flex-row",
      ref: el => $setup.infiniteScroll && el && (el.style.cssText = 'display: none !important')
    }, [
      (!$setup.isFirstPage)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.useRoute ? 'router-link' : 'button'), {
            key: 0,
            class: "btn btn-outline-primary",
            to: $setup.routeTo(0),
            onClick: _cache[0] || (_cache[0] = $event => ($setup.currentPage = 0))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Back to newest")
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (!$setup.reachedEnd)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.useRoute ? 'router-link' : 'button'), {
            key: 1,
            class: "btn btn-primary ms-auto",
            to: $setup.routeTo($setup.currentPage + 1),
            onClick: _cache[1] || (_cache[1] = $event => ($setup.currentPage++))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Older")
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ], 512),
    _createElementVNode("div", _hoisted_1, null, 512)
  ]))
}