<template lang="pug">
TitleTag(title="Buy the game")

.container-fluid.d-flex.flex-column.align-items-center.pt-5
    iframe(src="https://store.steampowered.com/widget/1054340/" width="646" height="190" frameborder="0")
    iframe.mt-3(src="https://itch.io/embed/393774" width="552" height="167" frameborder="0")
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { TitleTag } from '@/utils';

export default defineComponent({
    name: "Wishlist",
    components: { TitleTag }
})
</script>

<style lang="sass" scoped>
iframe
    max-width: 100% !important
</style>
