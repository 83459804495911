import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-057a4496"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }

export function render(_ctx, _cache) {
  const _component_spinner = _resolveComponent("spinner")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_spinner, { opacity: .8 })
  ]))
}