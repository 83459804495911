import { GetCommentObjectDocument } from '@/graphql';
import { useMeta } from '@/utils';
import { useQuery } from 'villus';
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    render() {},

    setup() {
        const route = useRoute();
        const router = useRouter();

        useMeta({ robots: "noindex" });

        const id = route.params["id"];
        
        const { data, error, then } = useQuery({
            query: GetCommentObjectDocument,
            variables: { id: String(id) }
        })
        then(() => {
            if (error.value) {
                router.replace({name: "notFound"});
            } else {
                var to = router.resolve(`/view/${data.value?.comment.objectid}`);
                to.hash = "#" + id;
                router.replace(to);
            }
        })
    }
})