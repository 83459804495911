<template lang="pug">
mixin content
    icon(v-if="userVoted" :icon="['fas', 'heart']" :class="{'text-danger': compact}")
    icon.text-muted(v-else :icon="['far', 'heart']")
    | &nbsp;{{rating?.score}}

div.like-button(v-if="!compact")
    button.btn(@click="rateClicked" :class="{'btn-outline-primary': !userVoted, 'btn-success': userVoted}"
                :disabled="!authed" v-tooltip="authed ? null : 'Log in to rate'")
        +content

    span.ms-2.text-danger(v-if="error") There was an error submitting your rating

.like-button.compact(v-else @click="rateClicked" :disabled="!authed" v-tooltip:left.client="authed ? 'Rate comment' : 'Log in to rate'")
    +content

</template>

<script lang="ts" setup>
import { RateObjectDocument } from '@/graphql';
import type { RateObjectMutation, RateObjectMutationVariables, Rating } from '@/graphql';
import { useMutation } from 'villus';
import { computed } from 'vue'
import { useAuth } from "@/mixins"
import { typedProp } from '@/utils';

const props = defineProps({
    objectid: String,
    rating: typedProp<Pick<Rating, "score" | "likedBy">>(o => o.score),
    compact: Boolean
});

const { execute, error } = useMutation<RateObjectMutation, RateObjectMutationVariables>(RateObjectDocument)
const { authed, currentUser } = useAuth();

const userVoted = computed(() => currentUser ? props.rating?.likedBy.includes(currentUser.id) : false);

function addOrRemoveVote(add: boolean) {
    assert(currentUser, "user")
    if (add) {
        props.rating!.likedBy.splice(props.rating!.likedBy.indexOf(currentUser.id));
    } else {
        props.rating!.likedBy.push(currentUser.id);
    }
}

async function rateClicked() {
    if (!authed) {
        return;
    }
    
    if (!props.rating || !props.objectid) {
        console.error("tried to vote before the post loads");
        return;
    }

    const clear = userVoted.value ?? false;
    props.rating.score += clear ? -1 : 1;
    addOrRemoveVote(clear);

    var { error } = await execute({
        objid: props.objectid,
        clear
    });

    if (error) {
        props.rating.score -= clear ? -1 : 1;
        addOrRemoveVote(!clear);
    }
}
</script>

<style lang="scss" scoped>
.compact {
    user-select: none;

    &[disabled=false] {
        cursor: pointer;
    }
}
</style>