import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b2b5d18"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-item"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")
  const _component_router_link = _resolveComponent("router-link")

  return ($setup.canAccess)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["nav-link", {active: $setup.currentPanel == $props.panel}]),
          to: {name:'manpanel', params:{panel: $props.panel}}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              icon: $setup.panels[$props.panel][1],
              "fixed-width": "fixed-width"
            }, null, 8, ["icon"]),
            _createTextVNode(_toDisplayString($setup.panels[$props.panel][0]), 1)
          ]),
          _: 1
        }, 8, ["to", "class"])
      ]))
    : _createCommentVNode("", true)
}