import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-504b1998"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "aria-live": "polite",
  "aria-atomic": "true"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "toast-header" }
const _hoisted_4 = { class: "me-auto" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_time_ago = _resolveComponent("time-ago")

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["toast mb-3", {linked: toast.href}]),
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true",
        onClick: $event => (_ctx.clicked(toast)),
        key: toast.time.getTime(),
        ref_for: true,
        ref: el => _ctx.shown(el, toast)
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["toast-icon rounded me-2", {'bg-danger': toast.status == 'error', 'bg-success': toast.status == 'success', 'bg-warning': toast.status == 'warning'}])
          }, null, 2),
          _createElementVNode("strong", _hoisted_4, _toDisplayString(toast.title), 1),
          _createVNode(_component_time_ago, {
            time: toast.time
          }, null, 8, ["time"]),
          _createElementVNode("button", {
            class: "btn-close",
            type: "button",
            "data-bs-dismiss": "toast",
            "aria-label": "Close",
            onClick: _withModifiers($event => (_ctx.remove(toast)), ["stop"])
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", {
          class: "toast-body",
          innerHTML: toast.body
        }, null, 8, _hoisted_6)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}