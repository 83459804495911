<template lang="pug">
TitleTag(title="Newsletter")

.container.text-center
    p.text-center.display-4 Newsletter
    hr

    p Subscribe to our newsletter to receive the latest updates about Logic World!

    .row
        .col-md-3
        .col-md-6
            component(:is="authed ? 'NewsletterAuthed' : 'NewsletterAnon'")

        .col-md-3

    div.d-flex.flex-row.justify-content-center
        p.mt-3(style="max-width:700px")
            | We don't do any of the typical email marketing mischief. We have no tracking pixels, we use minimal HTML, and everybody gets the same links -- no special, individual links that let us know when you click on them. They're just regular emails.
            br
            br
            | We promise to keep your email address private.
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { useAuth } from '@/mixins';

import NewsletterAuthed from "@/components/NewsletterAuthed.vue";
import NewsletterAnon from "@/components/NewsletterAnonymous.vue";
import { TitleTag } from '@/utils';

export default defineComponent({
    name: "Newsletter",
    components: {
    NewsletterAuthed,
    NewsletterAnon,
    TitleTag
},

    setup() {
        return { ...useAuth() };
    }
})
</script>

<style lang="sass" scoped>
label
    user-select: none
</style>