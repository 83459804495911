<template lang="pug">
span.badge.bg-warning.text-dark(v-if="kind == 'ISSUE'" style="width:4rem") Bug
span.badge.bg-info(v-else-if="kind == 'FEATURE'" style="width:7rem") Feature request
</template>

<script lang="ts">
import { TicketKind } from "@/graphql"
import { defineComponent, PropType } from "vue"

export default defineComponent({
    props: {
        kind: {
            type: String as PropType<TicketKind>,
            required: true
        }
    }
})
</script>