import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock, renderList as _renderList, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7f0af0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["collapsed"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "part" }
const _hoisted_4 = {
  key: 0,
  class: "collapse-button d-flex me-2 mt-1"
}
const _hoisted_5 = {
  key: 0,
  class: "dropstart"
}
const _hoisted_6 = ["aria-labelledby"]
const _hoisted_7 = { class: "flex-grow-1 position-relative inner" }
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 0,
  class: "clearfix"
}
const _hoisted_10 = { class: "float-end d-flex align-items-middle m-2" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "my-2 float-right" }
const _hoisted_13 = { class: "text-muted part" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")
  const _component_UserPicture = _resolveComponent("UserPicture")
  const _component_router_link = _resolveComponent("router-link")
  const _component_user_link = _resolveComponent("user-link")
  const _component_time_ago = _resolveComponent("time-ago")
  const _component_Markdown = _resolveComponent("Markdown")
  const _component_LikeButton = _resolveComponent("LikeButton")
  const _component_FitTextArea = _resolveComponent("FitTextArea")
  const _component_CommentBox = _resolveComponent("CommentBox")
  const _component_Comment = _resolveComponent("Comment", true)
  const _component_TransitionExpand = _resolveComponent("TransitionExpand")
  const _directive_dropdown = _resolveDirective("dropdown")

  return (_ctx.comment)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["comment position-relative", ['depth-'+((_ctx.depth ?? 0) % 13), _ctx.isHighlighted ? 'highlighted' : '']]),
        collapsed: _ctx.collapsed
      }, [
        _createElementVNode("div", {
          class: "marker",
          id: _ctx.comment.id
        }, null, 8, _hoisted_2),
        _createVNode(_component_TransitionExpand, { collapsed: _ctx.collapsed }, {
          expanded: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.isEditing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_ctx.canEdit || _ctx.canDelete)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _withDirectives(_createVNode(_component_icon, {
                            class: "text-muted dropdown-toggle",
                            icon: "cog",
                            type: "button",
                            id: 'opt' + _ctx.comment.id
                          }, null, 8, ["id"]), [
                            [_directive_dropdown]
                          ]),
                          _createElementVNode("div", {
                            class: "dropdown-menu",
                            role: "menu",
                            "aria-labelledby": 'opt' + _ctx.comment.id
                          }, [
                            (_ctx.canEdit)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  class: "dropdown-item",
                                  href: "#",
                                  onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.isEditing = true), ["prevent"]))
                                }, [
                                  _createVNode(_component_icon, {
                                    class: "me-2",
                                    icon: "edit",
                                    "fixed-width": "fixed-width"
                                  }),
                                  _createTextVNode("Edit")
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.canDelete)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 1,
                                  class: "dropdown-item",
                                  href: "#",
                                  onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_ctx.deleteComment && _ctx.deleteComment(...args)), ["prevent"]))
                                }, [
                                  _createVNode(_component_icon, {
                                    class: "me-2",
                                    icon: "trash",
                                    "fixed-width": "fixed-width"
                                  }),
                                  _createTextVNode("Delete")
                                ]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_6)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "ms-2",
                      onClick: _cache[2] || (_cache[2] = $event => (_ctx.collapsed = !_ctx.collapsed))
                    }, [
                      _createVNode(_component_icon, {
                        class: "text-muted",
                        icon: "minus"
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "d-flex",
                onClick: _cache[9] || (_cache[9] = (...args) => (_ctx.unHighlight && _ctx.unHighlight(...args)))
              }, [
                _createVNode(_component_router_link, {
                  class: "text-center me-3",
                  to: '/@' + _ctx.comment.author.username
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_UserPicture, {
                      class: "mb-2",
                      user: _ctx.comment.author,
                      round: "round",
                      shadow: "shadow"
                    }, null, 8, ["user"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", null, [
                    _createVNode(_component_user_link, {
                      user: _ctx.comment.author
                    }, null, 8, ["user"]),
                    _createElementVNode("a", {
                      class: "text-muted",
                      href: '#' + _ctx.comment.id
                    }, [
                      _createElementVNode("small", null, [
                        _createVNode(_component_time_ago, {
                          time: _ctx.comment.createdat
                        }, null, 8, ["time"]),
                        (_ctx.comment.editedat)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(" (edited"),
                              _createVNode(_component_time_ago, {
                                time: _ctx.comment.editedat
                              }, null, 8, ["time"]),
                              _createTextVNode(")")
                            ], 64))
                          : _createCommentVNode("", true)
                      ])
                    ], 8, _hoisted_8)
                  ]),
                  (!_ctx.isEditing)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_Markdown, {
                          class: "comment-text",
                          html: _ctx.comment.renderedbody
                        }, null, 8, ["html"]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_LikeButton, {
                            objectid: _ctx.comment.id,
                            rating: _ctx.comment.rating,
                            compact: "compact"
                          }, null, 8, ["objectid", "rating"]),
                          (_ctx.authed)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "ms-2 text-muted text-decoration-none",
                                href: "#",
                                onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.replying = !_ctx.replying), ["prevent"]))
                              }, [
                                _createTextVNode("Reply"),
                                _createVNode(_component_icon, {
                                  class: "ms-2 mt-1",
                                  icon: "reply"
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_FitTextArea, {
                          class: "form-control",
                          modelValue: _ctx.newBody,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.newBody) = $event)),
                          onSubmit: _ctx.submitEdit
                        }, null, 8, ["modelValue", "onSubmit"]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("button", {
                            class: "btn btn-danger me-2",
                            onClick: _cache[5] || (_cache[5] = $event => (_ctx.isEditing = false))
                          }, "Cancel"),
                          _createElementVNode("button", {
                            class: "btn btn-success",
                            onClick: _cache[6] || (_cache[6] = (...args) => (_ctx.submitEdit && _ctx.submitEdit(...args)))
                          }, "Submit")
                        ])
                      ])),
                  (_ctx.replying)
                    ? (_openBlock(), _createBlock(_component_CommentBox, {
                        key: 2,
                        class: "mb-2 reply-box",
                        canCancel: "canCancel",
                        objectid: _ctx.comment.objectid,
                        parentid: _ctx.comment.id,
                        onSent: _cache[7] || (_cache[7] = $event => (_ctx.replying = false)),
                        onCancel: _cache[8] || (_cache[8] = $event => (_ctx.replying = false))
                      }, null, 8, ["objectid", "parentid"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (cmt) => {
                return (_openBlock(), _createBlock(_component_Comment, {
                  key: cmt.id,
                  comment: cmt,
                  depth: (_ctx.depth ?? 0) + 1,
                  allComments: _ctx.allComments
                }, null, 8, ["comment", "depth", "allComments"]))
              }), 128))
            ])
          ]),
          collapsed: _withCtx(() => [
            _createElementVNode("small", _hoisted_13, [
              _createElementVNode("div", {
                class: "cover",
                onClick: _cache[10] || (_cache[10] = $event => (_ctx.collapsed = false))
              }),
              _createElementVNode("span", null, "@" + _toDisplayString(_ctx.comment.author?.username), 1),
              _createVNode(_component_time_ago, {
                time: _ctx.comment.createdat
              }, null, 8, ["time"])
            ])
          ]),
          _: 1
        }, 8, ["collapsed"])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}