<template lang="pug">
TitleTag(title="Website settings")

.container
    .row
        .col-md
        .col-md-8
            h1.display-4.text-center Site settings

            table.table
                colgroup
                    col(span="1" style="width: 50%")
                    col(span="1" style="width: 50%")
            
                tbody
                    tr
                        th Theme
                        td
                            .btn-group.btn-group-sm(role="group")
                                RadioButton(v-model="theme" value="light") Light
                                RadioButton(v-model="theme" value="dark") Dark
                                RadioButton(v-model="theme" value="auto") Automatic 
                                    span.badge.bg-light(v-tooltip="`If automatic theme is enabled the site will match your browser or OS's theme setting, which can be useful for enabling dark mode only at night.`") ?

                    tr
                        th 
                            | Remember scroll position 
                            span.badge.bg-light(v-tooltip="'When reloading or navigating away from a page, your scroll position will be saved and restored when you come back to it. You can disable this behaviour if you find it annoying.'") ?
                        td
                            Switch(v-model="autoScroll")

                    tr
                        th Paged page behaviour 
                        td
                            .btn-group.btn-group-sm(role="group")
                                RadioButton(v-model="infiniteScroll" :value="true") Infinite
                                RadioButton(v-model="infiniteScroll" :value="false") Paginated

                    tr
                        th Show open in-game help dialog
                        td
                            Switch(v-model="showOpenInGameDialog")

                    tr
                        th Show recent posts box
                        td
                            Switch(v-model="showRecent")

                    tr
                        th Time label format
                        td
                            .btn-group.btn-group-sm(role="group")
                                RadioButton(v-model="timeFormat" value="relative") Relative
                                RadioButton(v-model="timeFormat" value="datetime") Date and time

                    tr
                        th Store comment drafts
                        td
                            Switch(v-model="storeCommentDraft")

                    tr
                        th Automatically resize text boxes
                        td
                            Switch(v-model="autoResizeBoxes")

                    tr
                        th Always show navbar on top
                        td
                            Switch(v-model="fixedNavbar")

                    tr
                        th
                            | Enable server side rendering 
                            span.badge.bg-light(v-tooltip="'If enabled, the page will be rendered on the server before being sent to you. This can improve performance, but it may also cause some unintended behavior.'") ?

                        td
                            Switch(v-model="enableSSR")

                    tr
                        th
                            | Enable analytics 
                            span.badge.bg-light(v-tooltip="`We use sentry.io in order to collect information about errors and website performance. This shouldn't affect your experience in any way, but you can still choose to disable it.`") ?
                        td
                            Switch(v-model="analytics" v-tooltip="'Changing this setting will reload the page'")

        .col-md
</template>

<script lang="ts">
import { defineComponent, watch } from "vue"
import { useSettings } from "@/mixins"

import RadioButton from "@/components/widgets/RadioButton.vue";
import Switch from "@/components/widgets/Switch.vue";
import { TitleTag } from "@/utils";

export default defineComponent({
    components: { RadioButton, Switch, TitleTag },
    setup() {
        const settings = useSettings();

        watch(settings.analytics, () => location.reload());

        return { ...settings }
    }
})
</script>

<style lang="scss" scoped>
th {
    max-width: 200px;
    text-align: right;
    vertical-align: middle !important;
}

td {
    vertical-align: middle !important;
}

label {
    user-select: none;
}

.badge {
    user-select: none;
    cursor: pointer;
    padding: .15rem .35rem;
}
</style>