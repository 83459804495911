import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac6701e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body d-flex flex-column" }
const _hoisted_2 = { class: "display-1 mt-auto" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card gauge", [`bg-${$props.bg}`, `text-${$props.color}`]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", null, _toDisplayString($props.title), 1),
      _createElementVNode("h1", _hoisted_2, _toDisplayString($props.value), 1)
    ])
  ], 2))
}