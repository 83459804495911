<template lang="pug">
TitleTag(title="Website changelog")

-
    var changelog = {
        "2023-08-14": [
            "Add RSS and Atom feeds"
        ],
        "2023-05-04": [
            "Fix comments sometimes being visually duplicated after submission (#258)"
        ],
        "2023-05-03": [
            "Use websocket connection for all API requests",
            "Redirect to previous page after auth"
        ],
        "2023-04-25": [
            "Migrated all site settings from local storage into cookies",
            "Improved and re-enabled server side rendering for all pages"
        ],
        "2022-11-22": [
            "Fix website not working on Safari (#171)",
            "Fix 'edit bio' button showing on other users' profiles (#441)"
        ],
        "2022-10-20": [
            "Add ability to update bio in user profile"
        ],
        "2022-08-18": [
            "Fix submitting a ticket not redirecting you to the new ticket (#257)",
            "Add advanced querying in tracker",
            "Make carousel arrows more visible (#403)",
            "Warn users if images in posts aren't added (#184)"
        ],
        "2021-10-20": [
            "Fixed notification links"
        ],
        "2021-09-14": [
            "Always show navbar on top",
            "Implemented another possible fix for navbar collapsing"
        ],
        "2021-09-04": [
            "Fixed /view/lwt-XXXXXXXX links (#27)",
            "Fixed users without profile pictures seeing an empty image in navbar (#17)",
            "Improved scroll restoring behaviour (#20)",
            "Redirect old forum and image links (#10)",
            "Fixed mobile navbar collapse (#21)",
            "Clarified ticket edit cancel wording (#25)"
        ],
        "2021-08-26": [
            "Added trackers for game and website",
            "Automatically resize comment boxes to fit content (#4)"
        ],
        "2021-08-05": [
            "Added footer",
            "Show comment count and score on forum post listing",
            "Submit comments with CTRL+Enter"
        ],
        "2021-07-27": [
            "Added sentry monitoring"
        ],
        "2021-07-26": [
            "Added user mentions, only visually for now",
            "Links that lead to external pages are marked",
            "Added links to markdown headers"
        ],
        "2021-07-22": [
            "Updated site to Bootstrap 5",
            "Improved SSR implementation",
            "Fixed manual pagination not working with JavaScript disabled"
        ],
        "2021-07-07": [
            "Populated history in user pages"
        ],
        "2021-07-04": [
            "Added infinite and manual pagination to forum page"
        ],
        "2021-07-02": [
            "Fixed comments sometimes failing to submit",
            "Add switch for server-side rendering",
            "Fixed comment editing",
            "Improve newsletter page experience"
        ],
        "2021-06-30": [
            "Replaced YouTube embeds with a lighter solution that only loads the video when you click on it"
        ],
        "2021-06-29": [
            "Website is now using SSR, improving embeds in other sites and usability when JavaScript is disabled"
        ],
        "2021-04-30": [
            "Preserve comment box text while submitting",
            "Made horizontal separators more visible",
            "Matched headers and navbar size between light and dark mode",
            "Added site settings page",
            "Moved theme switch to settings page",
            "Added automatic theme, which matches your browser/OS's theme preference",
            "Added confirmation if you exit a page with text in a box",
            "Added setting for time label format",
            "Added setting to store comment drafts when cancelling",
            "Settings will apply if changed on a different tab"
        ],
        "2021-04-29": [
            "Added text box with short link on posts",
            "Replaced \"Logged in as\" text in navbar with user picture"
        ],
        "2021-04-19": [
            "Only automatically scroll when changing pages if the user hasn't manually scrolled"
        ],
        "2021-04-18": [
            "Upload and add images to posts",
            "User picture on comments are now links to their profile"
        ],
        "2021-04-17": [
            "Readded profile pictures",
            "Added developer flair",
            "Fixed user pages infinitely loading"
        ],
        "2021-03-24": [
            "Readded social links to navbar",
            "Added loading screen",
            "Added cancel button on comment reply box",
            "Show forum description on forum post list"
        ],
        "2021-03-21": [
            "Added cool error page",
            "Redesigned comment action buttons"
        ],
        "2021-03-19": [
            "Added spinny inverter loading symbol",
            "Added super secret link",
            "Reworked backend"
        ],
        "2020-11-04": [
            "Added notifications panel modal, accessible through the navbar"
        ],
        "2020-10-24": [
            "Anonymous users can now subscribe to the newsletter"
        ],
        "2020-10-23": [
            "Comments can now be collapsed"
        ],
        "2020-10-22": [
            "Fixed timestamp tooltips not updating",
            "Add link on user dropdown to profile page",
            "Fixed wishlist page looking bad on mobile"
        ],
        "2020-10-21": [
            "Timestamps will now refresh every 30 seconds",
            "Remember scroll position after all content loads when reloading the page",
            "Fixed comment permalinks not working",
            "Fixed comment quotes and dark theme colors",
            "Added comment editing",
            "Fixed comments not being sorted by date"
        ],
        "2020-10-20": [
            "Show post edit time"
        ],
        "2020-10-15": [
            "Re-added dark theme"
        ],
        "2020-10-14": [
            "Fixed comment delete button not showing",
            "You can now delete posts if you're the author",
            "You can now edit your post's title and body"
        ],
        "2020-10-09": [
            "Added temporary user profile page"
        ],
        "2020-10-07": [
            "Rewritten site in Vue.js"
        ],
        "2020-09-10": [
            "Removed release hint in home page",
            "Fixed newlines in comments"
        ],
        "2020-07-19": [
            "Added footer",
            "Added changelog page",
            "Added wishlist page",
            "Added tooltips to all icon buttons",
            "Made logged-in navbar action buttons (settings and logout) more intuitive",
            "Made post like button more clearly indicate if you've already liked it",
            "Post author can now be clicked on forum post listing",
            "Re-added markdown quotes",
            "When replying to a comment, only that comment will now be shown",
        ]
    }

    var knownIssues = []

.container
    if knownIssues.length > 0
        .card.mt-3
            .card-body
                h2.text-warning Known issues
                hr

                ul
                    each issue in knownIssues
                        li= issue

    .card.mt-3
        .card-body
            h2 Website changelog
            hr

            each items, date in changelog
                h3(id=date)= date
                ul
                    each item in items
                        li !{item.replace(/#(\d+)/g, (_, o) => `<router-link to="/tracker/${o}">#${o}</router-link>`)}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { TitleTag } from '@/utils';

export default defineComponent({
    name: "SiteChangelog",
    components: { TitleTag }
})
</script>