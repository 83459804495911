<template lang="pug">
TitleTag(title="Creation hub index")
CanonicalRouteTag

.container
    ol.breadcrumb
        li.breadcrumb-item.active
            span(aria-current="location") Creation hub

    router-link.btn.btn-success.float-end(v-if="hasFlag('can-create-creation')" to="/hub/upload") Upload
    p.display-4 Creation hub
    hr
    Pagination.row(:fetch-items="fetchItems" use-route emit-load :max-count="12")
        template(#default="{ item }")
            .col-lg-3.col-md-4.col-sm-6.mb-4
                CreationListingItem(:item="item")
</template>

<script lang="ts" setup>
import { useQuery } from 'villus';

import { GetCreationsListingDocument } from '@/graphql';
import type { PageOptions } from '@/graphql';
import { useAuth, useEvents } from '@/mixins';

import Pagination from "@/components/widgets/Pagination.vue";
import CreationListingItem from '@/components/hub/CreationListingItem.vue';
import { CanonicalRouteTag, TitleTag } from '@/utils';

const { loaded } = useEvents();
const { hasFlag } = useAuth();

const getCreations = useQuery({
    query: GetCreationsListingDocument,
    fetchOnMount: false
});

const fetchItems = (page: PageOptions) => getCreations.execute({
    variables: { page }
}).then(({error, data}) => error ? { error } : { data: data!.creations });

loaded();
</script>
