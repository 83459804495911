<template lang="pug">
.carousel.slide(ref="el" :id="id")
    .carousel-inner.h-100
        slot

    template(v-if="count === undefined || count > 1")
        .gradient.left
        .gradient.right

        button.control.carousel-control-prev(type="button" :data-bs-target="'#' + id" data-bs-slide="prev")
            span.carousel-control-prev-icon(aria-hidden="true")
            span.visually-hidden Previous
        button.control.carousel-control-next(type="button" :data-bs-target="'#' + id" data-bs-slide="next")
            span.carousel-control-next-icon(aria-hidden="true")
            span.visually-hidden Next
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

defineProps({
    count: Number
})

const el = ref<HTMLElement>();
const id = "carousel-" + Math.floor(Math.random() * 999);

onMounted(() => import("bootstrap").then(({ Carousel }) => new Carousel(el.value!)));
</script>

<style lang="scss" scoped>
.carousel:hover .control {
    opacity: 1;
}

.gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 18%;

    animation: show 2s;

    opacity: 0;
    transition: .2s opacity;

    $color-start: rgba(black, .8);
    $color-end: transparent;

    .carousel:hover & {
        opacity: 1;
    }

    &.left {
        left: 0;
        background: linear-gradient(90deg, $color-start, $color-end);
    }
    &.right {
        right: 0;
        background: linear-gradient(270deg, $color-start, $color-end);
    }
}

@keyframes show {
    0% { opacity: 1; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}
</style>