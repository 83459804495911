import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")

  return (_openBlock(), _createElementBlock("a", {
    class: "btn btn-info",
    href: 'logicworld://' + $props.creationId,
    onClick: $setup.onOpen
  }, [
    _createVNode(_component_icon, {
      class: "me-2",
      icon: "external-link-alt"
    }),
    _createTextVNode("Open in-game")
  ], 8, _hoisted_1))
}