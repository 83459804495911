<template lang="pug">
TitleTag(title="Management panel")

.container-fluid.pt-0.h-100
    .row.h-100
        .col-md.sidebar.bg-dark.text-white.d-flex.flex-column.p-3
            ul.nav.nav-pills.flex-column
                SidebarLink(v-for="_, panel in panels" :panel="panel")
        .col.pt-2
            component(:is="panels[currentPanel][2]")
</template>

<script lang="ts" setup>
import type { PropType } from "vue";
import { provide, toRef, watch } from "vue";
import { useRouter } from "vue-router";

import SidebarLink from "@/components/manpanel/SidebarLink.vue";
import { useAuth } from "@/mixins";
import { currentPanelKey, panels } from "@/utils/management";
import type { Panels } from "@/utils/management";
import { TitleTag } from "@/utils";

const props = defineProps({
    panel: { type: String as PropType<Panels>, required: true }
})

const { hasFlag } = useAuth();
const router = useRouter();

const currentPanel = toRef(props, "panel");
provide(currentPanelKey, currentPanel);

watch(currentPanel, panel => {
    if (panel && !hasFlag(`can-access-manpanel-${panel}`)) {
        router.replace("/");
    }
}, { immediate: true })
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
    .sidebar {
        flex: 0 0 280px;
    }
}
</style>