import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07c2553e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card mb-2 px-3 py-2 flex-row" }
const _hoisted_2 = { class: "d-flex flex-column me-2" }
const _hoisted_3 = { class: "title text-muted me-1" }
const _hoisted_4 = { class: "d-flex flex-column flex-fill" }
const _hoisted_5 = { class: "mt-auto" }
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = { class: "d-flex flex-column align-items-end" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Status = _resolveComponent("Status")
  const _component_router_link = _resolveComponent("router-link")
  const _component_Tag = _resolveComponent("Tag")
  const _component_user_link = _resolveComponent("user-link")
  const _component_Kind = _resolveComponent("Kind")
  const _component_time_ago = _resolveComponent("time-ago")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(_ctx.ticket.number), 1),
      _createVNode(_component_Status, {
        class: "mt-auto mb-1 me-2",
        ticket: _ctx.ticket
      }, null, 8, ["ticket"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_link, {
        class: "title stretched-link",
        to: '/tracker/' + _ctx.ticket.number
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.ticket.title), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.tags, (tag) => {
          return (_openBlock(), _createBlock(_component_Tag, {
            class: "me-1",
            tag: tag
          }, null, 8, ["tag"]))
        }), 256))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, [
          _createTextVNode("by "),
          _createVNode(_component_user_link, {
            class: "over",
            user: _ctx.ticket.author
          }, null, 8, ["user"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Kind, {
        class: "mt-1",
        kind: _ctx.ticket.kind
      }, null, 8, ["kind"]),
      _createVNode(_component_time_ago, {
        class: "mt-auto text-end pointer",
        time: _ctx.ticket.createdAt
      }, null, 8, ["time"])
    ])
  ]))
}