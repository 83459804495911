<template lang="pug">
span.badge(v-if="flairData" :class="['bg-' + flairData.color]" :title="flairData.title") {{flairData.name}}
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"

export default defineComponent({
    props: {
        flair: String
    },
    setup({ flair }) {
        const flairData = computed(() => {
            if (!flair)
                return null;

            switch (flair) {
                case "developer":
                    return {
                        name: "Developer",
                        color: "primary",
                        title: "Developer for Logic World"
                    }
                default:
                    return null;
            }
        })

        return { flairData }
    }
})
</script>