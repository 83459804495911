import { App, inject } from "vue";
import { logTrace } from ".";

const cookiesSymbol = Symbol("cookies");

export class Cookies {
    private allCookies: Record<string, string>;
    
    constructor(cookiesString: string, private readOnly: boolean) {
        this.allCookies = parseCookies(cookiesString);
    }

    get(name: string) {
        return this.allCookies[name];
    }

    set(name: string, value: string, opts = { expires: 9999, sameSite: "lax" }) {
        if (this.readOnly)
            return;

        const d = new Date();
        d.setTime(d.getTime() + (opts.expires * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = `${name}=${value}; ${expires}; path=/; samesite=${opts.sameSite}`;

        this.allCookies[name] = value;
    }

    install(app: App) {
        app.provide(cookiesSymbol, this);
    }

    static fromDocument() {
        if (isSSR)
            throw new Error("Cannot get document cookies in SSR mode");

        return new Cookies(document.cookie, false);
    }
}

function parseCookies(str: string): Record<string, string> {
    const cookies: Record<string, string> = {};

    logTrace("parsing cookies", str);

    for (const cookie of str.split(";")) {
        const parts = cookie.split("=");

        if (parts.length != 2) {
            logTrace("skipping cookie", cookie)
            continue;
        }

        cookies[parts[0].trim()] = decodeURIComponent(parts[1].trim());
    }

    logTrace("parsed cookies", cookies);

    return cookies;
}

export function useCookies() {
    return inject<Cookies>(cookiesSymbol)!;
}