import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9443aad1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt"]
const _hoisted_2 = ["media", "srcset"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("picture", {
    class: _normalizeClass({cover: $props.cover}),
    alt: $props.alt
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sorted, (size, i) => {
      return (_openBlock(), _createElementBlock("source", {
        media: $setup.getMediaQuery(i),
        srcset: `${$props.base}-${size}p.webp`,
        type: "image/webp"
      }, null, 8, _hoisted_2))
    }), 256)),
    _createElementVNode("img", {
      src: `${$props.base}-1080p.jpg`,
      class: _normalizeClass({cover: $props.cover}),
      alt: $props.alt,
      onLoad: _cache[0] || (_cache[0] = $event => (_ctx.$emit('load')))
    }, null, 42, _hoisted_3)
  ], 10, _hoisted_1))
}