import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c021501c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid pt-0 h-100" }
const _hoisted_2 = { class: "row h-100" }
const _hoisted_3 = { class: "col-md sidebar bg-dark text-white d-flex flex-column p-3" }
const _hoisted_4 = { class: "nav nav-pills flex-column" }
const _hoisted_5 = { class: "col pt-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["TitleTag"], { title: "Management panel" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.panels, (_, panel) => {
              return (_openBlock(), _createBlock($setup["SidebarLink"], { panel: panel }, null, 8, ["panel"]))
            }), 256))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.panels[$setup.currentPanel][2])))
        ])
      ])
    ])
  ], 64))
}