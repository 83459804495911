<template lang="pug">
picture(:class="{cover}" :alt="alt")
    source(v-for="(size, i) in sorted" :media="getMediaQuery(i)" :srcset="`${base}-${size}p.webp`" type="image/webp")
    img(:src="`${base}-1080p.jpg`" :class="{cover}" :alt="alt" @load="$emit('load')")
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { PropType } from 'vue';

defineEmits(["load"]);

const props = defineProps({
    base: {
        type: String,
        required: true
    },
    sizes: {
        type: Array as PropType<number[]>,
        default: [480, 720, 1080, 1440, 2160]
    },
    ratio: {
        type: Number,
        default: 16 / 9
    },
    cover: Boolean,
    alt: String
})
const sorted = computed(() => [...props.sizes].sort((a, b) => a - b));

function getMediaQuery(i: number): string {
    const s = sorted.value;
    const r = props.ratio;

    // Oh lawd
    if (i == 0) {
        return `(min-aspect-ratio: 1/1) and (max-width: ${(s[i] * r) - 1}px), (max-aspect-ratio: 1/1) and (max-height: ${s[i] - 1}px)`;
    } else if (i == s.length - 1) {
        return `(min-aspect-ratio: 1/1) and (min-width: ${s[i - 1] * r}px), (max-aspect-ratio: 1/1) and (min-height: ${s[i - 1]}px)`;
    }

    return `(min-aspect-ratio: 1/1) and (min-width: ${s[i - 1] * r}px) and (max-width: ${(s[i] * r) - 1}px), ` +
           `(max-aspect-ratio: 1/1) and (min-height: ${s[i - 1]}px) and (max-height: ${(s[i]) - 1}px)`;
}
</script>

<style lang="scss" scoped>
.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>