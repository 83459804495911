import { computed, defineComponent, h, inject, InjectionKey, provide, reactive, ref, Ref, toRef, toRefs, vModelText, watchEffect, withDirectives } from "vue";
import { BaseSchema, ObjectSchema, ValidationError } from "yup";
import { ObjectShape } from "yup/lib/object";
import { imageUrl, typeInTextarea } from ".";

import * as allyup from "yup";

import ImageUploader from "@/components/ImageUploader.vue";
import FitTextArea from "@/components/widgets/FitTextArea.vue";

const formData: InjectionKey<Form<any>> = Symbol("form-data");

type FieldsType<T extends ObjectSchema<S>, S extends ObjectShape> = {
    [P in keyof T["fields"]]: T["fields"][P] extends BaseSchema<infer FType> ? FType : any
};

export class Form<T extends Record<string, any>> {
    public readonly schema: ObjectSchema<T>;
    public readonly data: FieldsType<ObjectSchema<T>, T>;
    public readonly isValid: Ref<true | string>;
    public readonly isSubmitting: Ref<boolean> = ref(false);
    public readonly submitError: Ref<false | string> = ref(false);

    public readonly elements: {[P in keyof T]?: HTMLInputElement} = {};

    constructor(schema: ObjectSchema<T> | ((yup: typeof allyup) => ObjectSchema<T>), private onSubmit: () => Promise<void> | void) {
        if (typeof schema === "function") {
            schema = schema(allyup);
        }

        const data = {} as T;

        for (const name of Object.keys(schema.fields)) {
            const field = schema.fields[name];

            Object.defineProperty(data, name, {
                value: field.getDefault(),
                writable: true
            });
        }

        this.data = reactive(data) as T;
        this.isValid = ref(this.validate());
        this.schema = schema;

        watchEffect(() => this.isValid.value = this.validate());
    }

    private validate() {
        try {
            this.schema.validateSync(this.data);
        } catch (e) {
            if (e instanceof ValidationError) {
                return e.message;
            }

            return "Failed to validate data";
        }

        return true;
    }

    validateField(name: keyof FieldsType<ObjectSchema<T>, T>) {
        try {
            this.schema.fields[name].validateSync(this.data[name]);
        } catch (e) {
            if (e instanceof ValidationError) {
                return e.message;
            }

            return "Failed to validate data";
        }

        return true;
    }

    copyData() {
        const ret = {} as any;

        for (const key of Object.keys(this.schema.fields)) {
            ret[key] = this.data[key];
        }

        return ret as FieldsType<ObjectSchema<T>, T>;
    }

    reset() {
        Object.assign(this.data, this.schema.getDefault());
    }

    async submit() {
        if (this.isValid && !this.isSubmitting.value) {
            this.isSubmitting.value = true;
            this.submitError.value = false;

            try {
                await this.onSubmit();
            } catch (e) {
                let msg = "An error has occurred while submitting";

                if (typeof e === "string") {
                    msg = e;
                } else if (e instanceof Error || hasMessage(e)) {
                    msg = e.message;
                }

                this.submitError.value = msg.replace(/^\[GraphQL\] /, "");
            } finally {
                this.isSubmitting.value = false;
            }
        }
    }
}

function hasMessage(obj: any): obj is { message: string } {
    return typeof (obj as any).message === "string";
}

export const VForm = defineComponent({
    props: {
        form: {
            type: Form,
            required: true
        }
    },
    setup(props, { slots }) {
        provide(formData, props.form);

        return () => h("form", {
            "onsubmit": (e: any) => (e.preventDefault(), (props.form.isValid.value === true) && props.form.submit())
        }, slots.default!(props.form.isSubmitting));
    }
})

export const VField = defineComponent({
    props: {
        name: {
            type: String,
            required: true
        },
        is: {
            type: null,
            default: "input"
        },
        showError: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: "text"
        }
    },
    setup(props, { attrs, slots }) {
        const form = inject(formData);
        if (!form) {
            throw new Error("No form found for this field");
        }

        let is = props.is;
        if (is === "textarea") {
            is = FitTextArea;
        }

        return () => {
            const valid = form.validateField(props.name);

            const att = {...attrs};
            att.class += " form-control";
            att.class += valid === true ? " is-valid" : " is-invalid";

            const input = h(is, {
                "onUpdate:modelValue": (val: any) => form.data[props.name] = val,
                modelValue: form.data[props.name],
                disabled: form.isSubmitting.value,
                placeholder: form.schema.fields[props.name].describe().label,
                name: props.name,
                type: props.type,
                tabindex: Object.keys(form.schema.fields).indexOf(props.name) + 1,
                ref: o => form.elements[props.name] = is === FitTextArea ? (o as any)?.textArea : o as HTMLInputElement,
                ...att
            }, slots.default?.call(undefined))

            return [
                typeof is === "string" ? withDirectives(input, [[vModelText, form.data[props.name]]]) : input,

                valid === true || !props.showError ? null : h("div", {
                    class: "invalid-feedback"
                }, [valid])
            ]
        }
    }
})

export const VError = defineComponent({
    props: {
        is: {
            type: String,
            default: "p"
        },
        for: String
    },
    setup(props) {
        const form = inject(formData);
        if (!form) {
            throw new Error("No form found for this field");
        }

        const error = computed(() => {
            if (props.for) {
                const v = form.validateField(props.for);
                return v === true ? false : v;
            }

            return form.submitError.value
        });

        return () => error.value === false ? null : h(props.is, {
            class: "invalid-feedback" + (props.for ? " d-block" : " my-2")
        }, [error.value])
    }
})

export const VSubmit = defineComponent({
    props: {
        color: {
            type: String,
            default: "primary"
        },
        text: String
    },
    setup(props) {
        const form = inject(formData);
        if (!form) {
            throw new Error("No form found for this field");
        }

        return () => h("button", {
            class: `btn btn-${props.color}`,
            disabled: form.isSubmitting.value || form.isValid.value !== true
        }, [form.isSubmitting.value ? h("span", {class:"spinner-border spinner-border-sm"}) : (props.text ?? "Submit")])
    }
})

export const VImage = defineComponent({
    props: {
        bodyField: {
            type: String,
            default: "body"
        }
    },
    setup(props) {
        const form = inject(formData);
        if (!form) {
            throw new Error("No form found for this field");
        }

        const bodyText = toRef(form.data, props.bodyField);

        return () => h(ImageUploader, {
            onInsertImage: (img: {id:string, name:string}) => {
                const input = form.elements[props.bodyField];
                if (!input) {
                    throw new Error("Matching input for image picker not found");
                }

                const newValue = typeInTextarea(`![${img.name}](${imageUrl(img.id)})`, input as any);
                bodyText.value = newValue;
            },
            onDeleteImage: (id: string) => {
                const input = form.elements[props.bodyField];
                if (!input) {
                    throw new Error("Matching input for image picker not found");
                }

                const escapedUrl = imageUrl(id).replace(/[.*+?^${}()|[\]\\\/]/g, '\\$&');
                const regex = `!\\[[^\\]]*?\\]\\(${escapedUrl}\\)`

                bodyText.value = bodyText.value.replaceAll(new RegExp(regex, "g"), "");
            },
            text: bodyText.value,
            evenImages: false
        })
    }
})