<template lang="pug">
.indicator.badge.d-flex.align-items-center.justify-content-center(:class="[ticket.isOpen ? 'bg-success' : 'bg-danger']")
    template(v-if="ticket.isOpen")
        | Open
    template(v-else)
        template(v-if="ticket.closedVersion")
            | Fixed in {{ticket.closedVersion}}
        template(v-else)
            | Closed
</template>

<script lang="ts">
import { Ticket } from "@/graphql"
import { defineComponent, PropType } from "vue"

export default defineComponent({
    props: {
        ticket: {
            type: Object as PropType<Pick<Ticket, "isOpen" | "closedVersion">>,
            required: true
        }
    }
})
</script>

<style scoped>
.indicator {
    min-width: 3.4rem;
    height: 3em;
}
</style>