<template lang="pug">
.container(v-if="user")
    TitleTag(:title="'@' + user.username")
    CanonicalRouteTag

    .row
        .col-lg-3
            .card
                .card-body
                    .text-center
                        img.picture(:src="user.picture ? endpoints.asset('/upload/' + user.picture) : endpoints.asset('/img/default-user.png')")
                        h4.mt-1 @{{user.username}}
                        h4.mb-0
                            UserFlair(:flair="user.flair")

                    hr
                    template(v-if="!isEditingBio")
                        p.mb-0(v-if="user.renderedBio" ref="bioEl")
                        button.btn.btn-info(v-if="currentUser && user.id == currentUser.id" @click="isEditingBio = true")
                            icon.me-2(icon="pen-to-square")
                            | Set bio

                    template(v-else)
                        FitTextArea.form-control(v-model="newBio")
                        .clearfix.mt-2
                            button.btn.btn-danger(@click="isEditingBio = false; newBio = user.bio") Cancel
                            button.btn.btn-success.float-end(@click="saveBio") Save
        .col-lg-9.mt-lg-0.mt-3
            suspense(timeout="0")
                template(#fallback)
                    spinner
                template(#default)
                    HistoryFeed(:user-id="user.id")
</template>

<script lang="ts" setup>
import { EditBioDocument, GetUserDocument } from '@/graphql';
import { useMutation, useQuery } from 'villus';
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { CanonicalRouteTag, TitleTag, watchError } from '@/utils';
import endpoints from "@/endpoints";
import { useAuth } from '@/mixins';

import UserFlair from "@/components/UserFlair.vue"
import HistoryFeed from "@/components/HistoryFeed.vue"
import FitTextArea from '@/components/widgets/FitTextArea.vue';

const route = useRoute();
const { currentUser } = useAuth();

const bioEl = ref<HTMLElement>();

const { data, error } = await useQuery({
    query: GetUserDocument,
    variables: {
        name: route.params.name as string
    }
});
watchError(error);
const user = data.value?.user;

if (!user) throw new Error("Cannot find user");

onMounted(() => { // v-html doesn't seem to work while using SSR
    if (bioEl.value)
        bioEl.value.innerHTML = user.renderedBio;
})

const isEditingBio = ref(false);
const newBio = ref(user.bio);

async function saveBio() {
    const resp = await useMutation(EditBioDocument).execute({ newBio: newBio.value });
    if (resp.error) {
        throw resp.error;
    }

    user!.bio = newBio.value;
    user!.renderedBio = resp.data.editMyBio.renderedBio;
    isEditingBio.value = false;
}
</script>

<style lang="scss" scoped>
.picture {
    width: 100px;
    height: 100px;
}
</style>