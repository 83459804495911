import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", [_ctx.modelValue == _ctx.value ? 'btn-primary' : 'btn-secondary']]),
    type: "button",
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', _ctx.value)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}