<template lang="pug">
TitleTag(title="Trackers")
    
.container.d-flex.justify-content-center
    table
        tr
            td.board(v-for="_ in 15")

        tr(v-for="(_, y) in Array(5)")
            td.board
            td.board
            td.cell(v-for="(_, x) in Array(11)" :class="{on: display[x + (y * 11)] == 1}")
            td.board
            td.board

        tr
            td.board(v-for="_ in 15")
        tr
            td.board(v-for="_ in 2")
            td.label(colspan="8") {{phrase}}
            td.board
            td.button(colspan="2")
                router-link.stretched-link(to="/")
                    icon.icon(icon="home")
            td.board(v-for="_ in 2")
        tr
            td.board(v-for="_ in 15")

</template>

<script lang="ts">
import { computed, defineComponent, reactive } from "vue"
import { getReasonPhrase } from "http-status-codes"
import { useRoute } from "vue-router";

import { TitleTag } from "@/utils";

const digits = [
    [
        1, 1, 1,
        1, 0, 1,
        1, 0, 1,
        1, 0, 1, 
        1, 1, 1,
    ],
    [
        0, 0, 1,
        0, 0, 1,
        0, 0, 1,
        0, 0, 1,
        0, 0, 1,
    ],
    [
        1, 1, 1,
        0, 0, 1,
        1, 1, 1,
        1, 0, 0,
        1, 1, 1,
    ],
    [
        1, 1, 1,
        0, 0, 1,
        1, 1, 1,
        0, 0, 1,
        1, 1, 1,
    ],
    [
        1, 0, 1,
        1, 0, 1,
        1, 1, 1,
        0, 0, 1,
        0, 0, 1,
    ],
    [
        1, 1, 1,
        1, 0, 0,
        1, 1, 1,
        0, 0, 1,
        1, 1, 1,
    ],
    [
        1, 1, 1,
        1, 0, 0,
        1, 1, 1,
        1, 0, 1,
        1, 1, 1,
    ],
    [
        1, 1, 1,
        0, 0, 1,
        0, 0, 1,
        0, 0, 1,
        0, 0, 1,
    ],
    [
        1, 1, 1,
        1, 0, 1,
        1, 1, 1,
        1, 0, 1,
        1, 1, 1,
    ],
    [
        1, 1, 1,
        1, 0, 1,
        1, 1, 1,
        0, 0, 1,
        0, 0, 1,
    ]
]

function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default defineComponent({
    props: {
        code: Number
    },
    setup(props) {
        let code = props.code as number;
        if (!code) {
            const p = useRoute().query.code;
            
            if (p) {
                code = Number(p);
            } else {
                code = 0;
            }
        }

        const phrase = computed(() => {
            try {
                return getReasonPhrase(code);
            } catch {
                return code === 69 ? "Nice" : "Unknown error";
            }
        });
        const display = reactive(Array(55).fill(0) as number[]);

        function set(x: number, y: number, val: number) {
            display[x + (y * 11)] = val;
        }

        const n = String(code).padStart(3, "0");

        if (n.length > 3) {
            throw "number is too long";
        }

        async function drawDigit(offset: number, n: number) {
            const digit = digits[n];

            for (var j = 0; j < digit.length; j++) {
                const x = (j % 3) + offset * 4;
                const y = Math.floor(j / 3);
                
                set(x, y, digit[j]);

                if (digit[j] && !isSSR) {
                    await delay(70);
                    
                    if (Math.random() > .7) {
                        set(x, y, 0);
                        await delay(100);
                        set(x, y, 1);
                    }
                }
            }
        }

        for (var i = 0; i < n.length; i++) {
            drawDigit(i, Number(n[i]));
        }

        return { display, phrase }
    }
})
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap')

@mixin shadow
    box-shadow: -7px -0px 2px -1px rgba(0,0,0,0.5)

td
    max-width: 50px
    width: 50px
    height: 50px
    color: rgb(55, 58, 60)
    font-family: "Noto Sans"

    &.cell
        &:not(.on)
            background: rgb(39, 39, 39)
        &.on
            background: rgb(255, 254, 7)
            
        &:nth-child(3)
            @include shadow

    &.label
        @include shadow
        text-align: center
        font-size: 1.1rem
        line-height: 1.1rem
        font-weight: 600
        
        background: rgb(244, 244, 244)

    &.board
        background: rgb(173, 173, 167)
        border: rgb(73, 73, 72) solid 4px


    &.button
        @include shadow
        background: rgb(244, 244, 244)
        position: relative
        cursor: pointer
        text-align: center

        & .icon
            z-index: 200
            position: relative
            color: white
            font-size: 1.4rem

        &::before
            $space: 3px

            content: " "
            color: white
            font-weight: bold
            text-align: center
            // z-index: 1

            background: rgb(155, 74, 15)
            position: absolute
            left: $space
            top: $space
            right: $space
            bottom: $space
</style>
