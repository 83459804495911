<template lang="pug">
div.d-flex.flex-column
    FitTextArea.form-control.w-100.mb-2.flex-grow-1(v-model="body" @submit="submit" :disabled="!canSubmit || isFetching"
        :placeholder="canSubmit ? 'Write your own comment' : authed ? 'You cannot post comments' : 'Log in to comment'" )

    .d-flex(v-if="canSubmit")
        .ms-auto
            span.text-muted.me-3(v-if="isFetching") Submitting...
            span.text-danger.me-3(v-else-if="error" @click="error = undefined") There was an error submitting your comment.
            button.btn.btn-danger.me-2(@click="cancel" v-if="canCancel") Cancel
            button.btn.btn-primary(@click="submit") Submit
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'
import { CombinedError, useMutation } from 'villus';

import { CreateCommentDocument } from '@/graphql';
import { useAuth, useEvents, usePreventClose, useSettings } from "@/mixins"
import FitTextArea from "@/components/widgets/FitTextArea.vue";

const props = defineProps({
    objectid: String,
    parentid: String,
    canCancel: Boolean
});
const emit = defineEmits(["sent", "cancel"]);

const body = ref("");
const { newComment } = useEvents();
const { storeCommentDraft } = useSettings();
const { hasFlag, authed } = useAuth();
const canSubmit = hasFlag("can-create-comment");

if (!isSSR) {
    const storageKey = "cmt@" + props.objectid + "@" + props.parentid;
    const storedBody = localStorage.getItem(storageKey);
    if (storedBody !== null && storeCommentDraft.value) {
        const stored = JSON.parse(storedBody) as { date: number, body: string };

        if (new Date().getTime() - stored.date < 60 * 60 * 1000) {
            body.value = stored.body;
        } else {
            localStorage.removeItem(storageKey);
        }
    }

    onUnmounted(() => storeCommentDraft.value && body.value && localStorage.setItem(storageKey, JSON.stringify({ date: new Date().getTime(), body: body.value })));

    usePreventClose(body);
}

const error = ref<CombinedError>();
const { execute, isFetching } = useMutation(CreateCommentDocument)

async function submit() {
    var text = body.value;

    var resp = await execute({
        body: text,
        objectid: props.objectid!,
        parentid: props.parentid ?? ""
    });
    error.value = resp.error;

    if (resp.error) {
        console.error(resp.error);
    } else {
        body.value = "";

        emit("sent");
        newComment(resp.data.createComment);
    }
}

function cancel() {
    emit("cancel");
}
</script>
