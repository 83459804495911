<template lang="pug">
.container(v-if="data")
    MetaTag(name="og:title" :content="data.forum.title")
    MetaTag(name="og:description" :content="data.forum.description")
    MetaTag(name="description" :content="data.forum.description")
    FeedTag(:object-id="id")
    CanonicalRouteTag
    TitleTag(:title="data.forum.title")

    ol.breadcrumb
        li.breadcrumb-item
            router-link(to="/forums") Forums
        li.breadcrumb-item.active
            span(aria-current="location") {{data.forum.title}}

    .position-relative.clearfix
        FeedIcon.float-end(:object-id="id")
        h1.display-4 {{data.forum.title}}
        IfFlag(:flag="data.forum.adminOnly ? 'can-create-post-adminonly' : 'can-create-post'")
            router-link.btn.btn-success.new-post.mb-1(:to="{name:'newpost', params:{id: route.params.id}}") New post
        p {{data.forum.description}}

    .mt-2.d-block
        Pagination(:fetch-items="fetchItems" use-route emit-load)
            template(#default="{ item }")
                .card.mb-1.d-flex.flex-row.px-3.py-2
                    div
                        router-link.stretched-link.post-title(:to="{name:'post', params:{id: item.id, name: item.uglytitle}}") {{item.title}}
                        br
                        span.text-muted
                            | by 
                            user-link.over(:user="item.author")

                    .ms-auto.d-flex.flex-column.align-items-end
                        span
                            icon.me-1.muted(icon="heart" :class="{'text-danger': auth.currentUser && item.rating.likedBy.includes(auth.currentUser.id)}")
                            span.me-2 {{item.rating.score}}
                            span.comments
                                icon.me-1.muted(icon="comment-alt")
                                span {{item.commentCount}}
                        small.mt-auto.over.time
                            time-ago(:time="item.createdat")
</template>

<script lang="ts" setup>
import { useQuery } from "villus"
import { useRoute } from 'vue-router';

import { GetForumDocument, GetForumPostsListingDocument } from "@/graphql";
import type { GetForumQuery, GetForumQueryVariables, PageOptions } from "@/graphql";
import { useAuth } from "@/mixins";
import { CanonicalRouteTag, FeedTag, MetaTag, TitleTag } from "@/utils/head"
import { watchError } from "@/utils";

import Pagination from "@/components/widgets/Pagination.vue";
import FeedIcon from "@/components/widgets/FeedIcon.vue";

const route = useRoute();
const auth = useAuth();

const id = String(route.params.id);

const getPosts = useQuery({
    query: GetForumPostsListingDocument,
    fetchOnMount: false
})

const fetchItems = (page: PageOptions) => getPosts.execute({
    variables: {
        query: `forum:"${id}"`,
        page
    }
}).then(({error, data}) => error ? { error } : { data: data!.posts });

const { data, error } = await useQuery<GetForumQuery, GetForumQueryVariables>({
    query: GetForumDocument,
    variables: {
        id: id,
        page: {
            page: route.query.page ? Number(route.query.page) : undefined,
            maxCount: 10
        }
    }
})
watchError(error);
</script>

<style lang="sass" scoped>
@import "@/styles/style"

.comments
    display: inline-block
    min-width: 2.2rem
    text-align: right

.post-title
    font-size: 1.5rem

.muted
    &:not(.text-danger)
        @include light()
            opacity: .35
        @include dark()
            opacity: .5

.time
    text-align: right
    cursor: pointer

.new-post
    float: right
</style>