import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: "badge rounded-pill user-select-none",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createTextVNode(_toDisplayString(_ctx.tag.name), 1),
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}