<template lang="pug">
#createTagModal.modal.fade(ref="modalEl")
    .modal-dialog
        .modal-content
            .modal-header
                h5.modal-title Create new tag
                button.btn-close(type="button" data-bs-dismiss="modal" aria-label="Close")

            .modal-body
                .row
                    .col
                        label.form-label Tag name
                        input.form-control(v-model="name")
                    .col
                        label.form-label Product
                        select.form-control(v-model="product")
                            option(value="") All
                            option(v-for="prod in products?.products" :value="prod.id") {{prod.prettyName}}

                hr.mb-0

                label.form-label.mt-3 Choose tag color:
                input.d-block(v-model="color" type="color")

                label.form-label.mt-3 From random colors
                    a(href="#" @click.prevent="generateRandom")  (Generate)
                div
                    button.btn.me-1.text-white(v-for="clr in randomColors" :style="{backgroundColor: clr}" @click="color = clr") {{clr}}

                label.form-label.mt-3 From existing tag:
                div
                    Tag.pointer.me-1(v-for="tag in allTags" :tag="tag" @click="color = tag.color")

            .modal-footer
                button.btn.btn-secondary(type="button" data-bs-dismiss="modal") Cancel
                button.btn.btn-primary(type="button" @click="submit(false)") Create
                button.btn.btn-primary(type="button" @click="submit(true)") Create and add
</template>

<script lang="ts" setup>
import type { CreateTicketTagMutation, CreateTicketTagMutationVariables, GetProductsQuery, TicketTag } from "@/graphql";
import { CreateTicketTagDocument, GetProductsDocument } from "@/graphql";
import { onMounted, ref } from "vue"
import type { PropType } from "vue"
import { useMutation, useQuery } from "villus";
import tinycolor from "tinycolor2";

import Tag from "@/components/tracker/TicketTag.vue"

const props = defineProps({
    allTags: {
        type: Array as PropType<TicketTag[]>,
        required: true
    },
    currentProduct: {
        type: String,
        default: ""
    }
});
const emit = defineEmits(["addTag"]);

const name = ref("");
const color = ref("#FF0000");
const modalEl = ref<Element>();
const product = ref(props.currentProduct);
const randomColors = ref<string[]>([]);
const modal = ref<import("bootstrap").Modal>();

const { execute } = useMutation<CreateTicketTagMutation, CreateTicketTagMutationVariables>(CreateTicketTagDocument);
const { data: products } = useQuery<GetProductsQuery>({ query: GetProductsDocument })

onMounted(() => import("bootstrap").then(({ Modal }) => modal.value = new Modal(modalEl.value!)))

async function submit(add: boolean) {
    if (name.value == "") {
        return;
    }

    const tag = {
        name: name.value,
        color: color.value,
        product: product.value
    }

    await execute(tag);

    props.allTags.push(tag);
    props.allTags.sort((a, b) => a.name.localeCompare(b.name));
    modal.value?.hide();
    name.value = "";

    if (add) {
        emit("addTag", tag);
    }
}

function generateRandom() {
    randomColors.value = [];

    for (let i = 0; i < 4; i++) {
        randomColors.value.push(tinycolor.random().toHexString());
    }
}
generateRandom();
</script>