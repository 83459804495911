<template lang="pug">
span.time-ago.ms-1(:class="{'text-muted': !raw}" v-tooltip="date?.toString()")
    | {{formatted}}
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { format } from 'timeago.js';
import { useSettings, useTime } from "@/mixins"

const props = defineProps({
    time: {
        type: [Object, Number],
        required: true
    },
    raw: {
        type: Boolean,
        default: false
    }
})

const now = useTime();
const { timeFormat } = useSettings();

const date = computed(() => typeof props.time == "number" ? new Date(props.time! * 1000) : props.time as Date);

const formatted = computed(() => {
    if (timeFormat.value == "relative") {
        return format(typeof props.time == "number" ? (props.time || 0) * 1000 : (props.time as Date), undefined, {
            relativeDate: now.value as Date
        })
    } else {
        return date.value?.toLocaleString();
    }
});
</script>

<style scoped>
.time-ago {
    z-index: 5;
    position: relative;
}
</style>