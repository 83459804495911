<template lang="pug">
transition(name="slide")
    .backdrop.d-flex.flex-column.align-items-center.justify-content-center(v-if="shown" @click.self="shown = false")
        .n-modal.card
            .card-body.row
                .col-md-3.separator-right
                    ul.nav.nav-pills.flex-column
                        li.nav-item
                            a.nav-link.d-flex.justify-content-between.align-items-center(href="#" :class="{active: showUnread}" @click.prevent="showUnread = true")
                                | Unread
                                span.badge.badge-primary.badge-pill {{unreadCount}}
                        li.nav-item
                            a.nav-link.d-flex.justify-content-between.align-items-center(href="#" :class="{active: !showUnread}" @click.prevent="showUnread = false")
                                | All
                                span.badge.badge-primary.badge-pill {{data.notifications?.length}}
                .col-md-9
                    .position-relative.h-100
                        .list-group.scroll-view
                            template(v-for="notif in data.notifications" :key="notif.id")
                                .notif.list-group-item.list-group-item-action.flex-column.align-items-start.position-relative(v-if="!showUnread || !notif.read" :class="{disabled: notif.read, read: notif.read}")
                                    router-link.stretched-link(:to="'/view/' + notif.causedByObject" @click.capture="markRead(notif, true)")
                                    .d-flex.w-100.justify-content-between
                                        h5.mb-0(v-html="getText(notif)")
                                        time-ago(:time="notif.createdAt")
                                    .mark-read(v-if="!notif.read")
                                        button.btn.btn-primary(v-tooltip="'Mark as read'" @click="markRead(notif)")
                                            icon(icon="check")
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useMutation, useQuery } from 'villus';

import { useEvents } from '@/mixins';
import { GetNotificationsDocument, MarkNotifAsReadDocument } from '@/graphql';
import type { Notification, GetNotificationsQuery } from "@/graphql";

enum NotificationType {
    PostReply = 1,
    CommentReply
}

function makeNotificationBody(n: Pick<Notification, "type" | "byUser">): string {
    switch (n.type) {
        case NotificationType.CommentReply:
            return `<a href="#">@${n.byUser.username}</a> replied to your comment`
        case NotificationType.PostReply:
            return `<a href="#">@${n.byUser.username}</a> commented on your post`
    }

    return "Unknown notification"
}

const { toggleNotifsModal } = useEvents();

const shown = ref(false);
toggleNotifsModal.on(() => shown.value = !shown.value);

const { data } = useQuery<GetNotificationsQuery>({
    query: GetNotificationsDocument,
    variables: { unread: false }
})

const showUnread = ref(true);

const unreadCount = computed(() => data.value?.notifications?.filter(o => !o.read).length);

const { execute } = useMutation(MarkNotifAsReadDocument);

async function markRead(notif: Pick<Notification, "id" | "read">, hide: boolean = false) {
    notif.read = true;

    var { error } = await execute({ id: notif.id });

    if (error) {
        notif.read = false;
    } else if (hide) {
        shown.value = false;
    }
}

function getText(notif: Notification) {
    return makeNotificationBody(notif);
}
</script>

<style lang="scss">
@import "@/styles/style";

$anim-duration: 0.15s;
$anim-name: "slide";

.backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1028;
    overflow: hidden;
    
    background-color: rgba($color: #000000, $alpha: .5);

    &.#{$anim-name}-enter-active,
    &.#{$anim-name}-leave-active {
        transition: opacity $anim-duration cubic-bezier(1, 0.5, 0.8, 1);
    }

    &.#{$anim-name}-enter-from,
    &.#{$anim-name}-leave-to {
        // transform: translateX(-100%);
        opacity: 0;
    }
}

.n-modal {
    width: min(80vw, 1092.8px);
    height: min(90vh, 691.2px);

    .#{$anim-name}-enter-active &,
    .#{$anim-name}-leave-active & {
        transition: transform $anim-duration cubic-bezier(1, 0.5, 0.8, 1);
    }

    .#{$anim-name}-enter-from &,
    .#{$anim-name}-leave-to & {
        transform: scale(0.6);
    }
}

.scroll-view {
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.mark-read {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    transition: all 0.15s ease-in-out;
    transform: translateX(calc(-100% - 1px));
    
    .btn {
        border-radius: 0 !important;
    }

    .notif:hover & {
        transform: translateX(0);
    }
}

.notif.read {
    text-decoration: line-through;
    user-select: none;
}

.notif:not(.read) {
    background-color: rgba(yellow, .1);
}

@media (min-width: 768px) {
    @include light {
        .separator-right {
            border-right: 1px solid #dee2e6;
        }
    }
    @include dark {
        .separator-right {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
}
</style>
