<template lang="pug">
template(v-if="!editing")
    h1 {{post.title}}
    
    span.text-muted by 
    user-link(:user="post.author")
    span.text-muted
        time-ago(:time="post.createdat")

        template(v-if="post.editedat")
            |
            | (edited
            time-ago(:time="post.editedat")
            | )

    hr
    Markdown(:html="post.renderedbody")
    hr

    BottomSocials(:object-id="post.id" :rating="post.rating")

template(v-else)
    .d-flex.mb-2
        input.form-control.me-2(v-model="newTitle" name="newTitle" :disabled="submittingEdit")
        button.btn.btn-danger.me-2(@click="$emit('finishedEdit')" :disabled="submittingEdit") Cancel
        button.btn.btn-success(@click="editPost" :disabled="submittingEdit") Save
    span.text-danger(v-if="editError") There was an error submitting your edit
    hr
    textarea.form-control(v-model="newBody" rows="10" name="newBody" :disabled="submittingEdit")

    LoadingOverlay(v-if="submittingEdit")
</template>

<script lang="ts" setup>
import { toRefs, ref, watch } from "vue";
import type { PropType } from "vue";
import { useMutation } from "villus";

import type { EditPostMutation, EditPostMutationVariables, GetPostQuery } from "@/graphql";
import { EditPostDocument } from "@/graphql";
import { useAuth } from "@/mixins";

import Markdown from "@/components/Markdown.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import BottomSocials from "@/components/widgets/BottomSocials.vue";

const props = defineProps({
    post: {
        type: Object as PropType<GetPostQuery["post"]>,
        required: true
    },
    editing: Boolean
});
const emit = defineEmits(["finishedEdit"]);
const { post } = toRefs(props);

const { authed } = useAuth();

const submittingEdit = ref(false);
const editError = ref(false);
const newTitle = ref("");
const newBody = ref("");

watch(post, () => {
    newTitle.value = post.value.title;
    newBody.value = post.value.body;
}, { immediate: true });

const editMutation = useMutation<EditPostMutation, EditPostMutationVariables>(EditPostDocument);

async function editPost() {
    if (!(authed && post.value)) {
        return;
    }
    submittingEdit.value = true;

    try {
        const { error, data: newData } = await editMutation.execute({
            id: post.value.id,
            newTitle: newTitle.value,
            newBody: newBody.value
        });

        if (error) {
            editError.value = true;
        } else if (newData) {
            post.value.editedat = Date.now() / 1000;
            post.value.title = newData.editPost.title;
            post.value.renderedbody = newData.editPost.renderedbody;
            emit("finishedEdit");
        }
    } finally {
        submittingEdit.value = false;
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/style";

.post-controls > *:not(:last-child) {
    margin-right: .25rem;
}
</style>