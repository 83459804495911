import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge bg-warning text-dark",
  style: {"width":"4rem"}
}
const _hoisted_2 = {
  key: 1,
  class: "badge bg-info",
  style: {"width":"7rem"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.kind == 'ISSUE')
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Bug"))
    : (_ctx.kind == 'FEATURE')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Feature request"))
      : _createCommentVNode("", true)
}