<template lang="pug">
.container(v-if="ticket")
    MetaTag(name="og:title" :content="ticket.title")
    MetaTag(name="og:url" :content="endpoints.main(`/view/${ticket.id}`)")
    MetaTag(:names="['og:description', 'description']" :content="stub(ticket.renderedbody)")
    FeedTag(:object-id="ticket.id")
    TitleTag(:title="ticket.title")

    ol.breadcrumb
        li.breadcrumb-item
            router-link(to="/tracker") Trackers
        li.breadcrumb-item
            router-link(:to="'/tracker/' + ticket.product.id") {{ticket.product.prettyName}}
        li.breadcrumb-item.active
            span(aria-current="location") {{ticket.title}}

    .row
        .col
            .card
                //- Display ticket
                .card-body.d-flex.flex-column(v-if="!editing")
                    div
                        Status.float-end.mb-2(:ticket="ticket")

                        h1.mb-0
                            span.text-muted &num;{{ticket.number}}
                            |  {{ticket.title}}
                        div.mb-1
                            Tag.me-1(v-for="tag in ticket.tags" :tag="tag" :class="{pointer: canSetTags}" @click="removeTag(tag.name)")
                                span.ms-1(v-if="canSetTags") &times;

                        span.badge.bg-primary.me-1 {{ticket.product.prettyName}}
                        Kind.me-2(:kind="ticket.kind")

                        span.text-muted
                            | by 
                            user-link(:user="ticket.author")
                            time-ago(:time="ticket.createdAt")

                        button.btn.float-end(v-if="canEdit" v-tooltip="'Edit ticket'" @click="startEdit")
                            icon.text-muted(icon="edit")

                        hr

                    .flex-fill
                        Markdown(:html="ticket.renderedbody")

                    hr
                    BottomSocials(:object-id="ticket.id" :rating="ticket.rating" :show-share-url="false")

                //- Editing
                .card-body(v-else)
                    h5 Editing ticket &num;{{ticket.number}}
                    hr
                    .input-group
                        input.form-control(v-model="newTitle")
                        button.btn.btn-danger(type="button" @click="editing = false") Cancel
                        button.btn.btn-success(type="button" @click="submitEdit") Save
                    textarea.form-control.mt-3(v-model="newBody" rows="10")

        .col-3.w-20(v-if="canSetStatus || canSetTags")
            .card
                .card-body
                    h5.text-center.mb-0 Manage ticket
                    hr
                    template(v-if="canSetStatus")
                        .btn-group(v-if="ticket.isOpen")
                            button.btn.btn-primary(@click="setClosed(true)") Close ticket
                            button.btn.btn-primary.dropdown-toggle.dropdown-toggle-split(v-dropdown)
                            ul.dropdown-menu
                                li
                                    a.dropdown-item(href="#" @click.prevent="closeInVersion()") Close in version
                        button.btn.btn-primary(v-else @click="setClosed(false)") Reopen ticket

                    TagPicker.mt-3(v-if="canSetTags" :ticket="ticket")

    .card.mt-5
        .card-body
            CommentList(:objectid="ticket.id")
</template>

<script lang="ts" setup>
import { useMutation, useQuery } from "villus"
import { ref, computed } from "vue"
import { useRoute } from "vue-router"

import type { EditTicketMutation, EditTicketMutationVariables, GetTicketQuery, GetTicketQueryVariables, SetTicketStatusMutation, SetTicketStatusMutationVariables, SetTicketTagsMutation, SetTicketTagsMutationVariables } from "@/graphql"
import { EditTicketDocument, GetTicketDocument, SetTicketStatusDocument, SetTicketTagsDocument } from "@/graphql"

import { useEvents } from "@/mixins"
import { canAllOrOwn, stub } from "@/utils"

import endpoints from '@/endpoints';
import Tag from "@/components/tracker/TicketTag.vue"
import Status from "@/components/tracker/TicketStatus.vue"
import Kind from "@/components/tracker/TicketKind.vue"
import TagPicker from "@/components/tracker/TagPicker.vue"
import CommentList from "@/components/CommentList.vue";
import Markdown from "@/components/Markdown.vue";
import { FeedTag, MetaTag, TitleTag } from "@/utils/head"
import BottomSocials from "@/components/widgets/BottomSocials.vue"

const { loaded } = useEvents();
const route = useRoute();

const editing = ref(false);
const newTitle = ref("");
const newBody = ref("");

const id = Number(route.params.id);

const { execute: setStatus } = useMutation<SetTicketStatusMutation, SetTicketStatusMutationVariables>(SetTicketStatusDocument);
const { execute: setTags } = useMutation<SetTicketTagsMutation, SetTicketTagsMutationVariables>(SetTicketTagsDocument);
const { execute: editTicket } = useMutation<EditTicketMutation, EditTicketMutationVariables>(EditTicketDocument);

const { data } = await useQuery<GetTicketQuery, GetTicketQueryVariables>({
    query: GetTicketDocument,
    variables: { id }
})
const ticket = computed(() => data.value?.ticket);

const canEdit = canAllOrOwn("can-edit-ticket", () => ticket.value?.authorId);
const canSetStatus = canAllOrOwn("can-update-ticket-status", () => ticket.value?.authorId);
const canSetTags = canAllOrOwn("can-update-ticket-tags", () => ticket.value?.authorId);

loaded();

if (!ticket.value)
    throw new Error("No ticket?");

if (!isSSR) {
    document.title = `${ticket.value.title} - Logic World`;
}

async function setClosed(closed: boolean) {
    await setStatus({
        id,
        open: !closed,
    })

    data.value!.ticket.isOpen = !closed;
    data.value!.ticket.closedVersion = "";
}

async function removeTag(tag: string) {
    if (!canSetTags.value) {
        return;
    }

    const ticket = data.value!.ticket;
    const prevTags = [...ticket.tags];

    const index = ticket.tags.findIndex(o => o.name == tag);
    ticket.tags.splice(index, 1);

    const resp = await setTags({
        id: ticket.number,
        tags: ticket.tags.map(o => o.name)
    })

    if (resp.error) {
        ticket.tags = prevTags;
        throw resp.error;
    }
}

async function closeInVersion() {
    const version = prompt("What version is this ticket closed in?");
    if (!version) {
        return;
    }

    await setStatus({
        id,
        version,
        open: false
    })

    data.value!.ticket.isOpen = false;
    data.value!.ticket.closedVersion = version;
}

function startEdit() {
    newTitle.value = data.value!.ticket.title;
    newBody.value = data.value!.ticket.body;
    editing.value = true;
}

async function submitEdit() {
    const { error, data: resp } = await editTicket({
        id,
        newTitle: newTitle.value,
        newBody: newBody.value
    })

    if (!error) {
        data.value!.ticket.title = newTitle.value;
        data.value!.ticket.body = newBody.value;
        data.value!.ticket.renderedbody = resp.editTicket.renderedbody;
        editing.value = false;
    }
}
</script>

<style scoped>
.w-20 {
    width: 20%;
}
</style>