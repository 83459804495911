<template lang="pug">
.col-md-9
    .card
        .card-body
            v-form(:form="form")
                .d-flex.mb-3
                    v-field.flex-grow-1(name="newTitle" :show-error="false")
                    button.btn.btn-danger.mx-2(@click.prevent="$emit('cancel')") Cancel
                    v-submit(color="success" text="Save")

                v-field(name="newBody" is="textarea")

.col-md
    .card
        .card-body
            h5.text-center Carousel images
            hr
            ImageUploader.flex-wrap(v-model="images" reorderable)
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { PropType } from 'vue';
import { useMutation } from 'villus';

import ImageUploader from "@/components/ImageUploader.vue";

import { EditCreationDocument } from '@/graphql';
import type { HubCreation } from '@/graphql';
import { VForm, VField, VSubmit, Form } from "@/utils";

const emit = defineEmits<{
    (e: "done", title: string, body: string, renderedBody: string, images: string[]): void
    (e: "cancel"): void
}>();
const props = defineProps({
    creation: {
        type: Object as PropType<Pick<HubCreation, "id" | "title" | "body" | "images">>,
        required: true
    }
})

const images = ref(props.creation.images.map(o => ({ id: o, name: o })));

const { execute } = useMutation(EditCreationDocument)

const form = new Form(y => y.object({
    newTitle: y.string().label("New title").default(props.creation.title).required(),
    newBody: y.string().label("New body").default(props.creation.body).required()
}), async () => {
    const imgs = images.value.map(o => o.id);

    const { data, error } = await execute({
        id: props.creation.id,
        newTitle: form.data.newTitle,
        newBody: form.data.newBody,
        newImages: imgs
    });

    if (error) {
        throw error;
    }

    emit("done", data.editCreation.title, form.data.newBody, data.editCreation.renderedBody, imgs);
})
</script>