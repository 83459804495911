<template lang="pug">
TitleTag(title="Upload creation")

.container
    p.display-4 Upload your creation

    .row
        .col-md-9
            .card
                v-form.card-body(:form="form")
                    v-field(name="title" :show-error="false")
                    v-field.mt-3(name="body" is="textarea" :initialHeight="200")

                    v-error.d-block

                    .mt-3.d-flex
                        .flex-grow-1.file-input(:class="{disabled: !!preselectedFile}")
                            .input-group(@click.prevent="fileInputEl?.click()")
                                .btn.btn-primary(:class="{disabled: !!preselectedFile}") Select file
                                input.form-control(type="text" :value="selectedFile ? selectedFile.name : preselectedFile ? preselectedFile.fileName : ''" placeholder="No file selected")

                            input(type="file" ref="fileInputEl" @change="fileSelected")
                        v-submit.ms-3(color="success")
        .col-md.mt-3.mt-md-0
            .card
                .card-body
                    h5.text-center
                        | Carousel images
                        span(v-tooltip:left="'These images will be shown at the top of your post. You can reorder them by clicking and dragging.'")
                            icon.ms-2.info(icon="info-circle")
                    hr
                    ImageUploader.flex-wrap(v-model="images" reorderable :showNotAddedWarning="false" showNoImagesWarning)
</template>

<script lang="ts" setup>
import * as yup from "yup";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { VForm, VField, VSubmit, VError, Form, requireFlag, TitleTag } from '@/utils';
import ImageUploader from "@/components/ImageUploader.vue";
import { HubCreationKind, UploadCreationDocument } from "@/graphql";
import { useMutation } from "villus";

requireFlag("can-create-creation");

const route = useRoute();
const router = useRouter();

const form = new Form(yup.object({
    kind: yup.string().label("Kind").default(HubCreationKind.World),
    title: yup.string().label("Title").default("").required(),
    body: yup.string().label("Body").default(""),

    file: yup.bool().label("File").default(false).oneOf([true]),
    images: yup.array().default([]).of(yup.string())
}), submit);

const kindName = (kind: HubCreationKind) => Object.keys(HubCreationKind)[Object.values(HubCreationKind).indexOf(kind)];

const fileInputEl = ref<HTMLInputElement>();
const selectedFile = ref<File>();

const queryImages = typeof route.query.images === "string" ? route.query.images.split(",").map(o => ({ id: o, name: o })) : [];

const images = ref<{ id: string, name: string }[]>(queryImages);
watch(images, images => form.data.images = images.map(o => o.id), { deep: true });

const preselectedFile = ref<{ fileName: string, id: string }>();

if (typeof route.query.file === "string") {
    const idx = route.query.file.indexOf("-");

    if (idx >= 0) {
        preselectedFile.value = {
            id: route.query.file.substr(0, idx),
            fileName: route.query.file.substr(idx + 1),
        };
        form.data.file = true;
    }
}
if (typeof route.query.title === "string") {
    form.data.title = route.query.title;
}
if (typeof route.query.body === "string") {
    form.data.body = route.query.body;
}
if (typeof route.query.kind === "string" && Object.values(HubCreationKind).includes(route.query.kind as any)) {
    form.data.kind = route.query.kind;
}

function fileSelected() {
    selectedFile.value = fileInputEl.value!.files![0];
    form.data.file = true;
    preselectedFile.value = undefined;
}

const { execute } = useMutation(UploadCreationDocument);

async function submit() {
    if (images.value.length == 0 && !confirm("You haven't uploaded any images, are you sure you want to continue?")) {
        return
    }

    const { error, data } = await execute({
        input: {
            title: form.data.title,
            body: form.data.body,
            kind: HubCreationKind.World,
            images: images.value.map(o => o.id),
            file: selectedFile.value,
            uploadedFileId: preselectedFile.value?.id
        }
    })

    if (error) {
        throw error;
    }

    router.push(`/view/${data.uploadCreation.id}`);
}
</script>

<style lang="scss" scoped>
.kind {
    width: 15rem;
}

.file-input {
    position: relative;

    &:not(.disabled) * {
        cursor: pointer;
    }

    &.disabled {
        pointer-events: none;
    }

    input[type=file] {
        display: none;
    }

    input[type=text] {
        pointer-events: none;
    }
}

.info {
    font-size: .9rem;
}
</style>