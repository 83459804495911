import { useEvents } from '@/mixins';
import { createMemoryHistory, createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { Settings } from '@/mixins/settings';

import Home from "@/views/Home.vue"
import Newsletter from "@/views/Newsletter.vue"
import Wishlist from "@/views/Wishlist.vue"
import SiteChangelog from "@/views/SiteChangelog.vue"
import SiteSettings from "@/views/SiteSettings.vue"
import Admin from "@/views/Admin.vue"
import Management from "@/views/Management.vue"
import UserPage from "@/views/UserPage.vue"
import ForumsIndex from "@/views/forums/ForumsIndex.vue"
import Forum from "@/views/forums/Forum.vue"
import Post from "@/views/forums/Post.vue"
import CreatePost from "@/views/forums/CreatePost.vue"
import TrackersIndex from "@/views/tracker/TrackersIndex.vue"
import Ticket from "@/views/tracker/Ticket.vue"
import Tracker from "@/views/tracker/Tracker.vue"
import ChooseTicketKind from "@/views/tracker/ChooseTicketKind.vue"
import CreateTicket from "@/views/tracker/CreateTicket.vue"
import HubIndex from "@/views/hub/HubIndex.vue"
import UploadCreation from "@/views/hub/UploadCreation.vue"
import Creation from "@/views/hub/Creation.vue"
import Search from "@/views/Search.vue"
import Error from "@/views/Error.vue"

import CommentRedirect from "@/views/CommentRedirect"
import NotificationRedirect from "@/views/NotificationRedirect"

const idRegex = "-[A-Fa-f0-9]{8}"

type RouteMeta = {
    title?: string;
    footer?: false;
    loadCount?: number;
    usePathKey?: true;
    noSSR?: true;
}

const routes: Array<Omit<RouteRecordRaw, "meta"> & { meta?: RouteMeta, props?: any }> = [
    { path: "/", name: "home", component: Home, meta: { footer: false } },

    { path: "/newsletter",         name: "newsletter",     component: Newsletter },
    { path: "/wishlist",           name: "wishlist",       component: Wishlist },
    { path: "/changelog",          name: "siteChangelog",  component: SiteChangelog },
    { path: "/settings",           name: "siteSettings",   component: SiteSettings },
    { path: "/admin",              name: "admin",          component: Admin },

    { path: "/management",         name: "manpanelIndex",  component: Management,      beforeEnter: () => ({ name: "manpanel", params: { panel: "index" } })},
    { path: "/management/:panel",  name: "manpanel",       component: Management,      meta: { footer: false }, props: true },
    
    { path: "/@:name", name: "userPage", component: UserPage },

    { path: "/forums",                              name: "forums",   component: ForumsIndex,   meta: { loadCount: 1 } },
    { path: `/view/:id(frm-[A-Za-z0-9]{8})`,        name: "forum",    component: Forum,         meta: { loadCount: 1 } },
    { path: `/view/:id(pst${idRegex})/:name(.*)?`,  name: "post",     component: Post,          meta: { loadCount: 2, usePathKey: true } },
    { path: "/forum/:id/new",                       name: "newpost",  component: CreatePost },

    { path: "/tracker",           name: "trackers",   component: TrackersIndex },
    { path: "/tracker/:id(\\d+)", name: "ticket",     component: Ticket,           meta: { usePathKey: true, loadCount: 2 } },
    { path: "/tracker/:prod",     name: "tracker",    component: Tracker,          meta: { loadCount: 1 }, props: true },
    { path: "/tracker/:prod/new", name: "ticketKind", component: ChooseTicketKind, props: true },

    { path: "/tracker/:prod/new/:kind(issue|feature)", name: "newTicket", component: CreateTicket, props: true },

    { path: "/hub",                                 name: "hubIndex",  component: HubIndex,       meta: { loadCount: 1} },
    { path: "/hub/upload",                          name: "hubUpload", component: UploadCreation },
    { path: `/view/:id(ctn${idRegex})/:name(.*)?`,  name: "creation",  component: Creation,       meta: { loadCount: 2, usePathKey: true }, props: true },

    { path: `/view/:id(lwt${idRegex})`, name: "viewTicket", redirect: to => ({name: "ticket", params: { id: parseInt(/^lwt-(.+)$/.exec(to.params.id as string)![1], 16) }}) },
    { path: `/view/:id(cmt${idRegex})`, name: "comment", component: CommentRedirect },

    { path: "/search", name: "search", component: Search },

    // Old link
    { path: "/forum/1", name: "oldDevUpdates", redirect: { path: "/view/frm-devupdts" } },

    { path: "/notif/:id", name: "viewNotification", component: NotificationRedirect, props: true },

    { path: "/:pathMatch(.*)*", name: "notFound", component: Error, props: { code: 404 } },
    { path: "/error", name: "error", component: Error },
]

export default function(settings: Settings) {
    const router = createRouter({
        history: isSSR ? createMemoryHistory() : createWebHistory(),
        routes: routes as any,
        scrollBehavior: (isSSR || process.env.NODE_ENV === "test") ? undefined : async (to, from, savedPosition) => {
            const { emitter, loaded } = useEvents();

            var loadCount = Number(to.meta.loadCount);
            var pos = savedPosition ?? { left: 0, top: 0 };
            pos.behavior = "smooth";

            // Only "immediately" scroll to top if:
            //   The stored position is Y=0, and
            //   There is no hash in the URL, and
            //   !(There used to be a hash in the URL but now there isn't)
            if (pos.top === 0 && !to.hash && !(!to.hash && from.hash)) {
                return new Promise(resolve => setTimeout(() => resolve({ left: 0, top: 0 }), 100));
            }

            return new Promise((resolve) => {
                //TODO: Add timeout and reject

                if (loadCount && settings.autoScroll.value) {
                    var loadedCount = 0;
                    const callback = () => {
                        if (++loadedCount == loadCount) {
                            emitter.off(loaded.type, callback);

                            // Only scroll down if the user hasn't manually scrolled
                            if (window.scrollY === 0 || to.hash) {
                                setTimeout(() => resolve(to.hash ? { el: to.hash } : pos), 100);
                            }
                        }
                    };

                    emitter.on(loaded.type, callback);
                } else {
                    resolve(pos);
                }
            });
        }
    })

    return router;
}
