<template lang="pug">
.dropdown
    button.btn.btn-primary.dropdown-toggle.w-100(v-dropdown @click="loadForums")
        icon.me-2(icon="people-carry" fixed-width)
        | Move to forum
    ul.dropdown-menu
        template(v-for="forum in forums")
            li(v-if="forum.id != post.forumid")
                a.dropdown-item( href="#" @click.prevent="moveTo(forum)") {{forum.title}}
</template>

<script lang="ts" setup>
import { useMutation, useQuery } from "villus";
import { ref } from "vue";

import { GetForumsDocument, MovePostDocument } from "@/graphql";
import type { Forum, GetForumsQuery, MovePostMutation, MovePostMutationVariables, Post } from "@/graphql";

const props = defineProps<{ post: Pick<Post, "id" | "forumid"> }>();
const emit = defineEmits(["moved"])

const forums = ref<Pick<Forum, "id" | "title">[]>();
const { execute: executeForums } = useQuery<GetForumsQuery>({ query: GetForumsDocument });
const { execute: executeMove } = useMutation<MovePostMutation, MovePostMutationVariables>(MovePostDocument);

async function loadForums() {
    if (forums.value) {
        return;
    }

    const { data } = await executeForums();
    assert(data, "forums data");

    forums.value = data.forums;
}

async function moveTo(forum: Pick<Forum, "id" | "title">) {
    if (!confirm(`Are you sure you want to move this post to "${forum.title}"?`)) {
        return;
    }

    const { error } = await executeMove({
        id: props.post.id,
        newForum: forum.id
    });
    if (!error) {
        emit("moved", forum);
    }
}
</script>