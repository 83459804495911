<template lang="pug">
form(@submit.prevent="save")
    .input-group.mb-2
        input.form-control(type="text" placeholder="Email address" v-model="address" aria-label="Recipient's email address" :disabled="isFetching")
        .input-group-append
            button.btn.btn-primary(type="submit" :disabled="isFetching") Subscribe
    
    .form-check
        input.form-check-input(type="checkbox" id="checkMajor" checked disabled)
        label.form-check-label(for="checkMajor") Receive major development updates (less than 1 email per month)
        
    .form-check
        input.form-check-input(type="checkbox" id="checkMinor" v-model="minor" :disabled="isFetching")
        label.form-check-label(for="checkMinor") Also receive minor development updates (about 1 email per week)

    template(v-if="isDone && !isFetching")
        span.text-success(v-if="!error") Changes saved successfully
        span.text-danger(v-else) There was an error saving your subscription
</template>

<script lang="ts">
import { useMutation } from 'villus';
import { defineComponent, ref } from 'vue'

import { UpdateNewsletterAnonDocument, UpdateNewsletterAnonMutation, UpdateNewsletterAnonMutationVariables } from "@/graphql"

export default defineComponent({
    name: "NewsletterAnonymous",

    setup() {
        const minor = ref(false);
        const address = ref("");

        const { execute, error, isDone, isFetching } = useMutation<UpdateNewsletterAnonMutation, UpdateNewsletterAnonMutationVariables>(UpdateNewsletterAnonDocument);

        async function save() {
            await execute({
                address: address.value,
                minor: minor.value
            })
        }

        return { minor, address, save, error, isDone, isFetching }
    }
})
</script>