import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return _withDirectives((_openBlock(), _createElementBlock("a", {
    href: $setup.endpoints.main('feed/' + $props.objectId)
  }, [
    _createElementVNode("img", {
      src: $setup.endpoints.asset('/img/feed.png'),
      alt: "RSS icon"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1)), [
    [
      _directive_tooltip,
      'RSS feed (For Atom feed add "?type=atom")',
      void 0,
      { delay: true }
    ]
  ])
}