import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "dropdown-menu" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")
  const _directive_dropdown = _resolveDirective("dropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      class: "btn btn-primary dropdown-toggle w-100",
      onClick: $setup.loadForums
    }, [
      _createVNode(_component_icon, {
        class: "me-2",
        icon: "people-carry",
        "fixed-width": "fixed-width"
      }),
      _createTextVNode("Move to forum")
    ])), [
      [_directive_dropdown]
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.forums, (forum) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (forum.id != $props.post.forumid)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                _createElementVNode("a", {
                  class: "dropdown-item",
                  href: "#",
                  onClick: _withModifiers($event => ($setup.moveTo(forum)), ["prevent"])
                }, _toDisplayString(forum.title), 9, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ])
  ]))
}