<template lang="pug">
Pagination(:fetch-items="fetchItems" use-route)
    template(#default="{ item }")
        .card.mb-3(v-if="item")
            .card-body
                .post(v-if="item.__typename === 'Post'")
                    .clearfix.mb-2
                        | 📜 
                        router-link.title.stretched-link(:to="'/view/' + item.id") {{item.title}}
                        span.text-muted  on 
                        router-link.over(:to="'/view/' + item.forum?.id") {{item.forum?.title}}
                        time-ago.pointer.float-right(:time="item.createdat")
                    .body(v-html="item.renderedbody")

                .comment(v-else-if="item.__typename === 'Comment'")
                    .clearfix.mb-2
                        | 💬 
                        router-link.stretched-link(:to="'/view/' + item.id") Commented
                        span.text-muted  on 
                        router-link.title.over(:to="'/view/' + item.objectid") {{item.object.title}}
                        time-ago.pointer.float-right(:time="item.createdat")
                    .body(v-html="item.renderedbody")
</template>

<script lang="ts">
import { GetUserHistoryDocument, GetUserHistoryQuery, PageOptions } from "@/graphql";
import { useQuery } from "villus";
import { defineComponent } from "vue"

import Pagination from "@/components/widgets/Pagination.vue";

export default defineComponent({
    components: {
        Pagination
    },
    props: {
        userId: {
            type: Number,
            required: true
        }
    },
    async setup(props) {
        const getItems = useQuery<GetUserHistoryQuery>({
            query: GetUserHistoryDocument,
            fetchOnMount: false
        })

        async function fetchItems(page: PageOptions) {
            const { error, data } = await getItems.execute({
                variables: {
                    page: {
                        author: props.userId,
                        ...page
                    }
                }
            });
            
            if (!data) {
                return { error }
            }

            const all: (GetUserHistoryQuery[Exclude<keyof GetUserHistoryQuery, "__typename">])[0][] = [];
            all.push(...data!.posts.filter(o => !!o).map(o => (o.__typename = "Post", o)));
            all.push(...data!.comments.filter(o => !!o).map(o => (o.__typename = "Comment", o)));

            all.sort((a, b) => b.createdat - a.createdat);

            return { data: all };
        }

        return { fetchItems }
    }
})
</script>

<style lang="scss" scoped>
@import "@/styles/style";

.over {
    z-index: 5;
    position: relative;
}

.title {
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: none;
}

.body {
    overflow: hidden;
    max-height: 8em;
    margin-bottom: -1rem; // Negate the margin added by markdown paragraph

    &::after {
        content: " ";
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;

        @include dark() {
            background: linear-gradient(to top, rgba(48,48,48,1), rgba(0,0,0,0));
        }
        @include light() {
            background: linear-gradient(to top, rgba(255,255,255,1), rgba(0,0,0,0));
        }
    }
}
</style>