import { GetNotificationObjectDocument } from "@/graphql";
import { useMeta } from "@/utils";
import { useQuery } from "villus";
import { defineComponent, h } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    async setup(props) {
        const router = useRouter();

        useMeta({ robots: "noindex" });

        const { data, error } = await useQuery({
            query: GetNotificationObjectDocument,
            variables: {
                id: Number(props.id)
            }
        });

        if (error.value) {
            //TODO: Improve error handling for not-found codes
            throw error.value;
        }

        router.replace(`/view/${data.value?.notification.causedByObject}`);

        return () => h("spinner");
    }
})