<template lang="pug">
p.display-5.text-center Newsletter
hr

.card.mt-2
    .card-header Send newsletter
    .card-body
        v-form(:form="sendForm")
            .row.mb-3
                label.col-sm-2.col-form-label Title
                .col-sm
                    v-field.form-control(name="title" :show-error="false")
            .row.mb-3
                label.col-sm-2.col-form-label URL
                .col-sm
                    v-field.form-control(name="url" :show-error="false")
            .row.mb-3
                label.col-sm-2.col-form-label List Level
                .col-sm
                    v-field.form-control(name="list" is="select" :show-error="false")
                        option(value="-1" disabled) Choose a list
                        option(value="0") Test
                        option(value="1") Major
                        option(value="2") Minor
            .row.mb-3
                label.col-sm-2.col-form-label Body
                .col-sm
                    v-field.form-control(name="body" is="textarea" :show-error="false")
            .row.mb-3
                .col-sm-2
                .col-sm
                    .form-check.form-switch
                        input.form-check-input#sendDiscord(name="sendDiscord" type="checkbox" v-model="sendForm.data.sendDiscord")
                        label.form-check-label(for="sendDiscord") Send to Discord

            v-error.d-inline(is="span")
            span.text-success(v-if="success") Newsletter sent!
            v-submit.float-end
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useMutation } from "villus";
import * as yup from "yup"
import { useRoute } from "vue-router";

import type { SendNewsletterMutation, SendNewsletterMutationVariables } from "@/graphql";
import { SendNewsletterDocument } from "@/graphql";
import { VForm, VField, VSubmit, VError, Form } from "@/utils";

const route = useRoute();

const { execute } = useMutation<SendNewsletterMutation, SendNewsletterMutationVariables>(SendNewsletterDocument);
const success = ref(false);

const sendForm = new Form(yup.object({
    title: yup.string().label("Title (email subject)").default("").required(),
    url: yup.string().label("URL").default("").url().required(),
    body: yup.string().label("Body").default("").required(),
    list: yup.number().oneOf([0, 1, 2]).default(-1),
    sendDiscord: yup.bool().default(false).required()
}), async function() {
    success.value = false;

    const { error } = await execute({
        input: sendForm.copyData()
    });

    if (error) {
        throw error;
    }

    sendForm.reset();
    success.value = true;
});

if (typeof route.query.title === "string") {
    sendForm.data.title = route.query.title;
}
if (typeof route.query.url === "string") {
    sendForm.data.url = route.query.url;
}
if (route.query.discord === "1") {
    sendForm.data.sendDiscord = true;
}
</script>