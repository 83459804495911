import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98e55b60"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "bg-dark text-light px-3 py-4",
  style: {"transform":"translateY(100%)"}
}
const _hoisted_2 = { class: "notice" }
const _hoisted_3 = { class: "links" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, "© 2019-" + _toDisplayString(new Date().getFullYear()) + " Mouse Hat Games", 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: "/changelog" }, {
        default: _withCtx(() => [
          _createTextVNode("Site changelog")
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/settings" }, {
        default: _withCtx(() => [
          _createTextVNode("Site settings")
        ]),
        _: 1
      })
    ])
  ]))
}