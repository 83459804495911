<template lang="pug">
router-link(:to="'/@' + user?.username")
    | @{{user.username}}
    UserFlair.mx-1(:flair="user.flair")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { User } from "@/graphql";
import { typedProp } from "@/utils"

import UserFlair from "./UserFlair.vue"

export default defineComponent({
    name: "UserLink",
    components: {
        UserFlair
    },
    props: {
        user: typedProp<Pick<User, "username" | "flair">>(o => o.username, true)
    }
})
</script>