<template lang="pug">
TitleTag(:title="getTickets.data.value?.product.prettyName + ' tracker'")
CanonicalRouteTag

.container
    ol.breadcrumb
        li.breadcrumb-item
            router-link(to="/tracker") Trackers
        li.breadcrumb-item.active
            span(aria-current="location") {{ getTickets.data.value?.product.prettyName ?? prod }}

    .d-flex
        h1.display-4 Tracked tickets in: {{ getTickets.data.value?.product.prettyName.toLowerCase() || prod }}
        IfFlag(:flag="`can-create-ticket-${prod}`")
            router-link.btn.btn-success.ms-auto.align-self-end.mb-3(v-if="authed" :to="`/tracker/${prod}/new`") Create ticket

    hr.mt-0
    form.mb-3(@submit.prevent="updateQuery")
        .row
            span.col-sm-4.col-form-label.text-end(for="trackerQuery")
                | Advanced query
                span.badge.bg-light.mx-1.pointer(@click="showExplanation = !showExplanation") ?
                | :
            .col-sm-7
                input.form-control#trackerQuery(type="text" v-model="queryString")
                span.text-danger(v-if="queryError") Invalid query: {{ queryError }}
                .card(v-if="showExplanation")
                    .card-body
                        | You can narrow down the results using any the following qualifiers:
                        br
                        ul
                            li
                                code tag:mytag
                                |  or 
                                code tag:"some tag"
                            li
                                code is:issue
                                |  or 
                                code is:feature
                            li
                                code is:open
                                |  or 
                                code is:closed
                            li
                                code title:"some text"
                            li
                                code body:"some text"

                        | These qualifiers can be combined using the 
                        code |
                        |  operator to build a logical expression. Qualifiers separated by a space or a 
                        code &amp;
                        |  will be combined with an AND operator.

                        br
                        br

                        | Sample queries:
                        div
                            code is:issue tag:linux tag:"area: gameplay"
                            | : find gameplay issues related to Linux.
                        div
                            code is:feature (tag:duplicate | tag:"area: modding")
                            | : find duplicate or modding-related feature requests
            .col-sm-1
                button.btn.btn-primary.w-100 Submit

    Pagination(:fetch-items="fetchItems" use-route emit-load)
        template(#default="slot")
            Listing(v-if="slot" :ticket="slot.item")
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import type { PropType } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useQuery } from "villus"

import type { GetTicketsListingQuery, GetTicketsListingQueryVariables, PageOptions } from "@/graphql"
import { GetTicketsListingDocument } from "@/graphql"
import { CanonicalRouteTag, TitleTag, watchError } from "@/utils";
import { useAuth } from "@/mixins";

import Listing from "@/components/tracker/TicketListing.vue";
import Pagination from "@/components/widgets/Pagination.vue";

const props = defineProps({
    prod: {
        type: String as PropType<"game" | "site">,
        required: true
    }
})

const { authed } = useAuth();

const route = useRoute();
const router = useRouter();
const routeQueryString = computed(() => typeof route.query.q === "string" ? route.query.q : "");

const showExplanation = ref(false);
const queryError = ref("");

const queryString = ref("");
watch(routeQueryString, o => queryString.value = o, { immediate: true });

const getTickets = useQuery<GetTicketsListingQuery, GetTicketsListingQueryVariables>({
    query: GetTicketsListingDocument,
    fetchOnMount: false
})
watchError(getTickets.error);

function updateQuery() {
    router.push({
        ...router.currentRoute.value,
        query: {
            q: queryString.value
        }
    })
}

const fetchItems = async (page: PageOptions) => {
    queryError.value = "";

    const { error, data } = await getTickets.execute({
        variables: {
            page,
            product: props.prod,
            query: routeQueryString.value
        }
    });

    if (error) {
        const parseError = /parse query: (.+)$/.exec(error.message);
        if (parseError) {
            queryError.value = parseError[1];
            return { data: [] };
        }

        return { error };
    }

    return { data: data!.tickets };
};
</script>
