import { useEvents } from '@/mixins';
import { ClientPlugin } from 'villus';

type CacheEntry = {
    data: any;
    fetchedAt: number; //Unix timestamp milliseconds
}

export type CacheData = Record<string, CacheEntry>;

declare global {
    var cache: CacheData | string | undefined;
}

const maxCacheEntryAge = 5 * 1000 * 60; // Milliseconds

export function cache(init: CacheData = {}): { plugin: ClientPlugin, cache: CacheData } {
    let cacheObj: CacheData = init;

    function clearCache() {
        cacheObj = {};
    }

    useEvents().clearCache.on(clearCache);

    return {
        cache: cacheObj,
        plugin({ afterQuery, useResult, operation }) {
            if (operation.type === "mutation") {
                clearCache();
            }

            if (operation.type !== "query" || operation.cachePolicy === "network-only") {
                return;
            }

            afterQuery(result => {
                cacheObj[operation.key] = { data: result, fetchedAt: new Date().getTime() };
            });

            const cached = cacheObj[operation.key];
            if (operation.cachePolicy === "cache-only") {
                return useResult(cached?.data || { data: null, error: null }, true);
            }

            if (cached) {
                if (new Date().getTime() - cached.fetchedAt < maxCacheEntryAge) {
                    return useResult(cached?.data, operation.cachePolicy === "cache-first");
                }

                delete cacheObj[operation.key];
            }
        }
    }
}