<template lang="pug">
div
    a.btn.btn-info(@click="collapse = !collapse" data-bs-toggle="button") Add tags

    .mt-2(:class="{collapse}")
        span.badge.bg-warning.mb-1.pointer.user-select-none(data-bs-toggle="modal" data-bs-target="#createTagModal") Create new tag

        .me-1(v-for="tag in data?.allTicketTags")
            Tag.pointer(v-if="!ticket.tags.some(o => o.name == tag.name)" :tag="tag" @click="add(tag)")

        CreateTagModal(:all-tags="data?.allTicketTags" :currentProduct="ticket.product.id" @addTag="add")
</template>

<script lang="ts">
import { GetAllTicketTagsDocument, GetAllTicketTagsQuery, GetAllTicketTagsQueryVariables, SetTicketTagsDocument, SetTicketTagsMutation, SetTicketTagsMutationVariables, Ticket, TicketTag } from "@/graphql"
import { defineComponent, PropType, ref } from "vue"
import { useMutation, useQuery } from "villus"

import Tag from "@/components/tracker/TicketTag.vue"
import CreateTagModal from "@/components/tracker/CreateTagModal.vue"

export default defineComponent({
    components: { Tag, CreateTagModal },
    props: {
        ticket: {
            type: Object as PropType<Ticket>,
            required: true
        }
    },
    async setup(props) {
        const collapse = ref(true);

        const { execute } = useMutation<SetTicketTagsMutation, SetTicketTagsMutationVariables>(SetTicketTagsDocument);

        const { data } = await useQuery<GetAllTicketTagsQuery, GetAllTicketTagsQueryVariables>({
            query: GetAllTicketTagsDocument,
            variables: {
                product: props.ticket.product.id
            }
        });
        data.value?.allTicketTags.sort((a, b) => a.name.localeCompare(b.name))

        async function add(tag: TicketTag) {
            props.ticket.tags.push(tag);
            props.ticket.tags.sort((a, b) => a.name.localeCompare(b.name));

            await execute({
                id: props.ticket.number,
                tags: props.ticket.tags.map(o => o.name)
            })
        }

        return { add, data, collapse }
    }
})
</script>