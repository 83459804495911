<template lang="pug">
p.display-5.text-center Management panel
hr

.d-flex.flex-row.flex-wrap
    Gauge(title="User count" :value="data?.allUsers.length" bg="primary")
    Gauge.ms-2(title="Post count" :value="data?.postCount" bg="info")
</template>

<script lang="ts" setup>
import Gauge from "@/components/manpanel/Gauge.vue";
import { useQuery } from "villus";

import type { GetManagementStatsQuery } from "@/graphql";
import { GetManagementStatsDocument } from "@/graphql";

const { data } = useQuery<GetManagementStatsQuery>({ query: GetManagementStatsDocument });
</script>;