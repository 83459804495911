import endpoints from "@/endpoints";
import { PropType, Ref, computed, isRef, watch } from "vue";
import { App, defineComponent, inject, InjectionKey, onUnmounted, reactive, toRef, toRefs, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { logDebug } from "./log";

const KEY: InjectionKey<Head> = Symbol("head");

export type Link = {
    rel: string;
    type?: string;
    title?: string;
}

export function useHead(): Head {
    const head = inject(KEY);
    if (!head) {
        throw new Error("head not found");
    }
    return head;
}

export function useMeta(meta: Record<string, string | undefined>, head?: Head) {
    const providedHead = !!head;
    head ??= useHead();

    const refs = toRefs(meta);

    const oldMeta = new Map<string, string | null | undefined>();

    for (const name in refs) {
        const ref = refs[name];

        watchEffect(() => {
            if (ref.value === undefined) {
                return;
            }

            oldMeta.set(name, head!.setMeta(name, ref.value));
        });
    }

    if (!providedHead) {
        onUnmounted(() => {
            for (const key of oldMeta.keys()) {
                const value = oldMeta.get(key);

                if (value == null)
                    head!.removeMeta(key);
                else
                    head!.setMeta(key, value);
            }
        });
    }
}

export function useLink(href: string, link: Link) {
    const head = useHead();

    watchEffect(() => head.setLink(href, link));

    onUnmounted(() => head.removeLink(href, link));
}

export function useTitle(title: string | Ref<string>) {
    const head = useHead();
    let oldTitle: string | null;

    oldTitle = head.setTitle(isRef(title) ? title.value : title);

    if (isRef(title))
        watch(title, title => head.setTitle(title));
}

export abstract class Head {
    /**
     * Sets the meta value and returns the old value, or null if it doesn't exist.
     */
    abstract setMeta(name: string, content: string): string | null;
    abstract removeMeta(name: string): void;

    abstract setLink(href: string, link: Link): Link | null;
    abstract removeLink(href: string, link: Link): void;

    abstract setTitle(title: string): string | null;

    install(app: App) {
        app.provide(KEY, this);
    }
}

export class NoopHead extends Head {
    setMeta() { return null }
    removeMeta() { }
    setLink() { return null }
    removeLink() { }
    setTitle() { return ""; }
}

export class DomHead extends Head {
    private getMeta(name: string) {
        return document.querySelector(`meta[name="${name}"],meta[property="${name}"]`) as HTMLMetaElement | null;
    }
    private getLink(link: Link | string) {
        if (typeof link === "string")
            return document.querySelector(`link[href="${link}"]`) as HTMLLinkElement | null;
        
        let selector = `link[rel="${link.rel}"]`;

        if (link.title !== undefined)
            selector += `[title="${link.title}"]`
        if (link.type !== undefined)
            selector += `[type="${link.type}"]`
        
        return document.querySelector(selector) as HTMLLinkElement | null;
    }

    setMeta(name: string, content: string) {
        let el = this.getMeta(name);
        const oldContent = el?.content;

        logDebug(`set meta name=${name} content=${content} oldContent=${oldContent}`);

        if (!el) {
            const lastMeta = document.querySelector("meta:last-of-type");

            el = document.createElement("meta");
            document.head.insertBefore(el, lastMeta?.nextSibling || null);
        }

        if (name.startsWith("og:")) {
            el.setAttribute("property", name);
        } else {
            el.name = name;
        }
        el.content = content;

        return oldContent ?? null;
    }

    removeMeta(name: string) {
        logDebug(`remove meta name=${name}`);

        this.getMeta(name)?.remove();
    }

    setLink(href: string, link: Link) {
        let el = this.getLink(link);
        const oldContent = el ? <Link>{
            rel: el.rel,
            type: el.type,
            title: el.title
        } : null;

        if (oldContent && oldContent.rel == link.rel && oldContent.type == link.type && oldContent.title == link.title) {
            // The same link element already exists, likely because of SSR
            return null;
        }

        if (!el) {
            const lastLink = document.querySelector("link:last-of-type");

            el = document.createElement("link");
            el.href = href;
            document.head.insertBefore(el, lastLink?.nextSibling || null);
        }

        el.rel = link.rel ?? "";

        if (link.title === undefined)
            el.removeAttribute("title");
        else
            el.title = link.title;

        if (link.type === undefined)
            el.removeAttribute("type");
        else
            el.type = link.type;

        return oldContent;
    }

    removeLink(href: string, link: Link): void {
        this.getLink(link)?.remove();
    }

    setTitle(title: string) {
        const oldTitle = document.title;

        document.title = title;

        return oldTitle == title ? null : oldTitle;
    }
}

export class HtmlHead extends Head {
    private metas = new Map<string, string>();
    private links = new Map<string, Link>();
    private title: string | null = null;

    get html() {
        return Array
            .from(this.metas)
            .map(([name, content]) => `<meta ${name.startsWith("og:") ? "property" : "name"}="${name}" content="${content}" />`)
            .join("") +
            Array.from(this.links)
                .map(([href, link]) => this.linkToString(href, link))
                .join("") +
            `<title>${this.title}</title>`;
    }

    private linkToString(href: string, link: Link) {
        let el = `<link href="${href}"`;

        if (link.rel !== undefined)
            el += ` rel="${link.rel}"`;
        if (link.title !== undefined)
            el += ` title="${link.title}"`;
        if (link.type !== undefined)
            el += ` type="${link.type}"`;

        return el + " />"
    }

    setMeta(name: string, content: any) {
        const oldContent = this.metas.get(name);

        this.metas.set(name, content);

        return oldContent ?? null;
    }

    removeMeta(name: string) {
        this.metas.delete(name);
    }

    setLink(href: string, link: Link) {
        const oldContent = this.links.get(href);

        this.links.set(href, link);

        return oldContent ?? null;
    }

    removeLink(href: string): void {
        this.links.delete(href);
    }

    setTitle(title: string) {
        const oldTitle = this.title;
        this.title = title;
        return oldTitle;
    }
}

export const MetaTag = defineComponent({
    props: {
        name: {
            type: String
        },
        names: {
            type: Array as PropType<string[]>,
        },
        content: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const content = toRef(props, "content");

        let meta: Record<string, any>;
        if (props.name) {
            meta = {
                [props.name]: content
            };
        } else if (props.names) {
            meta = {};

            for (const name of props.names) {
                meta[name] = content;
            }
        } else {
            throw new Error("No meta name");
        }

        useMeta(reactive(meta) as any);

        return () => { };
    }
});

export const FeedTag = defineComponent({
    props: {
        objectId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        useLink(endpoints.main(`/feed/${props.objectId}?type=rss`), {
            rel: "alternate",
            type: "application/rss+xml",
            title: "RSS Feed",
        });
        useLink(endpoints.main(`/feed/${props.objectId}?type=atom`), {
            rel: "alternate",
            type: "application/atom+xml",
            title: "Atom Feed",
        });

        return () => { };
    }
});

export const TitleTag = defineComponent({
    props: {
        title: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const title = computed(() => props.title ? props.title + " - Logic World" : "Logic World");
        useTitle(title);

        return () => { };
    }
})

export const LinkTag = defineComponent({
    props: {
        href: {
            type: String,
            required: true
        },
        rel: {
            type: String,
            required: true
        },
        type: String,
        title: String
    },
    setup(props) {
        useLink(props.href, props);

        return () => { };
    }
})

export const CanonicalRouteTag = defineComponent({
    setup() {
        const route = useRoute();

        useLink(endpoints.main(route.fullPath), { rel: "canonical" });

        return () => { };
    }
})