import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-715f54b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card creation" }
const _hoisted_2 = { class: "controls card-img-top" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "overlay d-flex justify-content-center align-items-center px-4 py-2" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = { class: "d-flex flex-row" }
const _hoisted_8 = { class: "text-muted" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")
  const _component_router_link = _resolveComponent("router-link")
  const _component_user_link = _resolveComponent("user-link")
  const _component_time_ago = _resolveComponent("time-ago")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "w-100 thumbnail",
        src: $props.item.thumbnail,
        width: "300",
        height: "169"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        class: _normalizeClass(["indicator", [$props.item.kind.toLowerCase()]])
      }, [
        ($props.item.kind == $setup.HubCreationKind.World)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              icon: ['far', 'globe']
            }))
          : _createCommentVNode("", true),
        ($props.item.kind == $setup.HubCreationKind.Board)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 1,
              icon: ['fas', 'cubes']
            }))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["OpenInGameButton"], {
          class: "download",
          creationId: $props.item.id
        }, null, 8, ["creationId"])
      ]),
      _createVNode(_component_router_link, {
        class: "stretched-link",
        to: {name: 'creation', params: {id: $props.item.id}}
      }, null, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        class: "text-decoration-none",
        to: {name: 'creation', params: {id: $props.item.id}}
      }, {
        default: _withCtx(() => [
          _createElementVNode("h5", _hoisted_6, _toDisplayString($props.item.title), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, [
          _createTextVNode("by "),
          _createVNode(_component_user_link, {
            class: "author",
            user: $props.item.author
          }, null, 8, ["user"])
        ]),
        _createVNode(_component_time_ago, {
          class: "ms-auto text-muted",
          time: $props.item.createdAt
        }, null, 8, ["time"])
      ])
    ])
  ]))
}