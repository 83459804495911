/// <reference path="analytics.d.ts" />

import { Router } from "vue-router";

let enabled = false;

export function setEnabled(e: boolean) {
    enabled = e && !!window.Matomo;
}

export function trackSearch(query: string) {
    if (enabled) {
        const tracker = window.Matomo.getAsyncTracker();

        tracker.trackSiteSearch(query, undefined, undefined, undefined);
    }
}

export function watchRouter(router: Router) {
    if (!enabled)
        return;

    const tracker = window.Matomo.getAsyncTracker();

    router.afterEach(to => {
        tracker.setCustomUrl(to.path);
        tracker.trackPageView();
    });
}
