<template lang="pug">
span.badge.rounded-pill.user-select-none(:style="style")
    | {{tag.name}}
    slot
</template>

<script lang="ts">
import { TicketTag } from "@/graphql"
import { computed, defineComponent, PropType } from "vue"
import tinycolor from "tinycolor2"

const foregroundColors = [...Array(5).keys()].map(o => tinycolor({ h: 0, s: 0, l: o / 4 }))

export default defineComponent({
    props: {
        tag: {
            type: Object as PropType<TicketTag>,
            required: true
        }
    },
    setup(props) {
        const style = computed(() => ({
            backgroundColor: props.tag.color.startsWith('#') ? props.tag.color : 'unset',
            color: tinycolor.mostReadable(props.tag.color, foregroundColors).toHexString()
        }));

        return { style }
    }
})
</script>