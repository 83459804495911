import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, Suspense as _Suspense, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea994a51"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href", "media"]
const _hoisted_3 = { class: "well flex-grow-1" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_spinner = _resolveComponent("spinner")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["theme d-flex flex-column", [$setup.theme]])
  }, [
    (($setup.theme === 'auto') || $setup.theme === 'light')
      ? (_openBlock(), _createElementBlock("link", {
          key: 0,
          rel: "stylesheet",
          href: 'https://cdn.jsdelivr.net/npm/bootswatch@5.0.2/dist/cosmo/bootstrap.min.css',
          media: "screen"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (($setup.theme === 'auto') || $setup.theme === 'dark')
      ? (_openBlock(), _createElementBlock("link", {
          key: 1,
          rel: "stylesheet",
          href: 'https://cdn.jsdelivr.net/npm/bootswatch@5.0.2/dist/darkly/bootstrap.min.css',
          media: $setup.theme === 'dark' ? 'screen' : 'screen and (prefers-color-scheme: dark)'
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode($setup["Navbar"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["ToastView"]),
      ($setup.errorCode)
        ? (_openBlock(), _createBlock($setup["ErrorPage"], {
            key: 0,
            code: $setup.errorCode
          }, null, 8, ["code"]))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
            default: _withCtx(({ Component, route }) => [
              (_openBlock(), _createBlock(_Suspense, {
                onPending: $setup.startLoading,
                onResolve: _cache[0] || (_cache[0] = $event => ($setup.loading = false)),
                timeout: 0
              }, {
                fallback: _withCtx(() => [
                  _createVNode(_component_spinner)
                ]),
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: (route.meta.usePathKey ? route.path : undefined) + JSON.stringify(route.query)
                  }))
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }))
    ]),
    ($setup.showFooter)
      ? (_openBlock(), _createBlock($setup["Footer"], { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode($setup["NotificationsModal"]),
    _createVNode($setup["OpenInGameModal"])
  ], 2))
}