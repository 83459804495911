export default {
    main(path = "") {
        return new URL(path, process.env.ENDPOINT_MAIN).toString(); 
    },
    auth(path: string, redirect?: string) {
        const url = new URL(path, process.env.ENDPOINT_AUTH);
        if (redirect)
            url.searchParams.append("r", redirect);
        return url.toString();
    },
    asset(path: string) {
        return new URL(path, process.env.ENDPOINT_ASSETS).toString();
    }
}