import { FlagNames, useAuth } from '@/mixins';
import { CombinedError } from 'villus';
import { Prop, PropType, Ref, watch, computed } from 'vue';
import { Head, useMeta } from './head';

export * from "./head"
export * from "./forms"
export * from "./upload"
export * from "./log"

export function typedProp<T>(getter?: ((obj: T) => any) | false, required?: boolean): Prop<T> {
    if (getter === undefined)
        getter = o => (<any>o).id;

    return {
        type: Object as PropType<T>,
        validator: process.env.NODE_ENV != "development" ? undefined : (obj: T): boolean => {
            return getter === false ? true : (getter!(obj) !== undefined);
        },
        required
    };
}

export function isGraphQLError(err: any): err is CombinedError {
    return !!err.isGraphQLError;
}

export function watchError(err: Ref<CombinedError | null | undefined>) {
    watch(err, o => {
        if (o) {
            throw o;
        }
    }, { immediate: true })
}

type FlagsAll<S extends string> = S extends `${infer T}-all` ? T : never;
type FlagsOwn<S extends string> = S extends `${infer T}-own` ? T : never;
type FlagsAllOwn = FlagsAll<FlagNames> & FlagsOwn<FlagNames>

export function canAllOrOwn(flagBase: FlagsAllOwn, authorId: () => number | null | undefined): Ref<boolean> {
    const { hasFlag, currentUser } = useAuth();
    return computed(() => hasFlag(`${flagBase}-all`) || (hasFlag(`${flagBase}-own`) && !!currentUser && currentUser.id === authorId()));
}

// https://stackoverflow.com/a/34278578
export function typeInTextarea(newText: string, el: { selectionStart: number, selectionEnd: number, value: string, focus: () => void }) {
    const start = el.selectionStart!
    const end = el.selectionEnd!
    const text = el.value
    const before = text.substring(0, start)
    const after = text.substring(end, text.length)
    el.value = (before + newText + after)
    el.selectionStart = el.selectionEnd = start + newText.length
    el.focus()

    return el.value;
}

declare global {
    interface String {
        isEmpty(): boolean;
    }

    function assert<T>(val: T, name: string): asserts val is Exclude<T, null | undefined>
}

function assert(val: any, name: string) {
    if (val === null || val === undefined) {
        throw new Error(`${name} is missing`);
    }
}

globalThis.assert = assert;

String.prototype.isEmpty = function () {
    return /^\s*$/.test(this as string);
}

export class UnauthorizedError extends Error { }
export function requireFlag(flag: FlagNames) {
    const { hasFlag } = useAuth();

    if (!hasFlag(flag)) {
        throw new UnauthorizedError("You cannot access this page");
    }
}

export function stub(text: string | null | undefined, maxLength = 100) {
    if (!text) {
        return "";
    }

    let stub = text
        .replace(/<style[^>]*>.*<\/style>/gm, '')
        .replace(/<script[^>]*>.*<\/script>/gm, '') // Remove script tags and content
        .replace(/<[^>]+>/gm, '') // Remove all opening, closing and orphan HTML tags
        .replace(/([\r\n]+ +)+/gm, '') // Remove leading spaces and repeated CR/LF
        .replace(/\s+/g, " ")
        .trim();
    
    if (stub.length > maxLength) {
        stub = stub.substring(0, maxLength).trimEnd() + "…";
    }

    return stub;
}

export interface Thenable<T> {
    then(cb: (val: T) => void): Promise<unknown>
}
