<template lang="pug">
button.btn(type="button" :class="[modelValue == value ? 'btn-primary' : 'btn-secondary']" @click="$emit('update:modelValue', value)")
    slot
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    emits: ["update:modelValue"],
    props: ["modelValue", "value"]
})
</script>