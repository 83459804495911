import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-sm-row flex-column align-items-sm-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["LikeButton"], {
      objectid: $props.objectId,
      rating: $props.rating
    }, null, 8, ["objectid", "rating"]),
    _createVNode($setup["FeedIcon"], {
      class: "ms-sm-auto mt-2 mt-sm-0 me-2",
      "object-id": $props.objectId
    }, null, 8, ["object-id"]),
    ($props.showShareUrl)
      ? (_openBlock(), _createBlock($setup["ShareLink"], {
          key: 0,
          class: "mt-2 mt-sm-0",
          obj: $props.objectId
        }, null, 8, ["obj"]))
      : _createCommentVNode("", true)
  ]))
}