<template lang="pug">
slot(v-if="hasFlag(flag)")
</template>

<script lang="ts" setup>
import type { PropType } from "vue";
import type { FlagNames } from "@/mixins";
import { useAuth } from "@/mixins";

defineProps({
    flag: {
        type: String as PropType<FlagNames>,
        required: true
    }
});

const { hasFlag } = useAuth();
</script>