import { useEvents } from "@/mixins";
import { CombinedError } from "villus";
import { defineComponent, PropType, watch } from "vue"

export default defineComponent({
    props: {
        data: Object as PropType<{
            error?: CombinedError,
            isDone: boolean
        }>,
        title: String
    },
    setup(props) {
        const { loaded } = useEvents();
        
        watch(() => props.data?.error, o => {
            if (o) {
                console.error(o);
                throw o;
            }
        }, { immediate: true });

        watch(() => props.data?.isDone, o => {
            if (o) loaded();
        }, { immediate: true } );

        if (!isSSR) {
            watch(() => props.title, o => o && (document.title = o + " - Logic World"), { immediate: true });
        }

        return () => {}
    }
})