import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61c6c28d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  src: "https://assets.logicworld.net/img/spinner.gif",
  alt: "Loading..."
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("img", _hoisted_1))
}