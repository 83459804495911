<template lang="pug">
.container(v-if="creation")
    MetaTag(name="og:title" :content="creation.title")
    MetaTag(name="og:url" :content="endpoints.main(`/view/${creation.id}`)")
    MetaTag(:names="['og:description', 'description']" :content="stub(creation.renderedBody)")
    FeedTag(:object-id="creation.id")
    TitleTag(:title="creation.title")

    ol.breadcrumb
        li.breadcrumb-item
            router-link(to="/hub") Creation hub
        li.breadcrumb-item.active
            span(aria-current="location") {{creation.title}}

    .row(v-if="!isEditing")
        .col-md-9
            .card
                template(v-if="!isEditing")
                    .card-img-top(v-if="creation.images.length > 0")
                        .ratio.ratio-16x9
                            Carousel.carousel(:count="creation.images.length")
                                .carousel-item.h-100(v-for="(img, i) in creation.images" :class="{active: i == 0}")
                                    img.carousel-image(:src="'https://assets.logicworld.net/upload/' + img")
                    .card-body
                        h1 {{creation.title}}
                        span.text-muted
                            | by 
                            user-link(:user="creation.author")
                            time-ago(:time="creation.createdAt")
                            template(v-if="creation.editedAt")
                                |
                                | (edited
                                time-ago(:time="creation.editedAt")
                                | )
                        hr
                        Markdown(:html="creation.renderedBody")
                        hr

                        BottomSocials(:object-id="id" :rating="creation.rating")

        .col-md
            .card.mt-3.mt-md-0
                .card-body.d-flex.flex-column
                    OpenInGameButton.w-100(:creationId="creation.id")
                    router-link.text-center(to="/view/pst-56609d78")
                        | Help with this feature

                    a.btn.btn-success.w-100.mt-4(:href="creation.fileUrl" download)
                        icon.me-2(icon="download")
                        | Download

                    template(v-if="canEdit || canDelete")
                        hr
                        .row
                            .col(v-if="canEdit")
                                button.btn.btn-warning.w-100(@click="isEditing = true" :disabled="isEditing")
                                    icon.me-2(icon="edit" fixed-width)
                                    | Edit
                            .col(v-if="canDelete")
                                button.btn.btn-danger.w-100(@click="deleteCreation")
                                    icon.me-2(icon="trash" fixed-width)
                                    | Delete

                    hr
                    div
                        span.badge.fs-6(:class="['bg-' + kindName[creation.kind].color]") {{kindName[creation.kind].name}}
                        dl.mb-0.mt-2(v-if="creation.metadata.length > 0")
                            template(v-for="md in metadata")
                                dt {{metadataName[md.key]}}
                                dd {{md.value}}

    .row(v-else)
        EditCreation(:creation="creation" @cancel="isEditing = false" @done="doneEditing")

    .card.mt-5
        .card-body(style="min-height: 150px")
            suspense(timeout="0")
                template(#fallback)
                    spinner
                template(#default)
                    CommentList(:objectid="id")
</template>

<script lang="ts" setup>
import { useMutation, useQuery } from 'villus';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { GetCreationDocument, CreationMetadataKey, HubCreationKind, DeleteCreationDocument } from '@/graphql';
import { canAllOrOwn, stub } from '@/utils';
import { useEvents } from '@/mixins';
import endpoints from '@/endpoints';

import Markdown from '@/components/Markdown.vue';
import CommentList from "@/components/CommentList.vue";
import Carousel from '@/components/Carousel.vue';
import EditCreation from '@/components/hub/EditCreation.vue';
import { FeedTag, MetaTag, TitleTag } from "@/utils/head"
import BottomSocials from '@/components/widgets/BottomSocials.vue';
import OpenInGameButton from '@/components/OpenInGameButton.vue';

const props = defineProps({
    id: {
        type: String,
        required: true
    }
});

const { loaded } = useEvents();
const router = useRouter();

const kindName: { [K in `${HubCreationKind}`]: { name: string, color: string } } = {
    BOARD: { name: "Subassembly", color: "primary" },
    WORLD: { name: "World", color: "success" }
}

const metadataName: { [K in `${CreationMetadataKey}`]: string } = {
    NONE: "Unknown data",
    GAME_VERSION: "Game version"
}
const metadataKeys = Object.values(CreationMetadataKey);

const { execute: executeDelete } = useMutation(DeleteCreationDocument);
const { data } = await useQuery({ query: GetCreationDocument, variables: props });

const creation = computed(() => data.value?.creation!);

loaded();

if (!creation.value)
    throw new Error("No creation?");

const metadata = computed(() => [...creation.value.metadata].sort((a, b) => {
    return metadataKeys.indexOf(a.key) - metadataKeys.indexOf(b.key);
}))

const canEdit = canAllOrOwn("can-edit-creation", () => creation.value.authorId);
const canDelete = canAllOrOwn("can-delete-creation", () => creation.value.authorId);
const isEditing = ref(false);

function doneEditing(title: string, body: string, renderedBody: string, images: string[]) {
    assert(creation.value, "creation data");

    creation.value.title = title;
    creation.value.body = body;
    creation.value.renderedBody = renderedBody;
    creation.value.images = images;
    creation.value.editedAt = new Date().getTime() / 1000;

    isEditing.value = false;
}

async function deleteCreation() {
    if (confirm("Are you sure you want to delete this creation?")) {
        await executeDelete({ id: props.id });

        router.push("/hub");
    }
}
</script>

<style lang="scss" scoped>
dd:last-child {
    margin-bottom: 0;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carousel {
    background-color: black;
}
</style>