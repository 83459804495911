<template lang="pug">
spinner(v-if="!data")
template(v-else)
    CommentBox.mb-2(:objectid="objectid")

    span.text-muted {{data.comments.length}} comments

    Comment(v-for="cmt in topLevelComments" :key="cmt.id" :comment="cmt" :depth="0" :allComments="data.comments")
</template>

<script lang="ts" setup>
import { computed, onUnmounted } from "vue";
import { useQuery } from 'villus';

import { useEvents } from '@/mixins';
import type { CommentFieldsFragment } from '@/graphql';
import {  GetCommentsDocument } from '@/graphql';

import Comment from "@/components/Comment.vue"
import CommentBox from "@/components/CommentBox.vue"

const props = defineProps({
    objectid: String
})

const { loaded, newComment } = useEvents();

const queryVars = computed(() => ({ objid: props.objectid ?? "" }));
var { data } = await useQuery({ query: GetCommentsDocument, variables: queryVars })
loaded();

const topLevelComments = computed(() => data.value?.comments.filter(o => !o.parentid));

function onNewComment(cmt: CommentFieldsFragment) {
    data.value?.comments.unshift(cmt!);
}

newComment.on(onNewComment);
onUnmounted(() => newComment.off(onNewComment));
</script>
