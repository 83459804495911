import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["indicator badge d-flex align-items-center justify-content-center", [_ctx.ticket.isOpen ? 'bg-success' : 'bg-danger']])
  }, [
    (_ctx.ticket.isOpen)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode("Open")
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.ticket.closedVersion)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("Fixed in " + _toDisplayString(_ctx.ticket.closedVersion), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode("Closed")
              ], 64))
        ], 64))
  ], 2))
}