<template lang="pug">
TitleTag(:title="'Search: ' + opts.query")
MetaTag(name="robots" content="noindex")

.container
    p
        span.display-4 Search results
        span.ms-2.fs-4 for &laquo;{{opts.query}}&raquo;

        .card.mt-2
            .card-body
                SearchOptions(:opts="opts" v-model:sort="sort")

        hr

        Pagination.row(:fetch-items="fetchItems" use-route emit-load :sort="sort")
            template(#default="{ item }")
                .col-lg-3.col-md-4.col-sm-6.mb-4
                    template(v-if="item.__typename == 'Post'")
                        .card
                            .card-body
                                router-link.stretched-link.text-decoration-none(:to="`/view/${item.id}`")
                                    h5.card-title {{item.title}}
                                .d-flex
                                    span.text-muted
                                        | by 
                                        user-link.author(:user="item.author")
                                    time-ago.ms-auto.text-muted(:time="item.createdat")

                    template(v-else-if="item.__typename == 'HubCreation'")
                        CreationListingItem(:item="item")

                    template(v-else-if="item.__typename == 'Ticket'")
                        .card
                            .card-body.d-flex.flex-column
                                router-link.stretched-link.text-decoration-none(:to="`/tracker/${item.number}`")
                                    h5.card-title
                                        span.text-muted.me-1 &num;{{item.number}}
                                        span {{item.title}}

                                .d-flex
                                    span.text-muted
                                        | by 
                                        user-link.author(:user="item.author")
                                    time-ago.ms-auto.text-muted(:time="item.createdAt")

                                .d-flex.align-items-center.mt-1
                                    TicketStatus(:ticket="item")
                                    TicketKind.ms-auto(:kind="item.ticketKind")

</template>

<script lang="ts" setup>
import { useQuery } from 'villus';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { GetSearchResultsDocument, SearchItemType } from '@/graphql';
import type { PageOptions } from '@/graphql';

import Pagination from '@/components/widgets/Pagination.vue';
import SearchOptions from '@/components/SearchOptions.vue';
import CreationListingItem from '@/components/hub/CreationListingItem.vue';
import TicketStatus from '@/components/tracker/TicketStatus.vue';
import TicketKind from '@/components/tracker/TicketKind.vue';
import { MetaTag, TitleTag } from '@/utils';
import { trackSearch } from '@/utils/analytics';

const route = useRoute();
const opts = computed(() => {
    const strQuery = typeof route.query.q === "string" ? route.query.q : "";
    const typesStr = typeof route.query.t === "string" ? route.query.t.split(",") : Object.values(SearchItemType);
    
    return {
        query: strQuery,
        type: typesStr.filter(o => Object.values(SearchItemType).includes(o as any)) as SearchItemType[]
    }
})

trackSearch(opts.value.query);

const sort = ref(typeof route.query.s === "string" ? route.query.s : "-created");

const getResults = useQuery({
    query: GetSearchResultsDocument,
    fetchOnMount: false
});

const fetchItems = (page: PageOptions) => getResults.execute({
    variables: { page, ...opts.value }
}).then(({error, data}) => error ? { error } : { data: data!.search });
</script>