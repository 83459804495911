import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "markdown-body",
    innerHTML: _ctx.html,
    ref: "container"
  }, null, 8, _hoisted_1))
}