<template lang="pug">
div
    template(v-for="item in items")
        template(v-if="item")
            slot(:item="item")

    //- Must be rendered so that it is visible if the client has disabled JavaScript
    //- but must be hidden as soon as possible JS is enabled and they are not needed.
    .mt-3.w-100.d-flex.flex-row(:ref="el => infiniteScroll && el && (el.style.cssText = 'display: none !important')")
        component.btn.btn-outline-primary(v-if="!isFirstPage" :is="useRoute ? 'router-link' : 'button'" :to="routeTo(0)" @click="currentPage = 0") Back to newest
        component.btn.btn-primary.ms-auto(v-if="!reachedEnd" :is="useRoute ? 'router-link' : 'button'" :to="routeTo(currentPage + 1)" @click="currentPage++") Older
    div(ref="endMarker")
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue"
import type { PropType } from "vue"
import { useRouter } from "vue-router";
import { CombinedError } from "villus";

import type { PageOptions } from "@/graphql";
import { useSettings } from "@/mixins";

const props = defineProps({
    fetchItems: {
        type: Function as PropType<(page: PageOptions) => Promise<{ data?: any[], error?: CombinedError | null }>>,
        required: true
    },
    initialItems: {
        type: Array as PropType<any[]>,
        default: []
    },
    maxCount: {
        type: Number,
        default: 10
    },
    useRoute: {
        type: Boolean,
        default: false
    },
    emitLoad: Boolean,
    sort: {
        type: String,
        default: "-created"
    }
});

const { infiniteScroll } = useSettings();

const route = useRouter().currentRoute.value;

const endMarker = ref<HTMLElement>();
const reachedEnd = ref<boolean>(false);
const items = ref<any[]>([...props.initialItems]);

const currentPage = ref(props.useRoute && typeof route.query.page === "string" ? Number(route.query.page) : 0);
let isLoading = false;

let observer: IntersectionObserver | null = null;
onMounted(() => observer?.observe(endMarker.value!));
onUnmounted(() => observer?.disconnect());

const isFirstPage = computed(() => currentPage.value === 0);

function routeTo(page: number | null) {
    return { ...route, query: { ...route.query, page: page === null ? undefined : page }}
}

async function loadMore(page?: number) {
    if (isLoading) {
        return;
    }
    isLoading = true;

    if (page !== undefined) {
        currentPage.value = page;
    }

    const replace = !infiniteScroll.value;
    const { data, error } = await props.fetchItems({
        page,
        maxCount: props.maxCount,
        sort: [props.sort]
    })

    if (data) {
        reachedEnd.value = data.length === 0 || data.length < props.maxCount;

        if (data.length > 0) {
            if (replace) {
                items.value = data;

                if (!isSSR) {
                    setTimeout(() => scrollTo({ top: 0, behavior: "smooth" }), 50);
                }
            } else {
                items.value.push(...data);
            }
        }
    } else if (error) {
        throw error;
    }

    isLoading = false;
}

if (items.value.length == 0) {
    await loadMore(currentPage.value);
} else {
    reachedEnd.value = props.initialItems.length < props.maxCount;
}

if (infiniteScroll.value) {
    if (typeof IntersectionObserver !== "undefined") {
        function checkEntries(o: IntersectionObserverEntry[]) {
            o.forEach(async i => {
                if (i.isIntersecting && !reachedEnd.value) {
                    await loadMore(currentPage.value + 1);

                    nextTick(() => {
                        // When the posts load check if we are still not at the bottom
                        if (endMarker.value) {
                            observer!.unobserve(endMarker.value);
                            observer!.observe(endMarker.value);
                        }
                    });
                }
            })
        }

        observer = new IntersectionObserver(o => checkEntries(o), {
            threshold: 1,
            rootMargin: "30%"
        })
    }
}

watch(currentPage, loadMore);
</script>