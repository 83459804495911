import { fetch, handleSubscriptions, createClient } from 'villus'
import { cache, CacheData } from './villus-plugins/cache'
import { multipart } from '@villus/multipart';

export default function (initCache?: CacheData) {
    const { plugin: cachePlugin, cache: cacheStore } = cache(initCache);
    const plugins = [multipart(), cachePlugin, fetch()];
    let url = "/graphql";

    return {
        client: createClient({
            url,
            use: plugins
        }),
        cache: cacheStore
    }
}
