import { App, Directive, DirectiveBinding } from 'vue';

const tooltip: Directive<HTMLElement> = {
    mounted(el, binding) {
        if (!isSSR) {
            setOrUpdateTooltip(el, binding);
        }
    },
    updated(el, binding) {
        if (!isSSR) {
            setOrUpdateTooltip(el, binding);
        }
    },
    unmounted(el) {
        import("bootstrap").then(({ Tooltip }) => {
            Tooltip.getInstance(el)?.dispose();
        })
    }
}

function setOrUpdateTooltip(el: HTMLElement, binding: DirectiveBinding<any>) {
    if (binding.value)
        el.title = String(binding.value);
    else
        el.removeAttribute("title");

    import("bootstrap").then(({ Tooltip }) => {
        let tt = Tooltip.getInstance(el);
        if (tt) {
            tt.dispose();
        }

        if (binding.value) {
            new Tooltip(el, {
                placement: (binding.arg || "bottom") as any,
                trigger: binding.modifiers.click ? "click" : "hover",
                html: binding.modifiers.html ?? false,
                delay: { show: binding.modifiers.delay ? 600 : 0, hide: 0 }
            })
        }
    })
}

const dropdown: Directive<HTMLElement> = {
    mounted(el) {
        el.dataset.bsToggle = "dropdown";
        el.setAttribute("aria-haspopup", "true");
        el.setAttribute("aria-expanded", "true");
        import("bootstrap").then(({ Dropdown }) => new Dropdown(el));
    }
}

const selectFocus: Directive = {
    mounted(el) {
        const input = el as HTMLInputElement;

        if (!input.select) {
            throw new Error("Element is not an input");
        }

        input.addEventListener("focus", () => input.select());
    }
}

export default function (app: App) {
    app.directive("tooltip", tooltip);
    app.directive("select", selectFocus);
    app.directive("dropdown", dropdown);
}
